define("formaviva-web/utils/play-reporter", ["exports", "formaviva-web/config/environment", "formaviva-web/utils/transform-object-keys", "formaviva-web/utils/url-utils"], function (_exports, _environment, _transformObjectKeys, _urlUtils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Object.extend({
    reportingIntervalSeconds: 3,
    authenticatedAjax: Ember.inject.service(),
    currentSession: Ember.inject.service(),
    setCurrentSecretToken: function setCurrentSecretToken() {
      var paramsString = window.location.search;

      var queryParams = _urlUtils.default.getJsonFromUrl(paramsString);

      this.set("secretToken", queryParams["privateToken"]);
    },
    bufferFull: function bufferFull() {
      var buffer = this.get("buffer");
      return buffer.length == this.reportingIntervalSeconds;
    },
    bufferReadyToReport: function bufferReadyToReport() {
      var buffer = this.get("buffer");
      var lengthIsOk = this.bufferFull();
      var secondsAreSequential = buffer.every(function (i, index) {
        if (index + 1 < buffer.length) {
          var nextItem = buffer[index + 1];
          return i == nextItem - 1;
        } else {
          // Last item
          return i == buffer[buffer.length - 1];
        }
      });
      return lengthIsOk && secondsAreSequential;
    },
    clearBuffer: function clearBuffer() {
      this.set("buffer", []);
    },
    addToBuffer: function addToBuffer(second) {
      var buffer = this.get("buffer");

      if (!buffer.includes(second)) {
        buffer.push(second);
      }
    },
    trackIsPlaying: function trackIsPlaying(time) {
      if (!this.get("buffer")) {
        this.clearBuffer();
      }

      var buffer = this.get("buffer");
      var second = parseInt(time);

      if (this.bufferReadyToReport()) {
        var rangeStartSecond = buffer[0];
        var rangeEndSecond = buffer.slice(-1)[0]; // Last element

        this.reportTime(rangeStartSecond, rangeEndSecond);
        this.clearBuffer();
      } else if (buffer.length < this.reportingIntervalSeconds) {
        this.addToBuffer(second);
      } else {
        this.clearBuffer();
        this.addToBuffer(second);
      }
    },
    getPlaySessionId: function getPlaySessionId(trackId) {
      var key = "fv:play-session:".concat(trackId);
      var sessionId = window.localStorage.getItem(key);
      return sessionId;
    },
    setPlaySessionId: function setPlaySessionId(trackId, sessionId) {
      var key = "fv:play-session:".concat(trackId);
      window.localStorage.setItem(key, sessionId);
    },
    reportTime: function reportTime(startTime, endTime) {
      var _this = this;

      var trackId = this.get("track.id"),
          resourcePath = "/a/tps/" + trackId,
          url = _environment.default.serverURL + "/" + _environment.default.apiNamespace + resourcePath;
      var userTrackingProps = this.get("currentSession.userTrackingProps") || {};
      if (this.secretToken) userTrackingProps["secret_token"] = this.secretToken;
      var query = {
        startTime: startTime,
        endTime: endTime,
        userTrackingProps: userTrackingProps
      }; // see if we already have a session open for this track

      var sessionId = this.getPlaySessionId(trackId);

      if (!Ember.isEmpty(sessionId)) {
        query.sessionId = sessionId;
      }

      var promise = this.get("authenticatedAjax").post(url, {
        data: _transformObjectKeys.default.decamelizeKeys(query)
      });
      promise.then(function (response) {
        var data = _transformObjectKeys.default.camelizeKeys(response);

        var newSessionId = data.playSessionId; // set play session locally / or reset if requested by the server

        if (Ember.isEmpty(sessionId) || String(sessionId) != String(newSessionId)) {
          _this.setPlaySessionId(trackId, newSessionId);
        }
      });
    }
  });

  _exports.default = _default;
});