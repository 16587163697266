define("formaviva-web/_pods/components/track/mixes-table/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: "",
    store: Ember.inject.service(),
    messageBus: Ember.inject.service(),
    persistentPlayerState: Ember.inject.service(),
    currentTrack: null,
    init: function init() {
      this._super.apply(this, arguments);

      this.togglePlay = this.togglePlay.bind(this);
    },
    tracksInMix: Ember.computed.reads("track.tracksInMixRecords"),
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      this.messageBus.subscribe("playback:global:seek", this, this.onSeekPlayer);
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);

      this.messageBus.unsubscribe("playback:global:seek", this, this.onSeekPlayer);
    },
    onSeekPlayer: function onSeekPlayer(playingTrack, _ref) {
      var time = _ref.time,
          source = _ref.source;

      if (source !== "master") {
        return;
      }

      var tracks = this.track.sortedTracksInMix;
      var tracksModel = this.tracksInMix;
      var currentTrack = tracksModel[0];

      for (var i = 0; i < tracks.length; i++) {
        var start_time = tracks[i].start_time;

        if (start_time > time) {
          break;
        }

        currentTrack = tracksModel[i];
      }

      this.set("currentTrack", currentTrack);
    },
    getStartTimeForTrack: function getStartTimeForTrack(track) {
      var index = this.tracksInMix.indexOf(track);

      if (index === -1) {
        return 0;
      } else {
        return this.track.sortedTracksInMix[index].start_time;
      }
    },
    togglePlay: function togglePlay(mixinTrack) {
      var currentTrack = this.currentTrack,
          track = this.track;
      var isPlayingTrack = this.persistentPlayerState.isPlayingTrack;
      var isActiveTrack = currentTrack && currentTrack.id === mixinTrack.id;
      var time = this.getStartTimeForTrack(mixinTrack);
      var progress = time / track.length;

      if (isActiveTrack) {
        if (isPlayingTrack) {
          this.messageBus.publish("playback:global:pause", track);
        } else {
          this.messageBus.publish("playback:global:seek", track, {
            progress: progress
          });
          this.messageBus.publish("playback:global:play", track);
        }
      } else {
        this.messageBus.publish("playback:global:seek", track, {
          progress: progress
        });
        this.messageBus.publish("playback:global:play", track);
      }
    }
  });

  _exports.default = _default;
});