define("formaviva-web/_pods/components/sortable-select/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    router: Ember.inject.service(),
    selectedSortOption: null,
    actions: {
      setSelectedSortOption: function setSelectedSortOption(option) {
        this.set("selectedSortOption", option);
        var queryParams = {};
        queryParams.sort = "".concat(option.direction).concat(option.field);
        this.router.transitionTo({
          queryParams: queryParams
        });
      }
    }
  });

  _exports.default = _default;
});