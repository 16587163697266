define("formaviva-web/_pods/components/analytics/play-session-heatmap/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "hFMwio/R",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"play-session-wrapper\"],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"play-session-wavetable-section\"],[9],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"heat-map-container\"],[9],[10],[0,\"\\n  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "formaviva-web/_pods/components/analytics/play-session-heatmap/template.hbs"
    }
  });

  _exports.default = _default;
});