define("formaviva-web/_pods/blog/route", ["exports", "ember-data", "formaviva-web/config/environment"], function (_exports, _emberData, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var NotFoundError = _emberData.default.NotFoundError;

  var _default = Ember.Route.extend({
    headData: Ember.inject.service(),
    hasCustomAsyncTitle: true,
    model: function model() {
      return this.store.query("static-page", {
        filter: {
          slug: "blog/mixes"
        }
      }).then(function (_ref) {
        var firstObject = _ref.firstObject;

        if (!firstObject) {
          // to be consistent with other routes we throw 404
          // when static-page not found and move to error route
          throw new NotFoundError();
        }

        return firstObject;
      });
    },
    afterModel: function afterModel(_ref2) {
      var title = _ref2.title,
          description = _ref2.description;
      this.set("headData.title", "".concat(title, " | Formaviva"));
      this.set("headData.description", description);
    },
    setupController: function setupController(controller, model) {
      this._super(controller, model);

      controller.set("shareUrl", "".concat(_environment.default.webURL, "/").concat(model.slug));
    }
  });

  _exports.default = _default;
});