define("formaviva-web/components/loader-ball-clip-rotate-multiple", ["exports", "ember-cli-loaders/components/loader-ball-clip-rotate-multiple"], function (_exports, _loaderBallClipRotateMultiple) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _loaderBallClipRotateMultiple.default;
    }
  });
});