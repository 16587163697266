define("formaviva-web/_pods/components/track/tracks-table-row/component", ["exports", "formaviva-web/_pods/components/player/player-slave/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    playerHeight: 28,
    barWidth: 2,
    cursorWidth: 0,
    isTheCurrentlyPlayingTrack: Ember.computed("persistentPlayerState.currentTrack.id", "track.id", function () {
      return this.get("persistentPlayerState.currentTrack.id") === this.get("track.id");
    }),
    actions: {
      stopPropogation: function stopPropogation(e) {
        e.stopPropagation();
      }
    }
  });

  _exports.default = _default;
});