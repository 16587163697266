define("formaviva-web/_pods/components/release/release-content/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "KYzPfUgL",
    "block": "{\"symbols\":[\"@release\",\"@showPurchaseItems\"],\"statements\":[[1,[29,\"release/release-header\",null,[[\"class\",\"release\",\"artistsToDisplay\",\"isPrelaunchLocked\",\"shouldLinkToRelease\"],[\"release-header\",[25,[\"release\"]],[25,[\"artistsToDisplay\"]],[25,[\"isPrelaunchLocked\"]],false]]],false],[0,\"\\n\"],[7,\"div\"],[11,\"class\",\"locked-overlay-container\"],[9],[0,\"\\n\"],[4,\"if\",[[25,[\"isPrelaunchLocked\"]]],null,{\"statements\":[[0,\"    \"],[1,[29,\"release/release-locked-overlay\",null,[[\"release\"],[[25,[\"release\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"  \"],[7,\"div\"],[12,\"class\",[30,[[29,\"if\",[[25,[\"isPrelaunchLocked\"]],\"blurred-element\"],null]]]],[9],[0,\"\\n    \"],[1,[29,\"release/release-main-track\",null,[[\"class\",\"tracks\",\"canEdit\",\"release\"],[\"release-main-track\",[25,[\"tracks\"]],[25,[\"canEdit\"]],[25,[\"release\"]]]]],false],[0,\"\\n\\n    \"],[1,[29,\"release/release-items\",null,[[\"class\",\"release\",\"visibleTracksCount\",\"showPurchaseItems\",\"tracks\"],[\"release-items\",[25,[\"release\"]],[25,[\"visibleTracksCount\"]],[25,[\"showPurchaseItems\"]],[25,[\"tracks\"]]]]],false],[0,\"\\n\\n\"],[4,\"if\",[[29,\"and\",[[24,2,[]],[24,1,[\"purchaseItemsCount\"]]],null]],null,{\"statements\":[[0,\"      \"],[7,\"div\"],[11,\"class\",\"release-purchase-items\"],[9],[0,\"\\n        \"],[7,\"div\"],[11,\"class\",\"offset-top\"],[9],[0,\"\\n          \"],[7,\"div\"],[11,\"class\",\"collection-title\"],[9],[0,\"\\n            Purchase items (\"],[1,[24,1,[\"purchaseItemsCount\"]],false],[0,\")\\n          \"],[10],[0,\"\\n        \"],[10],[0,\"\\n\\n        \"],[7,\"div\"],[11,\"class\",\"offset-top\"],[9],[0,\"\\n          \"],[1,[29,\"release/purchase-table\",null,[[\"release\"],[[24,1,[]]]]],false],[0,\"\\n        \"],[10],[0,\"\\n      \"],[10],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "formaviva-web/_pods/components/release/release-content/template.hbs"
    }
  });

  _exports.default = _default;
});