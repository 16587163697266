define("formaviva-web/_pods/components/track/track-form-inputs/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    setSlug: function setSlug(track) {
      if (!track.get("isNew")) {
        return;
      }

      if (Ember.isBlank(track.get("displayName"))) {
        return;
      }

      var displayName = track.get("displayName"); // retain only alphanumeric characters and join them by '-'

      var slug = displayName.toLowerCase().replace(/[^a-zA-Z0-9\s+]/g, " ").trim().replace(/\s+/g, "-");
      track.set("slug", slug);
    },
    setDisplayName: function setDisplayName(track) {
      if (Ember.isBlank(track.get("name"))) {
        return;
      }

      var name = String(track.get("name"));
      var originalArtists = track.get("artists").filter(function (artist) {
        return !track.remixerIds.includes(Number.parseInt(artist.id));
      });
      var artistNames = originalArtists.mapBy("name").join(", ");
      var displayName = "";
      if (artistNames) displayName += "".concat(artistNames, " - ");
      if (name) displayName += "".concat(name);
      track.set("displayName", displayName); // recompute slug whenever display name is changed

      track.set("slug", displayName.toLowerCase().replace(/[^a-zA-Z0-9\s+]/g, " ").trim().replace(/\s+/g, "-"));
    },
    setNameWithRemixer: function setNameWithRemixer(track) {
      if (Ember.isBlank(track.get("name"))) {
        return;
      } // remove the remixer part if already present, so it can be
      // recomputed from initial


      var name = String(track.get("name").replace(/ *\([^)]*\) */g, ""));
      var remixers = track.get("artists").filter(function (artist) {
        return track.remixerIds.includes(Number.parseInt(artist.id));
      });
      var remixerNames = remixers.mapBy("name").join(", ");
      if (Ember.isPresent(remixerNames)) remixerNames = "(".concat(remixerNames, " Remix)");
      var nameWithRemixer = "";
      if (name) nameWithRemixer += "".concat(name);
      if (remixerNames) nameWithRemixer += " ".concat(remixerNames);
      track.set("name", nameWithRemixer);
      this.setDisplayName(track);
    },
    actions: {
      setTags: function setTags(value) {
        this.set("track.tags", value);
      },
      setName: function setName(value) {
        this.set("track.name", value.trim());
        this.setSlug(this.track);
        this.setDisplayName(this.track);
      },
      resetDisplayNameIfEmpty: function resetDisplayNameIfEmpty(track) {
        if (Ember.isBlank(track.displayName)) {
          this.setDisplayName(track);
        }
      },
      resetSlugIfEmpty: function resetSlugIfEmpty() {
        if (Ember.isBlank(this.get("track.slug"))) this.setSlug();
      }
    }
  });

  _exports.default = _default;
});