define("formaviva-web/_pods/components/image-uploader/component", ["exports", "formaviva-web/config/environment", "formaviva-web/constants/common"], function (_exports, _environment, _common) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    session: Ember.inject.service(),
    currentSession: Ember.inject.service(),
    fastboot: Ember.inject.service(),
    isFastBoot: Ember.computed.reads("fastboot.isFastBoot"),
    minDimension: _common.IMAGE_MIN_UPLOAD_DIMENSION,
    loadingDelegate: function loadingDelegate() {},
    didInsertElement: function didInsertElement() {
      if (this.isFastBoot) {
        return;
      }

      var profileId = this.get("currentSession.profile.id");
      var minDimension = this.minDimension,
          resource = this.resource;
      var component = this;
      this.loadingDelegate(false);
      var el = this.element.querySelector("#uploader");
      el.innerHTML = ""; // In firefox, the inner HTML of the <script> is not accessible in the `fine-uploader` due to
      // some weird behaviour but the children nodes are accessible. So, manually override the
      // innerHTML of the <script> to ensure things don't break unexpectedly. This works irrespective
      // of any browser since the whole innerHTML is getting replaced

      var templateContainer = this.element.querySelector("#qq-template-image-uploader");
      templateContainer.innerHTML = templateContainer.children[0].outerHTML;
      var uploader = new window.qq.FineUploader({
        element: el,
        template: this.element.querySelector("#qq-template-image-uploader"),
        messages: {
          minHeightImageError: "Minimum allowed image size: ".concat(minDimension, "x").concat(minDimension),
          minWidthImageError: "Minimum allowed image size: ".concat(minDimension, "x").concat(minDimension)
        },
        multiple: false,
        validation: {
          acceptFiles: ".jpg,.jpeg,.png",
          allowedExtensions: ["jpg", "jpeg", "png"],
          image: {
            minWidth: minDimension,
            minHeight: minDimension
          }
        },
        sizeLimit: _common.IMAGE_UPLOAD_SIZE_LIMIT,
        request: {
          inputName: "image",
          endpoint: _environment.default.apiBaseURL + "/uploads"
        },
        callbacks: {
          onSubmit: function onSubmit() {
            this.setCustomHeaders({
              "X-ProfileId": profileId,
              Authorization: "Bearer ".concat(component.get("session.data.authenticated.access_token"))
            });
            component.set("isUploading", true);
            component.loadingDelegate(true);
          },
          onComplete: function onComplete(id, fileName, response) {
            component.set("isUploading", false);
            component.loadingDelegate(false);
            var imageUploadId = response.upload_id;
            var imageUrl = response.image_url;
            resource.set("imageUrl", imageUrl);
            resource.set("imageUploadId", imageUploadId);
          },
          onError: function onError(id, name, reason) {
            if (window.Rollbar) Rollbar.error("Error occurred in image upload: " + reason); // Check if component is valid before accessing properties

            if (!component.isDestroyed()) {
              // Update the isUploading property
              component.set("isUploading", false);
            }

            component.loadingDelegate(false);
          },
          onCancel: function onCancel() {
            component.set("isUploading", false);
            component.loadingDelegate(false);
          }
        },
        retry: {
          enableAuto: true,
          showButton: true
        }
      });
      this.set("uploader", uploader);
    },
    willDestroyElement: function willDestroyElement() {
      this.loadingDelegate(false);

      this._super.apply(this, arguments);
    },
    actions: {
      replaceImage: function replaceImage() {
        this.$(".btn-upload-image-main input").click();
      }
    }
  });

  _exports.default = _default;
});