define("formaviva-web/_pods/checkout/info/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    currentSession: Ember.inject.service(),
    appWideModals: Ember.inject.service(),
    shoppingCart: Ember.inject.service("checkout/shoppingCart"),
    checkoutData: Ember.inject.service("checkout/checkoutData"),
    metrics: Ember.inject.service(),
    afterModel: function afterModel() {
      if (this.get("currentSession.isAuthenticated")) {
        var email = this.get("currentSession.user.email");
        this.set("checkoutData.shippingData.email", email);
      }
    },
    actions: {
      didTransition: function didTransition() {
        var cartItems = this.get("shoppingCart.cartItems").mapBy("name");
        var cartValue = this.get("shoppingCart.cartItems").mapBy("price").reduce(function (a, b) {
          return a + b;
        }, 0);
        this.userBehaviourTracker.track({
          event: "checkout",
          items: cartItems
        });
        this.metrics.trackEvent("FacebookPixel", {
          event: "InitiateCheckout",
          value: cartValue
        });
        return true; // Bubble the didTransition event
      }
    }
  });

  _exports.default = _default;
});