define("formaviva-web/_pods/checkout/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    pageTitle: "Checkout | Formaviva",
    beforeModel: function beforeModel(transition) {
      if (transition.targetName === "checkout.index") {
        this.transitionTo("checkout.info");
      }
    }
  });

  _exports.default = _default;
});