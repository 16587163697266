define("formaviva-web/models/shipping-origin", ["exports", "ember-data", "ember-cp-validations", "ember-data-model-fragments/attributes", "ember-awesome-macros/array", "ember-awesome-macros/raw"], function (_exports, _emberData, _emberCpValidations, _attributes, _array, _raw) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Model = _emberData.default.Model;
  var belongsTo = _emberData.default.belongsTo;
  var attr = _emberData.default.attr;
  var Validations = (0, _emberCpValidations.buildValidations)({
    address: (0, _emberCpValidations.validator)("presence", true),
    city: (0, _emberCpValidations.validator)("presence", true),
    countryCode: (0, _emberCpValidations.validator)("presence", true),
    postNumber: (0, _emberCpValidations.validator)("presence", true),
    shippingRegionPrices: [(0, _emberCpValidations.validator)("length", {
      min: 1
    }), (0, _emberCpValidations.validator)("has-many")]
  });

  var _default = Model.extend(Validations, {
    address: attr("string"),
    city: attr("string"),
    countryCode: attr("string"),
    postNumber: attr("string"),
    shippingRegionPrices: (0, _attributes.fragmentArray)("shipping-zone", {
      defaultValue: []
    }),
    profile: belongsTo(),
    shippingZonePrices: (0, _array.filter)("shippingRegionPrices", function (item) {
      return item.to !== "*";
    }),
    restOfTheWorldPrice: (0, _array.findBy)("shippingRegionPrices", (0, _raw.default)("to"), (0, _raw.default)("*"))
  });

  _exports.default = _default;
});