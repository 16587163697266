define("formaviva-web/_pods/components/tag/tags-list/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "BhyrWfzr",
    "block": "{\"symbols\":[\"tag\",\"index\"],\"statements\":[[4,\"if\",[[25,[\"record\",\"tags\"]]],null,{\"statements\":[[0,\"  \"],[7,\"div\"],[9],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"collection-title clearfix\"],[9],[0,\"\\n      \"],[7,\"span\"],[11,\"class\",\"pull-left\"],[9],[0,\"Tags (\"],[1,[25,[\"record\",\"tags\",\"length\"]],false],[0,\")\"],[10],[0,\"\\n    \"],[10],[0,\"\\n\"],[4,\"each\",[[25,[\"record\",\"tags\"]]],null,{\"statements\":[[0,\"      \"],[1,[29,\"tag/tag-indicator\",null,[[\"tag\",\"record\",\"onclick\",\"class\"],[[24,1,[]],[25,[\"record\"]],null,\"offset-top offset-bottom\"]]],false],[0,\"\\n\"]],\"parameters\":[1,2]},null],[0,\"  \"],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "formaviva-web/_pods/components/tag/tags-list/template.hbs"
    }
  });

  _exports.default = _default;
});