define("formaviva-web/_pods/edit/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    currentSession: Ember.inject.service(),
    isListener: Ember.computed.alias("currentSession.profile.isListener"),
    tabsArray: Ember.computed("isListener", function () {
      var tabs = [{
        name: "Profile",
        route: "edit.profile"
      }, {
        name: "Email & Password",
        route: "edit.user"
      }, {
        name: "Notifications",
        route: "edit.notifications"
      }];
      if (!this.isListener) tabs.push({
        name: "Shipping",
        route: "edit.shipping"
      });
      return tabs;
    })
  });

  _exports.default = _default;
});