define("formaviva-web/_pods/components/fv-radio-button/component", ["exports", "ember-awesome-macros"], function (_exports, _emberAwesomeMacros) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNameBindings: ["checked"],
    classNames: ["fv-radio-button"],
    checked: (0, _emberAwesomeMacros.equal)("value", "groupValue"),
    click: function click() {
      this.onChange(this.value);
    }
  });

  _exports.default = _default;
});