define("formaviva-web/_pods/components/address-inputs/component", ["exports", "formaviva-web/constants/countries"], function (_exports, _countries) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    withPhoneInput: true,
    shoppingCart: Ember.inject.service("checkout/shoppingCart"),
    init: function init() {
      this._super();

      this.set("availableCountries", _countries.default);
    },
    actions: {
      setBillingCountry: function setBillingCountry(country) {
        this.set("addressContainer.countryCode", country.id);
        this.get("shoppingCart.recalculatePaymentAmounts").perform();
      }
    }
  });

  _exports.default = _default;
});