define("formaviva-web/constants/common", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.FILE_DOWNLOAD_LIMIT = _exports.PRE_REGISTER_PLAY_COUNT = _exports.IMAGE_UPLOAD_SIZE_LIMIT = _exports.IMAGE_MIN_UPLOAD_DIMENSION = _exports.FEED_VISIBLE_TRACKS_COUNT = _exports.PRIVATE_TOKEN_LENGTH = void 0;
  var PRIVATE_TOKEN_LENGTH = 10;
  _exports.PRIVATE_TOKEN_LENGTH = PRIVATE_TOKEN_LENGTH;
  var FEED_VISIBLE_TRACKS_COUNT = 5;
  _exports.FEED_VISIBLE_TRACKS_COUNT = FEED_VISIBLE_TRACKS_COUNT;
  var IMAGE_MIN_UPLOAD_DIMENSION = 500;
  _exports.IMAGE_MIN_UPLOAD_DIMENSION = IMAGE_MIN_UPLOAD_DIMENSION;
  var IMAGE_UPLOAD_SIZE_LIMIT = 5 * 1000000;
  _exports.IMAGE_UPLOAD_SIZE_LIMIT = IMAGE_UPLOAD_SIZE_LIMIT;
  var PRE_REGISTER_PLAY_COUNT = 5;
  _exports.PRE_REGISTER_PLAY_COUNT = PRE_REGISTER_PLAY_COUNT;
  var FILE_DOWNLOAD_LIMIT = 10;
  _exports.FILE_DOWNLOAD_LIMIT = FILE_DOWNLOAD_LIMIT;
});