define("formaviva-web/_pods/components/shipping/shipping-zones-container/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ["shipping-zones-container"],
    countries: Ember.inject.service(),
    zoneToEdit: null,
    selectedCountryCodes: Ember.computed("shippingOrigin.shippingRegionPrices", function () {
      var shippingRegionPrices = this.get("shippingOrigin.shippingRegionPrices");
      var shippingTo = shippingRegionPrices.mapBy("to");
      var reducedShippingTo = shippingTo.reduce(function (arr, cur) {
        return arr.concat(cur);
      }, []);
      return reducedShippingTo.uniq();
    }),
    restCountries: Ember.computed("countries.countries", "selectedCountryCodes.[]", function () {
      var _this = this;

      return this.get("countries.countries").filter(function (c) {
        return !_this.selectedCountryCodes.includes(c.id);
      });
    }),
    actions: {
      addShippingZone: function addShippingZone() {
        var newZone = this.shippingOrigin.shippingRegionPrices.createFragment({
          to: []
        });
        this.set("zoneToEdit", newZone);
      },
      editShippingZone: function editShippingZone(zone) {
        this.set("zoneToEdit", zone);
      },
      finishEditZone: function finishEditZone() {
        if (this.get("zoneToEdit.validations.isInvalid")) {
          this.zoneToEdit.rollbackAttributes();

          if (this.get("zoneToEdit.validations.isInvalid")) {
            this.shippingOrigin.shippingRegionPrices.removeObject(this.zoneToEdit);
          }
        }

        this.set("zoneToEdit", null);
      },
      enableRestOfTheWorldPrices: function enableRestOfTheWorldPrices() {
        var restZone = this.shippingOrigin.shippingRegionPrices.createFragment({
          to: "*",
          from: this.get("shippingOrigin.countryCode")
        });
        this.set("zoneToEdit", restZone);
      },
      disableRestOfTheWorldPrices: function disableRestOfTheWorldPrices() {
        this.shippingOrigin.shippingRegionPrices.removeObject(this.shippingOrigin.restOfTheWorldPrice);
      },
      removeZone: function removeZone(zone) {
        this.set("zoneToEdit", null);
        this.shippingOrigin.shippingRegionPrices.removeObject(zone);
      }
    }
  });

  _exports.default = _default;
});