define("formaviva-web/_pods/edit/profile/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    profile: Ember.computed.alias("model"),
    actions: {
      goToProfile: function goToProfile() {
        var profile = this.get("profile");
        profile.rollbackAttributes();
        this.transitionToRoute("profile", profile.get("slug"));
      }
    }
  });

  _exports.default = _default;
});