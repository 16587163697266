define("formaviva-web/components/loader-line-scale", ["exports", "ember-cli-loaders/components/loader-line-scale"], function (_exports, _loaderLineScale) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _loaderLineScale.default;
    }
  });
});