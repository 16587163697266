define("formaviva-web/services/tracking/mixpanel", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    metrics: Ember.inject.service(),
    appState: Ember.inject.service(),
    identifyAndSetup: function identifyAndSetup(user) {
      if (this.appState.isImpersonatedSession) return;
      this.get("metrics").identify({
        distinctId: user.get("mixpanelDistinctId")
      }); // If there is no mixpanel support, don't go further

      if (!window.mixpanel || !window.mixpanel.people) return; // Update browser properties

      window.mixpanel.people.set({});
      window.mixpanel.people.set({
        $email: user.get("email"),
        $name: user.get("name") || user.get("email")
      });
    }
  });

  _exports.default = _default;
});