define("formaviva-web/_pods/apply/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    beforeModel: function beforeModel() {
      window.location = "https://airtable.com/shrLFXNnX5beiUJQ4";
    }
  });

  _exports.default = _default;
});