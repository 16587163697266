define("formaviva-web/_pods/components/shipping/rest-of-the-world-prices/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "GNFxo8s8",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"col-md-10 col-sm-12\"],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"shipping-zone__title\"],[9],[0,\"\\n    \"],[7,\"span\"],[11,\"class\",\"shipping-zone__title-text\"],[9],[0,\"\\n      Rest of the world \\n    \"],[10],[0,\"\\n    \"],[7,\"span\"],[11,\"class\",\"shipping-zone__title-countries\"],[9],[0,\"\\n      (\"],[1,[29,\"pluralize\",[[25,[\"restCountryCount\"]],\"country\"],null],false],[0,\")\\n    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"form-check form-check-inline inline-block shipping-zone__checkbox\"],[9],[0,\"\\n    \"],[7,\"input\"],[11,\"class\",\"form-check-input\"],[11,\"id\",\"enable-rest-prices\"],[12,\"onchange\",[29,\"action\",[[24,0,[]],\"changeCheckbox\"],null]],[12,\"checked\",[23,\"isPriceObjectPresent\"]],[11,\"type\",\"checkbox\"],[9],[10],[0,\"\\n    \"],[7,\"label\"],[11,\"class\",\"form-check-label cursor-pointer\"],[11,\"for\",\"enable-rest-prices\"],[9],[0,\"\\n      shipping to the rest of world\\n    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n\"],[4,\"if\",[[25,[\"isPriceObjectPresent\"]]],null,{\"statements\":[[0,\"    \"],[7,\"div\"],[11,\"class\",\"shipping-zone__prices\"],[9],[0,\"\\n      \"],[1,[29,\"shipping/zone-price-details\",null,[[\"zone\"],[[25,[\"priceObject\"]]]]],false],[0,\"\\n    \"],[10],[0,\"\\n\"]],\"parameters\":[]},null],[10],[0,\"\\n\"],[7,\"div\"],[11,\"class\",\"col-md-2 col-sm-12 shipping-zone__edit-btn-container\"],[9],[0,\"\\n\"],[4,\"if\",[[25,[\"isPriceObjectPresent\"]]],null,{\"statements\":[[0,\"    \"],[7,\"a\"],[12,\"onClick\",[29,\"action\",[[24,0,[]],\"edit\"],null]],[11,\"class\",\"pull-right cursor-pointer\"],[9],[0,\"\\n      Edit\\n    \"],[10],[0,\"\\n\"]],\"parameters\":[]},null],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "formaviva-web/_pods/components/shipping/rest-of-the-world-prices/template.hbs"
    }
  });

  _exports.default = _default;
});