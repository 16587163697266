define("formaviva-web/utils/track-gradient", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = trackGradient;

  function trackGradient() {
    var ctx = document.createElement("canvas").getContext("2d");
    var linGradWave = ctx.createLinearGradient(0, 20, 0, 110);
    linGradWave.addColorStop(0.5, "#00DAB8");
    linGradWave.addColorStop(0.5, "#23675B");
    var linGradProgress = ctx.createLinearGradient(0, 20, 0, 110);
    linGradProgress.addColorStop(0.5, "#018670");
    linGradProgress.addColorStop(0.5, "#143b34");
    return {
      linGradWave: linGradWave,
      linGradProgress: linGradProgress
    };
  }
});