define("formaviva-web/_pods/components/track/track-like/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "yo7h1fyS",
    "block": "{\"symbols\":[],\"statements\":[[7,\"button\"],[12,\"class\",[30,[\"btn-action btn-action--like \",[29,\"if\",[[25,[\"track\",\"liked\"]],\"btn-action--liked\"],null]]]],[11,\"type\",\"button\"],[9],[0,\"\\n  \"],[7,\"span\"],[11,\"class\",\"btn-action__content\"],[9],[0,\"\\n    \"],[1,[29,\"fa-icon\",[\"heart\"],null],false],[0,\"\\n  \"],[10],[0,\"\\n\"],[3,\"action\",[[24,0,[]],\"likeTrack\",[25,[\"track\"]]]],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "formaviva-web/_pods/components/track/track-like/template.hbs"
    }
  });

  _exports.default = _default;
});