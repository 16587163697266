define("formaviva-web/_pods/components/time-input/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "TTinDGKt",
    "block": "{\"symbols\":[],\"statements\":[[7,\"input\"],[12,\"class\",[23,\"class\"]],[12,\"value\",[23,\"formattedValue\"]],[12,\"onkeydown\",[29,\"action\",[[24,0,[]],\"onKeydown\"],null]],[12,\"oninput\",[29,\"action\",[[24,0,[]],\"onInput\"],null]],[11,\"type\",\"text\"],[9],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "formaviva-web/_pods/components/time-input/template.hbs"
    }
  });

  _exports.default = _default;
});