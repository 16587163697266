define("formaviva-web/_pods/resource/route", ["exports", "formaviva-web/config/environment", "ember-ajax/errors"], function (_exports, _environment, _errors) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    ajax: Ember.inject.service(),
    router: Ember.inject.service(),
    messages: Ember.inject.service(),
    fastboot: Ember.inject.service(),
    isFastBoot: Ember.computed.reads("fastboot.isFastBoot"),
    beforeModel: function beforeModel(transition) {
      var _this = this;

      var _transition$params$re = transition.params.resource,
          profile_slug = _transition$params$re.profile_slug,
          resource_slug = _transition$params$re.resource_slug;
      var requestUrl = "".concat(_environment.default.apiBaseURL, "/slug_resources/resolve_resource");
      var requestParams = {
        profile_slug: profile_slug,
        resource_slug: resource_slug
      }; // Figure out if slug is for track, release, or merch, then redirect
      // For example: resource.edit

      var resourceTargetRoute = transition.targetName;
      var promise = this.get("ajax").request(requestUrl, {
        data: requestParams
      });
      var queryParams = transition.to.queryParams;
      promise.then(function (data) {
        var resourceType = data.resource_type; // track | release | merch

        if (!resourceType) {
          if (window.Rollbar) Rollbar.error("Unknown resource type"); // transition to previous route/dashboard if the resource doesn't exits or got renamed

          if (!transition.urlMethod) _this.transitionTo("dashboard", {
            queryParams: queryParams
          });

          if (_this.isFastBoot) {
            _this.set("fastboot.response.statusCode", 404);
          }

          _this.notifications.error(_this.messages.notFoundError, {
            autoClear: true
          });
        }

        var newRoutePath = resourceTargetRoute.replace("resource", resourceType); // We use show instead of index

        newRoutePath = newRoutePath.replace(".index", ".show"); // queryParams are dropped during programmatic transition.
        // if an attempt is made to redirect with the queryParams option, queryParams
        // need to defined in the controller in order to be retained.
        // Reference: https://guides.emberjs.com/release/routing/redirection/#toc_transitioning-after-the-model-is-known

        if (queryParams) {
          if (newRoutePath == "track.show" && queryParams.s) {// Not calling setControllerQueryParams in this particular situation
            // seems to fix the bug where private track with tokens failed to show.
            // This is just a temporary fix I made to fix the urgent problem.
            // Next developer, Please address it properly!
            // NO OP
          } else {
            _this.setControllerQueryParams(newRoutePath, queryParams);
          }
        }

        _this.transitionTo(newRoutePath, profile_slug, resource_slug, {
          queryParams: queryParams
        });
      });
      return promise.catch(function (error) {
        // if slug does not match resources slug go to static-page
        if ((0, _errors.isNotFoundError)(error)) {
          if (queryParams) _this.setControllerQueryParams("static-page", queryParams);

          _this.replaceWith("static-page", profile_slug + "/" + resource_slug);
        } else {
          throw error;
        }
      });
    },
    setControllerQueryParams: function setControllerQueryParams(resourceRoute, queryParams) {
      var resourceController = this.controllerFor(resourceRoute);
      var controllerQueryParams = resourceController.queryParams;
      var queryParamsKeys = Object.keys(queryParams);
      var concatedQueryParams = controllerQueryParams.concat(queryParamsKeys);
      resourceController.set("queryParams", concatedQueryParams);
    }
  });

  _exports.default = _default;
});