define("formaviva-web/_pods/components/shipping/shipping-zone-form/component", ["exports", "ember-awesome-macros/array", "formaviva-web/helpers/country-by-code"], function (_exports, _array, _countryByCode) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ["shipping-zone-form"],
    store: Ember.inject.service(),
    selectedCountries: (0, _array.map)("zone.to", function (code) {
      return (0, _countryByCode.countryByCode)(code);
    }),
    actions: {
      changeTo: function changeTo(countries) {
        this.set("zone.to", countries.map(function (c) {
          return c.id;
        }));
      },
      save: function save() {
        this.onSave();
      },
      remove: function remove() {
        this.onRemove(this.zone);
      },
      changeCalculationType: function changeCalculationType(type) {
        if (!(type == this.get("zone.shippingPriceContainer.type"))) {
          this.zone.set("pricesContainer", this.store.createFragment(Ember.String.dasherize(type), {
            type: type
          }));
        }
      }
    }
  });

  _exports.default = _default;
});