define("formaviva-web/_pods/components/shipping/edit-form/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    countries: Ember.inject.service(),
    actions: {
      changeCountryCode: function changeCountryCode(country) {
        this.model.set("countryCode", country.id);
      },
      save: function save() {
        this.onSubmit();
      }
    }
  });

  _exports.default = _default;
});