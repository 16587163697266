define("formaviva-web/models/play-session", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Model = _emberData.default.Model;
  var attr = _emberData.default.attr;
  var belongsTo = _emberData.default.belongsTo;

  var _default = Model.extend({
    resolution: attr("number"),
    size: attr("number"),
    userTrackingProps: attr(),
    segments: attr(),
    createdAt: attr("date"),
    track: belongsTo("track"),
    listenerName: attr("string"),
    playedPercent: Ember.computed("segments", function () {
      var allSegments = this.get("segments");
      var playedSegments = allSegments.filter(function (s) {
        return s;
      });
      return playedSegments.length / allSegments.length;
    }),
    playedTime: Ember.computed("playedPercent", "track.length", function () {
      var percent = this.get("playedPercent");
      var trackLength = this.get("track.length");
      return parseInt(percent * trackLength);
    })
  });

  _exports.default = _default;
});