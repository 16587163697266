define("formaviva-web/models/notification-preference", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Model = _emberData.default.Model;
  var attr = _emberData.default.attr;
  var belongsTo = _emberData.default.belongsTo;

  var _default = Model.extend({
    user: belongsTo("user"),
    releases: attr("string"),
    likes: attr("string"),
    follows: attr("string"),
    newsletters: attr("string")
  });

  _exports.default = _default;
});