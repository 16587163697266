define("formaviva-web/_pods/application/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    currentSession: Ember.inject.service(),
    headData: Ember.inject.service(),
    router: Ember.inject.service(),
    queryParams: ["userImpersonationToken"],
    userImpersonationToken: null,
    centralFormRoutes: Object.freeze(["login", "signup", "password-reset", "izvir"]),
    isCentralFormRoute: Ember.computed("centralFormRoutes", "router.currentRouteName", function () {
      var routeName = this.get("router").get("currentRouteName");
      return this.centralFormRoutes.includes(routeName);
    }),
    showNavbar: Ember.computed.not("isCentralFormRoute"),
    showFooter: Ember.computed.not("isCentralFormRoute"),
    containerClass: Ember.computed("isCentralFormRoute", "showNavbar", function () {
      if (!this.get("isCentralFormRoute")) {
        return "container min-height-container";
      }

      return "";
    }),
    setPageTitle: function setPageTitle(title) {
      this.set("headData.title", title || "Formaviva");
    }
  });

  _exports.default = _default;
});