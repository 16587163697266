define("formaviva-web/_pods/release/new/route", ["exports", "formaviva-web/mixins/unsaved-record-notification", "formaviva-web/utils/generate-random-token", "formaviva-web/constants/common"], function (_exports, _unsavedRecordNotification, _generateRandomToken, _common) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_unsavedRecordNotification.default, {
    pageTitle: "Create Release | Formaviva",
    currentSession: Ember.inject.service(),
    model: function model() {
      var profile = this.get("currentSession.profile");
      return this.store.createRecord("release", {
        private: true,
        privateToken: (0, _generateRandomToken.default)(_common.PRIVATE_TOKEN_LENGTH),
        profile: profile,
        artists: []
      });
    }
  });

  _exports.default = _default;
});