define("formaviva-web/_pods/components/tag/tag-selector/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "gx0nZL18",
    "block": "{\"symbols\":[\"tag\"],\"statements\":[[4,\"power-select-multiple\",null,[[\"searchEnabled\",\"options\",\"searchField\",\"selected\",\"placeholder\",\"dropdownClass\",\"onchange\"],[true,[25,[\"tags\"]],\"name\",[24,0,[\"record\",\"tags\"]],\"Select some tags...\",[29,\"if\",[[25,[\"selectFull\"]],\"hidden\"],null],[24,0,[\"onchange\"]]]],{\"statements\":[[0,\"  \"],[1,[24,1,[\"name\"]],false],[0,\"\\n\"]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "formaviva-web/_pods/components/tag/tag-selector/template.hbs"
    }
  });

  _exports.default = _default;
});