define("formaviva-web/_pods/components/table/row/active-track/component", ["exports", "ember-light-table/components/lt-row"], function (_exports, _ltRow) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _ltRow.default.extend({
    classNameBindings: ["active"],
    active: Ember.computed("row.content.id", "extra.extraData.currentTrack", function () {
      var currentTrack = this.extra.extraData.currentTrack;
      return currentTrack && currentTrack.id === this.row.content.id;
    })
  });

  _exports.default = _default;
});