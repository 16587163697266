define("formaviva-web/_pods/error/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ejpjqLuM",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"row\"],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"col-md-6\"],[9],[0,\"\\n\"],[4,\"if\",[[25,[\"pageNotFound\"]]],null,{\"statements\":[[0,\"      \"],[7,\"h1\"],[9],[0,\"Not found.\"],[10],[0,\"\\n      \"],[7,\"h3\"],[9],[0,\"The page you are looking for was either removed, had its name changed, or you don't have the necessary permissions to see it.\"],[10],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"      \"],[7,\"h1\"],[9],[0,\"Something went wrong.\"],[10],[0,\"\\n      \"],[7,\"h3\"],[9],[0,\"There was an error while processing your request. Please try again and if the issue persists, please contact support.\"],[10],[0,\"\\n\"]],\"parameters\":[]}],[0,\"  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "formaviva-web/_pods/error/template.hbs"
    }
  });

  _exports.default = _default;
});