define("formaviva-web/_pods/components/country-with-flag/component", ["exports", "ember-awesome-macros", "formaviva-web/macros/country-by-code"], function (_exports, _emberAwesomeMacros, _countryByCode) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ["country-with-flag"],
    countryFromCode: (0, _countryByCode.default)("countryCode"),
    countryObject: (0, _emberAwesomeMacros.conditional)((0, _emberAwesomeMacros.isEmpty)("country"), "countryFromCode", "country")
  });

  _exports.default = _default;
});