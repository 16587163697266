define("formaviva-web/_pods/components/claim/confirm-selection/component", ["exports", "formaviva-web/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    currentSession: Ember.inject.service(),
    authenticatedAjax: Ember.inject.service(),
    associatedReleasesCount: Ember.computed.reads("claimedProfile.associatedReleasesCount"),
    associatedTracksCount: Ember.computed.reads("claimedProfile.associatedReleasesCount"),
    playSessionsCount: Ember.computed.reads("claimedProfile.associatedReleasesCount"),
    isClaiming: false,
    _claimProfileRequest: function _claimProfileRequest(profile, additionalInfo) {
      var endpointUrl = "".concat(_environment.default.apiBaseURL, "/profile_claims");
      return this.get("authenticatedAjax").post(endpointUrl, {
        data: {
          profile_slug: profile.get("slug"),
          additional_info: additionalInfo
        }
      });
    },
    claimProfile: function claimProfile(profile) {
      var _this = this;

      if (Ember.isBlank(this.get("additionalInfo"))) {
        this.set("errorMessage", "can't be blank");
        return;
      }

      if (Ember.isBlank(profile)) return;
      this.set("isClaiming", true);

      var claimRequest = this._claimProfileRequest(profile, this.get("additionalInfo"));

      claimRequest.then(function () {
        _this.set("currentClaimStep", "claimSuccess");
      }).catch(function (error) {
        _this.handleErrorMessage(error);
      }).finally(function () {
        _this.set("isClaiming", false);
      });
    },
    handleErrorMessage: function handleErrorMessage(_ref) {
      var payload = _ref.payload;
      var error = payload.error,
          errors = payload.errors;
      var errorMessage;
      if (error) errorMessage = error;else errorMessage = Ember.isPresent(errors[0]["details"]) ? errors[0]["details"] : "An error occurred";
      this.set("errorMessage", errorMessage);
      this.notifications.error("There was an unexpected error. Please try again or contact support.");
    }
  });

  _exports.default = _default;
});