define("formaviva-web/_pods/components/table/columns/custom/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "8jlB0njQ",
    "block": "{\"symbols\":[\"@sortIcons\",\"@column\"],\"statements\":[[1,[24,2,[\"label\"]],false],[0,\"\\n\"],[4,\"if\",[[24,2,[\"sortable\"]]],null,{\"statements\":[[0,\"  \"],[7,\"i\"],[12,\"class\",[30,[\"lt-sort-icon \",[29,\"if\",[[24,2,[\"sorted\"]],[29,\"if\",[[24,2,[\"ascending\"]],[24,1,[\"iconAscending\"]],[24,1,[\"iconDescending\"]]],null],[24,1,[\"iconSortable\"]]],null]]]],[9],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "formaviva-web/_pods/components/table/columns/custom/template.hbs"
    }
  });

  _exports.default = _default;
});