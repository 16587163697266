define("formaviva-web/utils/abbreviate-number", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = abbreviateNumber;
  // https://stackoverflow.com/a/46134583
  var COUNT_ABBRS = ["", "k", "M", "B", "T"];

  function abbreviateNumber(count) {
    var withAbbr = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : true;
    var decimals = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 2;
    var i = 0 === count ? count : Math.floor(Math.log(count) / Math.log(1000));
    var result; // if index is less than 0, count lies
    // between 0 and 1

    if (i < 0) {
      withAbbr = false;
      result = Number.parseFloat(count).toFixed(decimals);
    } else {
      result = Number.parseFloat((count / Math.pow(1000, i)).toFixed(decimals));
    }

    return withAbbr ? result + COUNT_ABBRS[i] : result;
  }
});