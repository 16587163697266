define("formaviva-web/_pods/edit/profile/route", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin"], function (_exports, _authenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    currentSession: Ember.inject.service(),
    pageTitle: "Edit Profile | Formaviva",
    model: function model() {
      var profile_slug = this.currentSession.user.profileSlug;
      return this.store.queryRecord("profile", {
        find_by_slug: true,
        slug: profile_slug
      });
    },
    actions: {
      saveProfile: function saveProfile(profile) {
        var _this = this;

        this.controller.set("isSaving", true);
        profile.save().then(function () {
          _this.notifications.success("Profile saved successfully.", {
            autoClear: true
          });
        }).catch(function (error) {
          if (window.Rollbar) Rollbar.error(error);

          _this.notifications.error("There was an unexpected error. Please try again or contact support.", {
            autoClear: true
          });
        }).finally(function () {
          return _this.controller.set("isSaving", false);
        });
      }
    }
  });

  _exports.default = _default;
});