define("formaviva-web/_pods/components/footer-area/component", ["exports", "formaviva-web/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    ajax: Ember.inject.service(),
    fastboot: Ember.inject.service(),
    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);

      if (this.fastboot.isFastBoot) return;
      var url = "".concat(_environment.default.apiBaseURL, "/html_snippets/default_footer");
      this.ajax.request(url).then(function (response) {
        _this.set("htmlSnippet", response.content);
      }).catch(function (error) {
        if (window.Rollbar) Rollbar.error(error);
      });
    }
  });

  _exports.default = _default;
});