define("formaviva-web/_pods/checkout/info/controller", ["exports", "formaviva-web/constants/countries", "ember-cp-validations"], function (_exports, _countries, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Validations = (0, _emberCpValidations.buildValidations)({
    "checkoutData.shippingData.firstName": [(0, _emberCpValidations.validator)("presence", {
      presence: true,
      disabled: Ember.computed.empty("model.shoppingCart.merchItems"),
      dependentKeys: ["shoppingCart.merchItems.length"]
    }), (0, _emberCpValidations.validator)("length", {
      min: 2,
      disabled: Ember.computed.empty("model.shoppingCart.merchItems"),
      dependentKeys: ["shoppingCart.merchItems.length"]
    })],
    "checkoutData.shippingData.lastName": [(0, _emberCpValidations.validator)("presence", {
      presence: true,
      disabled: Ember.computed.empty("model.shoppingCart.merchItems"),
      dependentKeys: ["shoppingCart.merchItems.length"]
    }), (0, _emberCpValidations.validator)("length", {
      min: 2,
      disabled: Ember.computed.empty("model.shoppingCart.merchItems"),
      dependentKeys: ["shoppingCart.merchItems.length"]
    })],
    "checkoutData.shippingData.phoneNumber": (0, _emberCpValidations.validator)("presence", {
      presence: true,
      disabled: Ember.computed.empty("model.shoppingCart.merchItems"),
      dependentKeys: ["shoppingCart.merchItems.length"]
    }),
    "checkoutData.shippingData.city": (0, _emberCpValidations.validator)("presence", {
      presence: true,
      disabled: Ember.computed.empty("model.shoppingCart.merchItems"),
      dependentKeys: ["shoppingCart.merchItems.length"]
    }),
    "checkoutData.shippingData.address": (0, _emberCpValidations.validator)("presence", {
      presence: true,
      disabled: Ember.computed.empty("model.shoppingCart.merchItems"),
      dependentKeys: ["shoppingCart.merchItems.length"]
    }),
    "checkoutData.shippingData.postNumber": (0, _emberCpValidations.validator)("presence", {
      presence: true,
      disabled: Ember.computed.empty("model.shoppingCart.merchItems"),
      dependentKeys: ["shoppingCart.merchItems.length"]
    }),
    "checkoutData.shippingData.countryCode": (0, _emberCpValidations.validator)("presence", {
      presence: true,
      disabled: Ember.computed.empty("model.shoppingCart.merchItems"),
      dependentKeys: ["shoppingCart.merchItems.length"]
    })
  });

  var _default = Ember.Controller.extend(Validations, {
    shoppingCart: Ember.inject.service("checkout/shoppingCart"),
    checkoutData: Ember.inject.service("checkout/checkoutData"),
    currentSession: Ember.inject.service(),
    appWideModals: Ember.inject.service(),
    init: function init() {
      this._super();

      this.set("availableCountries", _countries.default);
    },
    actions: {
      goToPayment: function goToPayment() {
        this.get("checkoutData").persist(); // See comment in #toggleLoginPopup.

        this.set("checkoutData.isCurrentlyEditing", false);

        if (!this.get("currentSession.isAuthenticated")) {
          var pendingAction = [this.transitionToRoute.bind(this), "checkout.payment"];
          this.get("appWideModals").toggleLoginModal(true, pendingAction, {
            header: "Sign up to make a purchase"
          });
          return;
        }

        this.transitionToRoute("checkout.payment");
      },
      setShippingCountry: function setShippingCountry(countryCode) {
        this.set("checkoutData.shippingData.countryCode", countryCode);
        this.get("shoppingCart.recalculatePaymentAmounts").perform();
      },
      toggleLoginPopup: function toggleLoginPopup() {
        // Set this flag so the checkout data doesn't get rewritten
        // when user signs up or logins after clicking on "To proceed with the purchase, you have to log in".
        this.set("checkoutData.isCurrentlyEditing", true);
        this.get("appWideModals").toggleLoginModal(true);
      }
    }
  });

  _exports.default = _default;
});