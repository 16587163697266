define("formaviva-web/_pods/dashboard/tracks/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    page: 1,
    dir: "desc",
    sort: "created_at",
    actions: {
      sortChange: function sortChange(dir, sort) {
        this.setProperties({
          dir: dir,
          sort: Ember.String.underscore(sort)
        });
      }
    }
  });

  _exports.default = _default;
});