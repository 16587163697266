define("formaviva-web/_pods/components/social-share/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ce4kWVeT",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"share-popover__link clearfix\"],[9],[0,\"\\n  \"],[7,\"input\"],[11,\"required\",\"\"],[11,\"readonly\",\"\"],[12,\"value\",[23,\"shareUrl\"]],[12,\"onClick\",[29,\"action\",[[24,0,[]],\"selectInput\"],null]],[9],[10],[0,\"\\n  \"],[7,\"span\"],[9],[0,\"\\n    \"],[7,\"button\"],[11,\"class\",\"btn-little-action btn-copy btn-copy--gray no-text-transform\"],[12,\"onclick\",[29,\"action\",[[24,0,[]],[29,\"perform\",[[25,[\"copyLink\"]]],null]],null]],[11,\"type\",\"button\"],[9],[0,\"\\n\"],[4,\"if\",[[25,[\"showCopiedText\"]]],null,{\"statements\":[[0,\"        copied\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"        copy\\n\"]],\"parameters\":[]}],[0,\"    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\"],[7,\"div\"],[11,\"class\",\"share-popover__sn-buttons\"],[9],[0,\"\\n  Share: \"],[1,[29,\"share-panel\",null,[[\"buttons\",\"url\",\"title\"],[\"fb,twitter\",[25,[\"shareUrl\"]],[25,[\"shareText\"]]]]],false],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "formaviva-web/_pods/components/social-share/template.hbs"
    }
  });

  _exports.default = _default;
});