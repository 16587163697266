define("formaviva-web/services/local-persistence", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    fastboot: Ember.inject.service(),
    isFastBoot: Ember.computed.reads("fastboot.isFastBoot"),
    lastPlayedTrackDataStorageKey: "fv:last-played",
    volumeStorageKey: "fv:volume",
    preRegisterPlayCountKey: "fv:pre-register-play-count",
    setPreRegisterPlayCountKey: function setPreRegisterPlayCountKey(count) {
      if (this.isFastBoot) return;
      window.localStorage.setItem(this.preRegisterPlayCountKey, count);
    },
    getPreRegisterPlayCountKey: function getPreRegisterPlayCountKey() {
      if (this.isFastBoot) return;
      var count = window.localStorage.getItem(this.preRegisterPlayCountKey);
      return Ember.isPresent(count) ? count : 0;
    },
    saveVolume: function saveVolume(volume) {
      if (this.isFastBoot) return;
      window.localStorage.setItem(this.volumeStorageKey, volume);
    },
    getVolume: function getVolume(defaultVolume) {
      if (this.isFastBoot) return;
      var volumeMaybe = window.localStorage.getItem(this.volumeStorageKey);
      var volume = defaultVolume;

      if (Ember.isPresent(volumeMaybe)) {
        volume = parseFloat(volumeMaybe);
      }

      return volume;
    },
    saveLastPlayedTrack: function saveLastPlayedTrack(trackData) {
      if (this.isFastBoot) return;
      window.localStorage.setItem(this.lastPlayedTrackDataStorageKey, JSON.stringify(trackData));
    },
    getLastPlayedTrack: function getLastPlayedTrack() {
      if (this.isFastBoot) return;
      var recentlyPlayedTrackData = window.localStorage.getItem(this.lastPlayedTrackDataStorageKey);
      var lastPlayedData = JSON.parse(recentlyPlayedTrackData);
      return lastPlayedData || {};
    }
  });

  _exports.default = _default;
});