define("formaviva-web/_pods/components/release/release-items/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Rz7Il67f",
    "block": "{\"symbols\":[\"index\",\"@visibleTracksCount\",\"@tracks\",\"@release\"],\"statements\":[[7,\"div\"],[11,\"class\",\"release-items-table\"],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"double-offset-top\"],[9],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"collection-title\"],[9],[0,\"\\n      Tracks (\"],[1,[24,3,[\"length\"]],false],[0,\")\\n    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"offset-top\"],[9],[0,\"\\n\"],[4,\"if\",[[24,3,[\"length\"]]],null,{\"statements\":[[0,\"      \"],[1,[29,\"track/tracks-table\",null,[[\"release\",\"tracks\",\"visibleTracksCount\"],[[24,4,[]],[24,3,[]],[24,2,[]]]]],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"each\",[[29,\"repeat\",[5],null]],null,{\"statements\":[[4,\"content-loader\",null,[[\"primaryColor\",\"secondaryColor\",\"height\"],[\"#3a3a3a\",\"#4d4d4d\",20]],{\"statements\":[[0,\"          \"],[7,\"rect\"],[11,\"rx\",\"2\"],[11,\"ry\",\"2\"],[11,\"width\",\"380\"],[11,\"height\",\"20\"],[9],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[1]},null]],\"parameters\":[]}],[0,\"  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "formaviva-web/_pods/components/release/release-items/template.hbs"
    }
  });

  _exports.default = _default;
});