define("formaviva-web/_pods/signup/route", ["exports", "formaviva-web/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // TODO: MERGE THIS LOGIC WITH USER-SIGNUP.JS
  var _default = Ember.Route.extend({
    pageTitle: "Sign Up | Formaviva",
    session: Ember.inject.service(),
    currentSession: Ember.inject.service(),
    shoppingCart: Ember.inject.service("checkout/shoppingCart"),
    metrics: Ember.inject.service(),
    appState: Ember.inject.service(),
    mixpanelTracking: Ember.inject.service("tracking/mixpanel"),
    resetController: function resetController(controller) {
      controller.set("isPasswordShown", false);
    },
    beforeModel: function beforeModel() {
      this._super.apply(this, arguments);

      if (this.currentSession.isAuthenticated) this.transitionTo("dashboard.stats");
    },
    model: function model(params) {
      var profileType = "listener";

      if (["label", "artist"].includes(params.as)) {
        profileType = params.as;
      }

      return this.store.createRecord("user", {
        profileType: profileType
      });
    },
    setupController: function setupController(controller, model) {
      controller.set("user", model);
    },
    actions: {
      signup: function signup(e) {
        var _this = this;

        // This is here because of Firefox. When clicking on Signup,
        // Firefox will reload the window for some reason.
        e.preventDefault();

        if (_environment.default.environment !== "test" && !this.controller.get("recaptcha")) {
          return;
        }

        if (this.controller.isSigningUp) return;
        this.controller.set("isSigningUp", true);
        this.appState.setIsImpersonatedSession(false);
        var user = this.controller.get("user");
        user.set("fv_user_behaviour_initial_distinct_id", this.userBehaviourTracker.getInitialDistinctId());
        user.set("recaptcha", this.controller.get("recaptcha"));
        user.save().then(function () {
          // this.get("mixpanelTracking").identifyAndSetup(user);
          // eslint-disable-next-line no-undef
          if (window.posthog !== undefined) {
            // eslint-disable-next-line no-undef
            posthog.identify(user.id, {
              email: user.email
            });
          }

          _this.session.authenticate("authenticator:oauth2", user.get("email"), user.get("password")).then(function () {
            // Notify success
            _this.get("notifications").success("You signed up successfully. Welcome onboard!", {
              autoClear: true
            });

            return _this.currentSession.load().then(function () {
              _this.get("shoppingCart").restore();

              if (_this.get("currentSession.profile.isListener")) {
                _this.transitionTo("welcome");
              } else {
                _this.transitionTo("dashboard");
              }
            });
          });
        }).catch(function () {
          _this.get("notifications").error("There was an error while signing up. Please try again or contact support.", {
            autoClear: true
          });
        }).finally(function () {
          return _this.controller.set("isSigningUp", false);
        });
      }
    }
  });

  _exports.default = _default;
});