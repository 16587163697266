define("formaviva-web/utils/keyboard", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.isNumberKey = isNumberKey;
  _exports.isLetterKey = isLetterKey;
  _exports.getCaretPosition = getCaretPosition;
  _exports.setCaretPosition = setCaretPosition;
  _exports.KEYS = void 0;
  // utility helper for working with keyboard keys and input field caret
  var KEYS = {
    ENTER: 13,
    ESCAPE: 27,
    SPACE: 32,
    ARROW_UP: 38,
    ARROW_DOWN: 40
  };
  _exports.KEYS = KEYS;

  function isNumberKey(keyCode) {
    return keyCode >= 48 && keyCode <= 57;
  }

  function isLetterKey(event) {
    if (event.keyCode >= 65 && event.keyCode <= 90) {
      return true;
    } else if (event.keyCode >= 97 && event.keyCode <= 122) {
      return true;
    }

    return false;
  }

  function getCaretPosition(oField) {
    var iCaretPos = 0; // IE Support

    if (document.selection) {
      // Set focus on the element
      oField.focus(); // To get cursor position, get empty selection range

      var oSel = document.selection.createRange(); // Move selection start to 0 position

      oSel.moveStart("character", -oField.value.length); // The caret position is selection length

      iCaretPos = oSel.text.length;
    } // Firefox support
    else if (oField.selectionStart || oField.selectionStart == "0") iCaretPos = oField.selectionDirection == "backward" ? oField.selectionStart : oField.selectionEnd; // Return results


    return iCaretPos;
  }

  function setCaretPosition(elem, caretPos) {
    elem.focus();

    if (elem.selectionStart) {
      elem.setSelectionRange(caretPos, caretPos);
    }
  }
});