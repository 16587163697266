define("formaviva-web/_pods/search/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    pageTitle: "Search results | Formaviva",
    queryParams: {
      term: {
        refreshModel: true
      },
      filter: {
        refreshModel: true,
        replace: true
      },
      page: {
        refreshModel: true
      }
    },
    algolia: Ember.inject.service(),
    model: function model(params) {
      var _this = this;

      var query = params.term;
      var filter = params.filter || "all"; // pagination in Algolia is zero indexed

      var page = params.page - 1;
      var releasePerPageHits = 3,
          profilePerPageHits = 5,
          trackPerPageHits = 10,
          mixPerPageHits = 5;

      switch (filter) {
        case "release":
          releasePerPageHits = 10;
          break;

        case "track":
          trackPerPageHits = 10;
          break;

        case "profile":
          profilePerPageHits = 25;
          break;

        case "mix":
          mixPerPageHits = 25;
      }

      var searchQuery = [{
        indexName: "profile",
        hitsPerPage: profilePerPageHits,
        query: query,
        page: page
      }, {
        indexName: "release",
        hitsPerPage: releasePerPageHits,
        query: query,
        page: page
      }, {
        indexName: "track",
        hitsPerPage: trackPerPageHits,
        query: query,
        page: page,
        filters: "mix:false"
      }, {
        indexName: "track",
        hitsPerPage: mixPerPageHits,
        query: query,
        page: page,
        filters: "mix:true"
      }];
      return this.algolia.search(searchQuery).then(function (_ref) {
        var results = _ref.results;
        return {
          releases: _this.algolia.fetchReleases(results, true),
          profiles: _this.algolia.fetchProfiles(results, true),
          tracks: _this.algolia.fetchTracks(results, true),
          mixes: _this.algolia.fetchMixes(results, true)
        };
      });
    },
    actions: {
      queryParamsDidChange: function queryParamsDidChange(change) {
        this._super.apply(this, arguments);

        if (this.controller) {
          // when the filter is changed, move back the
          // start with the first page of the results
          if (change["filter"]) {
            this.controller.set("page", 1);
          }
        }
      }
    }
  });

  _exports.default = _default;
});