define("formaviva-web/utils/clipboard", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _default;
  var textArea;

  function createTextArea(text) {
    textArea = document.createElement("textarea");
    textArea.value = text;
    document.body.appendChild(textArea);
  }

  function selectText() {
    var isOS = navigator.userAgent.match(/ipad|iphone/i);
    var range, selection;

    if (isOS) {
      range = document.createRange();
      range.selectNodeContents(textArea);
      selection = window.getSelection();
      selection.removeAllRanges();
      selection.addRange(range);
      textArea.setSelectionRange(0, 999999);
    } else {
      textArea.select();
    }
  }

  function copyToClipboard() {
    document.execCommand("copy");
    document.body.removeChild(textArea);
  }

  function _default(text) {
    createTextArea(text);
    selectText();
    copyToClipboard();
  }
});