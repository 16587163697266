define("formaviva-web/_pods/analytics/sales/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    pageTitle: "Sales | Formaviva",
    queryParams: {
      page: {
        refreshModel: true
      },
      sort: {
        refreshModel: true
      },
      dir: {
        refreshModel: true
      },
      filterByProperty: {
        refreshModel: true
      }
    },
    model: function model(_ref) {
      var _ref$filterByProperty = _ref.filterByProperty,
          filterByProperty = _ref$filterByProperty === void 0 ? "all" : _ref$filterByProperty,
          _ref$page = _ref.page,
          page = _ref$page === void 0 ? 1 : _ref$page,
          sort = _ref.sort,
          dir = _ref.dir;
      var filter = {};

      if (["processed", "shipped", "processing"].includes(filterByProperty)) {
        filter["status"] = filterByProperty;
      } else {
        filter["item_type"] = filterByProperty;
      }

      return this.store.query("purchase", {
        page: page,
        sort: "".concat(dir === "desc" ? "-" : "").concat(sort),
        per_page: 10,
        sales: true,
        filter: filter
      });
    }
  });

  _exports.default = _default;
});