define("formaviva-web/_pods/profile/index/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "9IeOWU+o",
    "block": "{\"symbols\":[\"release\"],\"statements\":[[1,[29,\"collection-pagination\",null,[[\"collection\",\"path\",\"currentPage\"],[[25,[\"releases\"]],\"profile.index\",[25,[\"page\"]]]]],false],[0,\"\\n\"],[4,\"if\",[[25,[\"isLoading\"]]],null,{\"statements\":[[0,\"  \"],[7,\"div\"],[11,\"class\",\"css-loader css-loader--inline\"],[9],[0,\"\\n    \"],[1,[23,\"loader-line-scale-pulse-out\"],false],[0,\"\\n  \"],[10],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[7,\"div\"],[11,\"class\",\"fv-box-list fv-box-list--six\"],[9],[0,\"\\n\"],[4,\"each\",[[25,[\"releases\"]]],null,{\"statements\":[[0,\"      \"],[1,[29,\"fv-box\",null,[[\"release\",\"truncateTitle\",\"class\"],[[24,1,[]],true,\"fv-box-list__item\"]]],false],[0,\"\\n\"]],\"parameters\":[1]},{\"statements\":[[0,\"      \"],[7,\"div\"],[11,\"class\",\"fv-box-list__placeholder\"],[9],[0,\"This profile hasn't published any releases yet.\"],[10],[0,\"\\n\"]],\"parameters\":[]}],[0,\"  \"],[10],[0,\"\\n\"]],\"parameters\":[]}],[1,[29,\"collection-pagination\",null,[[\"collection\",\"path\",\"currentPage\"],[[25,[\"releases\"]],\"profile.index\",[25,[\"page\"]]]]],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "formaviva-web/_pods/profile/index/template.hbs"
    }
  });

  _exports.default = _default;
});