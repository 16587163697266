define("formaviva-web/_pods/components/track/new-tracks-table/component", ["exports", "formaviva-web/config/environment", "formaviva-web/utils/social-link"], function (_exports, _environment, _socialLink) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function shareUrl(track) {
    return "".concat(_environment.default.webURL, "/").concat(track.profileSlug, "/").concat(track.slug);
  }

  function shareText(track) {
    return "".concat(track.displayName, " by ").concat(track.artistNames);
  }

  var _default = Ember.Component.extend({
    tagName: "",
    router: Ember.inject.service(),
    media: Ember.inject.service(),
    messageBus: Ember.inject.service(),
    persistentPlayerState: Ember.inject.service(),
    shoppingCart: Ember.inject.service("checkout/shoppingCart"),
    trackActions: Ember.inject.service("track/track-actions"),
    slicedTracks: Ember.computed("tracks.[]", "visibleTracksCount", function () {
      var visibleTracksCount = this.visibleTracksCount;
      return Ember.isBlank(visibleTracksCount) ? this.tracks : this.tracks.slice(0, visibleTracksCount);
    }),
    height: Ember.computed("slicedTracks.length", function () {
      return this.slicedTracks.length * 64 + 100 + "px";
    }),
    columnsType: "tracks",
    columns: Ember.computed("release.allowIndividualTracksPurchase", "columnsType", function () {
      var _this = this;

      var columnsType = this.columnsType;
      var overlayActions = [{
        icon: "heart",
        label: function label(track) {
          return track.liked ? "Unlike" : "Like";
        },
        name: "like"
      }, columnsType === "mixes" || this.get("release.allowIndividualTracksPurchase") ? {
        icon: "shopping-cart",
        label: "Add to cart",
        name: "buy"
      } : null, {
        icon: "facebook-square",
        label: "Share on Facebook",
        name: "share-facebook"
      }, {
        icon: "twitter",
        label: "Share on Twitter",
        name: "share-twitter"
      }, {
        icon: "headphones",
        label: "Go To Track Page",
        name: "show-track"
      }, {
        icon: "music",
        label: "Go To Label Page",
        name: "show-label"
      }].filter(function (val) {
        return val;
      });
      return [{
        label: "",
        sortable: false,
        align: "center",
        width: "34px",
        togglePlay: function togglePlay(row) {
          _this.togglePlay(row.content);
        },
        breakpoints: ["tablet", "desktop"],
        cellComponent: "table/cell/player-control"
      }, {
        label: "",
        sortable: false,
        align: "center",
        width: "30px",
        breakpoints: ["tablet", "desktop"],
        cellComponent: "table/cell/index"
      }, {
        label: "",
        valuePath: "adjustedImageUrl",
        sortable: false,
        align: "center",
        width: "52px",
        breakpoints: ["tablet", "desktop"],
        cellComponent: "table/cell/image"
      }, {
        label: "Title",
        valuePath: "displayName",
        sortable: false,
        breakpoints: ["tablet", "desktop"],
        customClass: "fv-body-color",
        cellComponent: "table/cell/simple-link",
        getLink: function getLink(track) {
          var profileSlug = Ember.get(track, "profile.slug");

          if (profileSlug && track.slug) {
            return {
              label: track.name,
              link: _this.router.urlFor("track.show", profileSlug, track.slug)
            };
          } else {
            return {
              label: track.name
            };
          }
        }
      }, {
        label: "Release",
        valuePath: "release.name",
        linkValuePath: "release",
        sortable: false,
        width: "15%",
        breakpoints: ["desktop"],
        cellComponent: "table/cell/simple-link",
        getLink: function getLink(release) {
          if (release) {
            return {
              label: Ember.get(release, "name"),
              link: _this.router.urlFor("release.show", Ember.get(release, "profileSlug"), Ember.get(release, "slug"))
            };
          }
        }
      }, {
        label: "Artist",
        valuePath: "artistNames",
        linkValuePath: "artists",
        sortable: false,
        width: "15%",
        breakpoints: ["tablet", "desktop"],
        cellComponent: "table/cell/simple-link",
        getLink: function getLink(artist) {
          if (artist.approved) {
            return {
              label: artist.name,
              link: _this.router.urlFor("profile.index", artist.slug)
            };
          } else {
            return {
              label: artist.name
            };
          }
        }
      }, {
        label: "Label",
        valuePath: "profile.name",
        sortable: false,
        width: "15%",
        breakpoints: ["desktop"],
        cellComponent: "table/cell/simple-link",
        getLink: function getLink(track) {
          if (track && track.profileSlug && Ember.get(track, "profile.approved")) {
            return {
              label: Ember.get(track, "profile.name"),
              link: _this.router.urlFor("profile.index", track.profileSlug)
            };
          } else if (track && track.slug) {
            return {
              label: Ember.get(track, "profile.name")
            };
          } else return {
            label: track.profileName
          };
        }
      }, ["tracks"].includes(columnsType) ? {
        label: "Date added",
        valuePath: "createdAt",
        sortable: false,
        width: "85px",
        breakpoints: ["desktop"],
        format: "DD-MM-YYYY",
        cellComponent: "table/cell/date"
      } : null, {
        label: "Length",
        valuePath: "length",
        sortable: false,
        align: "right",
        width: "60px",
        breakpoints: ["tablet", "desktop"],
        cellComponent: "table/cell/duration"
      }, {
        label: "",
        sortable: false,
        align: "right",
        width: "122px",
        breakpoints: ["tablet", "desktop"],
        cellComponent: "table/cell/action-btns"
      }, {
        label: "",
        sortable: false,
        breakpoints: ["mobile"],
        cellComponent: "table/cell/track-mobile",
        onClickAction: function onClickAction(track, _ref, closeOverlay) {
          var name = _ref.name;

          if (name === "like") {
            _this.trackActions.likeTrack(track);
          } else if (name === "buy") {
            _this.shoppingCart.addTrackToCart(track, _this.release);
          } else if (name === "show-track") {
            _this.router.transitionTo("track.show", track.profileSlug, track.slug);
          } else if (name === "show-label") {
            _this.router.transitionTo("profile.index", track.profileSlug);
          } else if (name === "share-facebook") {
            var url = shareUrl(track);
            var text = shareText(track);
            (0, _socialLink.openSharePopup)((0, _socialLink.buildFbUrl)(url, text));
          } else if (name === "share-twitter") {
            var _url = shareUrl(track);

            var _text = shareText(track);

            (0, _socialLink.openSharePopup)((0, _socialLink.buildTwitterUrl)(_url, _text));
          }

          closeOverlay();
        },
        overlayActions: overlayActions
      }].filter(function (val) {
        return val;
      });
    }),
    currentTrack: Ember.computed("persistentPlayerState.currentTrack", {
      get: function get() {
        return this.persistentPlayerState.currentTrack;
      },
      set: function set(key, val) {
        return val;
      }
    }),
    togglePlay: function togglePlay(track) {
      var _this$persistentPlaye = this.persistentPlayerState,
          currentTrack = _this$persistentPlaye.currentTrack,
          isPlayingTrack = _this$persistentPlaye.isPlayingTrack;
      var isActiveTrack = currentTrack && currentTrack.id === track.id;

      if (isActiveTrack) {
        if (isPlayingTrack) {
          this.messageBus.publish("playback:global:pause", track);
        } else {
          this.messageBus.publish("playback:global:play", track);
        }
      } else {
        this.messageBus.publish("playback:global:play", track);
      }
    },
    actions: {
      onRowDoubleClick: function onRowDoubleClick(row) {
        if (!this.media.isMobile) {
          this.togglePlay(row.content);
        }
      },
      onRowClick: function onRowClick(row) {
        if (this.media.isMobile) {
          this.togglePlay(row.content);
        }
      }
    }
  });

  _exports.default = _default;
});