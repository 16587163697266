define("formaviva-web/_pods/components/async-button/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "g3z25hj4",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[4,\"if\",[[25,[\"isLoading\"]]],null,{\"statements\":[[0,\"  \"],[1,[29,\"fa-icon\",[\"circle-o-notch\"],[[\"spin\"],[true]]],false],[0,\"\\n\"],[4,\"if\",[[25,[\"loadingLabel\"]]],null,{\"statements\":[[0,\"    \"],[1,[23,\"loadingLabel\"],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[15,1],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[15,1],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "formaviva-web/_pods/components/async-button/template.hbs"
    }
  });

  _exports.default = _default;
});