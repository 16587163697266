define("formaviva-web/_pods/components/truncate-with-tooltip/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "3jQ21ps0",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[15,1],[0,\"\\n\"],[4,\"if\",[[25,[\"needTooltip\"]]],null,{\"statements\":[[0,\"  \"],[4,\"bs-tooltip\",null,[[\"placement\"],[\"top\"]],{\"statements\":[[4,\"if\",[[25,[\"tooltipContent\"]]],null,{\"statements\":[[1,[23,\"tooltipContent\"],false]],\"parameters\":[]},{\"statements\":[[15,1]],\"parameters\":[]}]],\"parameters\":[]},null],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "formaviva-web/_pods/components/truncate-with-tooltip/template.hbs"
    }
  });

  _exports.default = _default;
});