define("formaviva-web/_pods/edit/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    hasCustomAsyncTitle: true,
    beforeModel: function beforeModel(transition) {
      if (transition.targetName === "edit.index") {
        this.transitionTo("edit.profile");
      }
    }
  });

  _exports.default = _default;
});