define("formaviva-web/_pods/analytics/sale/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model: function model(params) {
      return this.store.findRecord("purchase", params.purchase_id, {
        adapterOptions: {
          extra_params: {
            sales: true
          }
        }
      });
    }
  });

  _exports.default = _default;
});