define("formaviva-web/_pods/profile/mixes/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    renderTemplate: function renderTemplate() {
      this.render({
        into: "profile",
        outlet: "profile-content"
      });
    },
    headData: Ember.inject.service(),
    hasCustomAsyncTitle: true,
    afterModel: function afterModel(model) {
      var profile = model.profile;
      var title = "".concat(profile.name, " Mixes | Formaviva");
      this.set("headData.title", title);
      this.set("headData.description", profile.bio);
      this.set("headData.imageUrl", profile.adjustedImageUrl);
    },
    setupController: function setupController(controller, model) {
      controller.set("isLoading", true);
      this.fetchMixes(model.profile.slug, model.page).then(function (mixes) {
        controller.set("mixes", mixes);
        controller.set("page", model.page);
      }).finally(function () {
        controller.set("isLoading", false);
      });
    },
    fetchMixes: function fetchMixes(profileSlug, page) {
      return this.store.query("track", {
        page: page,
        public: true,
        per_page: 20,
        sort: "-created_at",
        filter: {
          profile_slug: profileSlug,
          mix: true
        }
      });
    }
  });

  _exports.default = _default;
});