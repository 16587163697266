define("formaviva-web/_pods/components/profile/follow-profile-button/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    buttonDisplay: Ember.computed("prelaunchLocked", "profile.followed", function () {
      var followed = this.get("profile.followed");
      var caption = "";

      if (!followed) {
        if (this.get("prelaunchLocked")) {
          caption = "Follow Label";
        } else {
          caption = "Follow";
        }
      } else {
        caption = "Following";
      }

      return caption;
    }),
    isPending: Ember.computed.alias("profileActions.followProfile.isRunning"),
    profileActions: Ember.inject.service("profile/profile-actions"),
    actions: {
      followProfile: function followProfile(profile) {
        this.profileActions.followProfile.perform(profile);
      }
    }
  });

  _exports.default = _default;
});