define("formaviva-web/_pods/components/release/release-track-selector/component", ["exports", "formaviva-web/utils/scrollable-table"], function (_exports, _scrollableTable) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    didRender: function didRender() {
      var $table = this.$(".fv-table-scrollable");
      (0, _scrollableTable.default)($table);
    }
  });

  _exports.default = _default;
});