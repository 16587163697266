define("formaviva-web/_pods/components/sortable-table-head/component", ["exports", "ember-awesome-macros/raw", "ember-awesome-macros/array", "ember-awesome-macros"], function (_exports, _raw, _array, _emberAwesomeMacros) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: "th",
    classNames: ["sortable-table-head"],
    sortObjectForField: (0, _array.findBy)("currentSorting", (0, _raw.default)("field"), "field"),
    sortingEnabled: (0, _emberAwesomeMacros.notEmpty)("sortObjectForField"),
    currentDirection: (0, _emberAwesomeMacros.getBy)("sortObjectForField", (0, _raw.default)("direction")),
    oppositeDirection: (0, _emberAwesomeMacros.conditional)((0, _emberAwesomeMacros.equal)("currentDirection", (0, _raw.default)("asc")), (0, _raw.default)("desc"), (0, _raw.default)("asc")),
    oppositeDirectionSing: (0, _emberAwesomeMacros.conditional)((0, _emberAwesomeMacros.equal)("oppositeDirection", (0, _raw.default)("asc")), (0, _raw.default)(""), (0, _raw.default)("-")),
    sortIcon: (0, _emberAwesomeMacros.conditional)((0, _emberAwesomeMacros.equal)("currentDirection", (0, _raw.default)("desc")), (0, _raw.default)("caret-down"), (0, _raw.default)("caret-up")),
    newSortParams: Ember.computed("field", "oppositeDirection", "oppositeDirectionSing", function () {
      return "".concat(this.oppositeDirectionSing).concat(this.field);
    })
  });

  _exports.default = _default;
});