define("formaviva-web/_pods/izvir/controller", ["exports", "ember-cp-validations"], function (_exports, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var AttendeeValidation = (0, _emberCpValidations.buildValidations)({
    name: (0, _emberCpValidations.validator)("presence", true),
    email: [(0, _emberCpValidations.validator)("presence", true), (0, _emberCpValidations.validator)("format", {
      type: "email"
    })]
  });

  var _default = Ember.Controller.extend({
    // status stuff
    queryParams: ["status"],
    status: null,
    hasStatus: Ember.computed("status", function () {
      return !Ember.isEmpty(this.get("status"));
    }),
    isSuccessful: Ember.computed("status", function () {
      return this.get("status") == "success" ? true : false;
    }),
    isUnsuccessful: Ember.computed("status", function () {
      return this.get("status") == "failure" ? true : false;
    }),
    isCancelled: Ember.computed("status", function () {
      return this.get("status") == "cancel" ? true : false;
    }),
    isDonationType: Ember.computed("model.merchType", function () {
      return this.get("model.merchType") == 1 ? true : false;
    }),
    hasSuccessMessage: Ember.computed("model.successMessage", function () {
      return !Ember.isEmpty(this.get("model.successMessage"));
    }),
    isMultiplePeople: Ember.computed("order.quantity", function () {
      return parseInt(this.order.quantity) > 1;
    }),
    // other
    totalPrice: Ember.computed("model.price", "order.quantity", function () {
      return this.get("order.quantity") * this.get("model.price");
    }),
    disableForm: Ember.computed.reads("formSubmitted"),
    quantityChanged: Ember.observer("order.quantity", function () {
      var _this = this;

      var quantity = parseInt(this.order.quantity);

      if (isNaN(quantity)) {
        return;
      }

      var attendees = this.order.get("attendees");
      var newAttendees = Array(quantity).fill().map(function (_, i) {
        var attendee = attendees[i];

        if (attendee) {
          return attendee;
        } else {
          return _this.newAttendee();
        }
      });
      this.order.set("attendees", newAttendees);
    }),
    newAttendee: function newAttendee() {
      var Attendee = Ember.Object.extend(AttendeeValidation);
      return Attendee.create(Ember.getOwner(this).ownerInjection(), {
        name: null,
        email: null
      });
    },
    actions: {
      removeAttendee: function removeAttendee() {
        var attendees = this.order.get("attendees").concat();
        var newAttendees = attendees.splice(-1, 1); // Remove last

        this.order.set("attendees", newAttendees);
      },
      addAttendee: function addAttendee() {
        if (this.order.attendees == null) {
          this.order.set("attendees", []);
        }

        var AttendeeValidation = (0, _emberCpValidations.buildValidations)({
          name: (0, _emberCpValidations.validator)("presence", true),
          email: [(0, _emberCpValidations.validator)("presence", true), (0, _emberCpValidations.validator)("format", {
            type: "email"
          })]
        });
        var Attendee = Ember.Object.extend(AttendeeValidation);
        var attendee = Attendee.create(Ember.getOwner(this).ownerInjection(), {
          name: null,
          email: null
        });
        this.order.attendees.pushObject(attendee);
      },
      submit: function submit(order) {
        var _this2 = this;

        order.validate().then(function (_ref) {
          var validations = _ref.validations;

          if (validations.get("isValid")) {
            order.set("totalPrice", order.get("quantity") * _this2.get("model.price"));
            order.set("name", order.attendees.get("firstObject.name"));
            order.set("email", order.attendees.get("firstObject.email"));

            _this2.set("formSubmitted", true);

            _this2.send("submitOrder", order); // save in the route

          } else {
            _this2.setProperties({
              showQuantityError: true,
              showNameError: true,
              showEmailError: true
            });
          }
        });
      }
    }
  });

  _exports.default = _default;
});