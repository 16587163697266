define("formaviva-web/_pods/merch/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model: function model(params) {
      return this.store.query("merch", {
        public: true,
        filter: {
          profile_slug: params.profile_slug,
          slug: params.merch_slug
        }
      }).then(function (t) {
        return t.get("firstObject");
      });
    }
  });

  _exports.default = _default;
});