define("formaviva-web/_pods/components/profile/profile-grid/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    artistsLimit: 2,
    actions: {
      toggleHiddenArtists: function toggleHiddenArtists() {
        this.set("artistsLimit", this.artists.length);
      }
    }
  });

  _exports.default = _default;
});