define("formaviva-web/templates/head", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Mv9bj0TI",
    "block": "{\"symbols\":[],\"statements\":[[7,\"meta\"],[11,\"property\",\"og:site_name\"],[11,\"content\",\"Formaviva\"],[9],[10],[0,\"\\n\"],[7,\"meta\"],[11,\"property\",\"og:type\"],[11,\"content\",\"website\"],[9],[10],[0,\"\\n\"],[7,\"meta\"],[11,\"property\",\"og:title\"],[12,\"content\",[25,[\"model\",\"title\"]]],[9],[10],[0,\"\\n\"],[7,\"meta\"],[11,\"property\",\"og:description\"],[12,\"content\",[25,[\"model\",\"description\"]]],[9],[10],[0,\"\\n\"],[7,\"meta\"],[11,\"property\",\"og:image\"],[11,\"itemprop\",\"image\"],[12,\"content\",[25,[\"model\",\"imageUrl\"]]],[9],[10],[0,\"\\n\"],[7,\"meta\"],[11,\"property\",\"og:image:url\"],[11,\"itemprop\",\"image\"],[12,\"content\",[29,\"string-replace\",[[25,[\"model\",\"imageUrl\"]],\"https://\",\"http://\"],null]],[9],[10],[0,\"\\n\"],[7,\"meta\"],[11,\"property\",\"og:image:secure_url\"],[12,\"content\",[25,[\"model\",\"imageUrl\"]]],[9],[10],[0,\"\\n\"],[4,\"if\",[[25,[\"model\",\"canonicalUrl\"]]],null,{\"statements\":[[0,\"  \"],[7,\"meta\"],[11,\"property\",\"og:url\"],[12,\"content\",[25,[\"model\",\"canonicalUrl\"]]],[9],[10],[0,\"\\n  \"],[7,\"link\"],[11,\"rel\",\"canonical\"],[12,\"href\",[25,[\"model\",\"canonicalUrl\"]]],[9],[10],[0,\"\\n\"]],\"parameters\":[]},null],[7,\"meta\"],[11,\"property\",\"og:image:width\"],[11,\"content\",\"300\"],[9],[10],[0,\"\\n\"],[7,\"meta\"],[11,\"property\",\"og:image:height\"],[11,\"content\",\"300\"],[9],[10],[0,\"\\n\"],[7,\"title\"],[9],[1,[25,[\"model\",\"title\"]],false],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "formaviva-web/templates/head.hbs"
    }
  });

  _exports.default = _default;
});