define("formaviva-web/_pods/components/release/release-track-selector-item/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNameBindings: ["isSelected:selected"],
    isSelected: Ember.computed("track", "tracksToAdd.[]", function () {
      var selectedTrackIds = this.get("tracksToAdd").mapBy("id");
      return selectedTrackIds.indexOf(this.get("track").id) !== -1;
    }),
    didInsertElement: function didInsertElement() {
      var _this = this;

      this.$().click(function (e) {
        var fromButton = e.target.classList.contains("btn");

        if (fromButton) {
          return;
        }

        _this.get("toggleTrack")(_this.get("track"));
      });
    }
  });

  _exports.default = _default;
});