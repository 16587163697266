define("formaviva-web/models/user", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Model = _emberData.default.Model;
  var belongsTo = _emberData.default.belongsTo;
  var attr = _emberData.default.attr;

  var _default = Model.extend({
    email: attr("string"),
    name: attr("string"),
    profileType: attr("string"),
    password: attr("string"),
    currentPassword: attr("string"),
    referralSource: attr("string"),
    profile: belongsTo("profile"),
    access: attr(),
    // placeholder attribute to handle the user's input
    // and pass it to the API
    username: attr("string"),
    profileSlug: Ember.computed.alias("profile.slug"),
    profileId: Ember.computed.alias("profile.id"),
    shoppingCart: attr(),
    checkoutData: attr(),
    pendingClaimsCount: attr("number"),
    mixpanelDistinctId: attr("string"),
    canAdmin: Ember.computed("access.access_type", function () {
      return ["admin", "owner"].indexOf(this.get("access.access_type")) !== -1;
    }),
    fv_user_behaviour_initial_distinct_id: attr("string"),
    // For mixpanel
    recaptcha: attr("string")
  });

  _exports.default = _default;
});