define("formaviva-web/services/current-session", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    session: Ember.inject.service("session"),
    store: Ember.inject.service(),
    countries: Ember.inject.service(),
    fastboot: Ember.inject.service(),
    profile: Ember.computed.alias("user.profile"),
    isAuthenticated: Ember.computed.alias("session.isAuthenticated"),
    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);

      if (this.get("fastboot.isFastBoot")) return;
      this.get("countries").getIpInfo().then(function (data) {
        _this.set("userTrackingProps", data);
      });
    },
    load: function load() {
      var _this2 = this;

      if (!this.get("session.isAuthenticated")) {
        return Ember.RSVP.resolve(null);
      }

      return this.get("store").queryRecord("user", {
        me: true
      }).then(function (user) {
        _this2.set("user", user);

        _this2.get("session").set("data.profileId", user.get("profile.id"));

        return user;
      }).catch(function () {
        _this2.session.invalidate();
      });
    },
    nullify: function nullify() {
      this.set("user", null);
    }
  });

  _exports.default = _default;
});