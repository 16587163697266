define("formaviva-web/components/loader-ball-zig-zag", ["exports", "ember-cli-loaders/components/loader-ball-zig-zag"], function (_exports, _loaderBallZigZag) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _loaderBallZigZag.default;
    }
  });
});