define("formaviva-web/_pods/components/user/user-login/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    session: Ember.inject.service(),
    appState: Ember.inject.service(),
    init: function init() {
      this._super.apply(this, arguments);

      this.set("username", null);
      this.set("password", null);
    },
    actions: {
      login: function login(username, password, e) {
        var _this = this;

        e.preventDefault();
        this.appState.setIsImpersonatedSession(false);
        this.set("isLoggingIn", true);
        this.get("session").authenticate("authenticator:oauth2", username, password).then(function () {
          return _this.userDidLogin();
        }).catch(function () {
          return _this.set("errorMessage", "Invalid login.");
        }).finally(function () {
          return _this.set("isLoggingIn", false);
        });
      }
    }
  });

  _exports.default = _default;
});