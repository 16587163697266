define("formaviva-web/mixins/track-actions", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    actions: {
      saveTrack: function saveTrack(track) {
        var _this = this;

        if (!track.get("validations.isValid")) {
          var isOriginalArtistPresent = track.remixerIds.length < track.artists.length;
          var artistsValidation = track.validations.attrs.artists;
          this.set("errorMessage", null);
          this.set("disableSave", false);

          if (!isOriginalArtistPresent && !track.mix) {
            this.set("disableSave", true);
            this.set("errorMessage", "should have at least one original artist");
            return;
          } else if (artistsValidation.isInvalid) {
            this.set("disableSave", true);
            this.set("errorMessage", artistsValidation.message);
            this.notifications.error("Artist field should be filled", {
              autoClear: true
            });
            return;
          }
        }

        this.set("isSaving", true);
        track.save().then(function (track) {
          _this.set("isSaved", true);

          var recordType = track.mix ? "Mix" : "Track";

          _this.notifications.success("".concat(recordType, " saved successfully."), {
            autoClear: true
          });

          track.trigger("saved-record"); // for unsaved-record-notification

          if (window.history.length && !track.mix) {
            window.history.back();
          } else {
            _this.transitionToRoute("track.show", track.profileSlug, track.slug);
          }
        }).catch(function () {
          _this.notifications.error("There were some errors while saving the track.", {
            autoClear: true
          });
        }).finally(function () {
          return _this.set("isSaving", false);
        });
      },
      deleteTrack: function deleteTrack(track) {
        var _this2 = this;

        var type = track.mix ? "mix" : "track";
        if (!confirm("Are you sure you want to delete this ".concat(type, "?"))) return;
        this.set("isDeleting", true);
        track.destroyRecord().then(function () {
          _this2.notifications.success("".concat(Ember.String.capitalize(type), " deleted."), {
            autoClear: true
          });

          if (window.history.length) {
            window.history.back();
          } else {
            _this2.transitionToRoute("dashboard");
          }
        }).catch(function () {
          return _this2.notifications.error("There were some errors while deleting the ".concat(type), {
            autoClear: true
          });
        }).finally(function () {
          _this2.set("isDeleting", false);
        });
      }
    }
  });

  _exports.default = _default;
});