define("formaviva-web/_pods/dashboard/releases/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "mcrfI/sQ",
    "block": "{\"symbols\":[\"release\",\"@canEdit\"],\"statements\":[[7,\"div\"],[11,\"class\",\"clearfix pb-8\"],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"pull-left pt-2\"],[9],[0,\"You currently have \"],[1,[29,\"pluralize\",[[29,\"or\",[[25,[\"model\",\"meta\",\"total\"]],0],null],\"release\"],null],false],[10],[0,\"\\n\"],[10],[0,\"\\n\"],[1,[29,\"collection-pagination\",null,[[\"class\",\"collection\",\"path\",\"currentPage\"],[\"no-margins\",[25,[\"model\"]],\"dashboard.releases\",[25,[\"page\"]]]]],false],[0,\"\\n\"],[7,\"div\"],[11,\"class\",\"fv-box-list\"],[9],[0,\"\\n\"],[4,\"each\",[[25,[\"model\"]]],null,{\"statements\":[[0,\"    \"],[1,[29,\"fv-box\",null,[[\"release\",\"canEdit\",\"class\"],[[24,1,[]],[24,2,[]],\"fv-box-list__item\"]]],false],[0,\"\\n\"]],\"parameters\":[1]},null],[10],[0,\"\\n\"],[1,[29,\"collection-pagination\",null,[[\"class\",\"collection\",\"path\",\"currentPage\"],[\"no-margins\",[25,[\"model\"]],\"dashboard.releases\",[25,[\"page\"]]]]],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "formaviva-web/_pods/dashboard/releases/template.hbs"
    }
  });

  _exports.default = _default;
});