define("formaviva-web/initializers/inject-notifications", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.initialize = initialize;
  _exports.default = void 0;

  function initialize(application) {
    application.inject("controller", "notifications", "service:notification-messages");
    application.inject("route", "notifications", "service:notification-messages");
    application.inject("component", "notifications", "service:notification-messages");
  }

  var _default = {
    name: "inject-notifications",
    initialize: initialize
  };
  _exports.default = _default;
});