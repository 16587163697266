define("formaviva-web/_pods/components/collection-pagination/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }

  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(n); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && Symbol.iterator in Object(iter)) return Array.from(iter); }

  function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  var _default = Ember.Component.extend({
    tagName: "",
    range: function range(start, end) {
      return _toConsumableArray(Array(1 + end - start).keys()).map(function (v) {
        return start + v;
      });
    },
    pageCount: Ember.computed("collection.meta.pages", function () {
      return parseInt(this.get("collection.meta.pages"));
    }),
    pagesList: Ember.computed("collection.meta", "currentPage", "pageCount", function () {
      var pageCount = this.get("pageCount");
      var pages = [];

      if (pageCount > 0) {
        pages = this.range(1, pageCount);
      }

      if (pages.length > 10) {
        // Show first, four pages before current, four pages after current and last
        var currentPage = this.get("currentPage");
        var left = this.range(currentPage - 4, currentPage - 1).filter(function (p) {
          return p > 1;
        });
        var right = this.range(currentPage + 1, currentPage + 4).filter(function (p) {
          return p < pageCount;
        });
        pages = [1].concat(left).concat([currentPage]).concat(right).concat([pageCount]).uniq();
        var pagesWithTruncation = [];
        pages.forEach(function (page, index) {
          pagesWithTruncation.push(page);

          if (pages[index + 1] && pages[index + 1] - page != 1) {
            pagesWithTruncation.push(null); // Ellipsis in template
          }
        });
        pages = pagesWithTruncation;
      }

      return pages;
    }),
    paginationShown: Ember.computed("pagesList", function () {
      return this.get("pagesList").length > 0;
    }),
    previousPage: Ember.computed("pagesList", "currentPage", function () {
      var previous = parseInt(this.get("currentPage")) - 1;
      if (previous >= 1) return previous;
      return undefined;
    }),
    nextPage: Ember.computed("currentPage", "pageCount", "pagesList", function () {
      var next = parseInt(this.get("currentPage")) + 1;
      if (next <= this.get("pageCount")) return next;
      return undefined;
    })
  });

  _exports.default = _default;
});