define("formaviva-web/utils/object", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.deepClone = deepClone;
  _exports.isEqual = isEqual;
  _exports.objectEqual = objectEqual;
  _exports.arrayEqual = arrayEqual;

  function _typeof(obj) { "@babel/helpers - typeof"; if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") { _typeof = function _typeof(obj) { return typeof obj; }; } else { _typeof = function _typeof(obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }; } return _typeof(obj); }

  // utility helpers for cloning and deep comparing objects
  function deepClone(item) {
    var clone = item;

    if (Array.isArray(item)) {
      clone = new Array(item.length);
      item.forEach(function (child, key) {
        clone[key] = deepClone(child);
      });
    } else if (item && _typeof(item) === "object") {
      clone = {};
      Object.keys(item).forEach(function (key) {
        clone[key] = deepClone(item[key]);
      });
    }

    return clone;
  }

  var DEFAULT_COMPARE = function DEFAULT_COMPARE(a, b) {
    return a === b;
  };

  function isEqual(a, b, isDeep) {
    if (Array.isArray(a)) {
      if (!arrayEqual(a, b, isDeep)) {
        return false;
      }
    } else if (_typeof(a) === "object") {
      if (!objectEqual(a, b, isDeep)) {
        return false;
      }
    } else {
      if (!DEFAULT_COMPARE(a, b)) {
        return false;
      }
    }

    return true;
  }

  function objectEqual(a, b, isDeep) {
    var compare = isDeep ? isEqual : DEFAULT_COMPARE;
    var k;
    var aCount = 0;
    var bCount = 0;

    for (k in a) {
      if (a.hasOwnProperty(k)) {
        if (!compare(a[k], b[k], isDeep)) {
          return false;
        }

        aCount++;
      }
    }

    for (k in b) {
      if (b.hasOwnProperty(k)) {
        bCount++;
      }
    }

    return aCount === bCount;
  }

  function arrayEqual(a, b, isDeep) {
    var compare = isDeep ? isEqual : DEFAULT_COMPARE;
    var l = a.length;
    if (l !== b.length) return false;

    for (var i = 0; i < l; i++) {
      if (!compare(a[i], b[i], isDeep)) return false;
    }

    return true;
  }
});