define("formaviva-web/_pods/search/controller", ["exports", "ember-awesome-macros", "ember-inflector", "formaviva-web/utils/display-round-off"], function (_exports, _emberAwesomeMacros, _emberInflector, _displayRoundOff) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    router: Ember.inject.service(),
    queryParams: ["term", "filter", "page"],
    filter: null,
    term: null,
    page: 1,
    profiles: Ember.computed.alias("model.profiles"),
    releases: Ember.computed.alias("model.releases"),
    tracks: Ember.computed.alias("model.tracks"),
    mixes: Ember.computed.alias("model.mixes"),
    shouldDisplayProfiles: (0, _emberAwesomeMacros.or)(Ember.computed.equal("filter", "profile"), Ember.computed.equal("filter", "all")),
    shouldDisplayReleases: (0, _emberAwesomeMacros.or)(Ember.computed.equal("filter", "release"), Ember.computed.equal("filter", "all")),
    shouldDisplayTracks: (0, _emberAwesomeMacros.or)(Ember.computed.equal("filter", "track"), Ember.computed.equal("filter", "all")),
    shouldDisplayMixes: (0, _emberAwesomeMacros.or)(Ember.computed.equal("filter", "mix"), Ember.computed.equal("filter", "all")),
    releasesCount: (0, _emberAwesomeMacros.conditional)("releases.meta.total", "releases.meta.total", 0),
    profilesCount: (0, _emberAwesomeMacros.conditional)("profiles.meta.total", "profiles.meta.total", 0),
    tracksCount: (0, _emberAwesomeMacros.conditional)("tracks.meta.total", "tracks.meta.total", 0),
    mixesCount: (0, _emberAwesomeMacros.conditional)("mixes.meta.total", "mixes.meta.total", 0),
    allResultsCount: (0, _emberAwesomeMacros.add)("releasesCount", "tracksCount", "mixesCount", "profilesCount"),
    resultsCount: Ember.computed("model", "profilesCount", "releasesCount", "tracksCount", "mixesCount", function () {
      var countTextArray = [];
      var releasesCount = this.releasesCount;
      var tracksCount = this.tracksCount;
      var mixesCount = this.mixesCount;
      var profilesCount = this.profilesCount;

      if (releasesCount > 0) {
        countTextArray.push("".concat((0, _displayRoundOff.default)(releasesCount), " ").concat((0, _emberInflector.pluralize)(releasesCount, "releases", {
          withoutCount: true
        })));
      }

      if (tracksCount > 0) {
        countTextArray.push("".concat((0, _displayRoundOff.default)(tracksCount), " ").concat((0, _emberInflector.pluralize)(tracksCount, "tracks", {
          withoutCount: true
        })));
      }

      if (mixesCount > 0) {
        countTextArray.push("".concat((0, _displayRoundOff.default)(mixesCount), " ").concat((0, _emberInflector.pluralize)(mixesCount, "mixes", {
          withoutCount: true
        })));
      }

      if (profilesCount > 0) {
        countTextArray.push("".concat((0, _displayRoundOff.default)(profilesCount), " ").concat((0, _emberInflector.pluralize)(profilesCount, "profiles", {
          withoutCount: true
        })));
      }

      var displayText = countTextArray.reduce(function (a, b, i, countTextArray) {
        return a + (i < countTextArray.length - 1 ? ", " : " and ") + b;
      });
      return displayText;
    }),
    noResultsFound: Ember.computed("filter", "profiles", "releases", "tracks", "mixes", function () {
      switch (this.filter) {
        case "profile":
          return Ember.isBlank(this.profiles);

        case "release":
          return Ember.isBlank(this.releases);

        case "track":
          return Ember.isBlank(this.tracks);

        case "mix":
          return Ember.isBlank(this.mixes);

        default:
          return Ember.isBlank(this.profiles) && Ember.isBlank(this.releases) && Ember.isBlank(this.tracks) && Ember.isBlank(this.mixes);
      }
    }),
    showAllResults: Ember.computed("filter", function () {
      return Ember.isBlank(this.filter) || this.filter === "all";
    }),
    actions: {
      setFilter: function setFilter(filterValue) {
        var queryParams = {};
        queryParams.term = this.term;
        queryParams.filter = filterValue;
        this.router.transitionTo({
          queryParams: queryParams
        });
      }
    }
  });

  _exports.default = _default;
});