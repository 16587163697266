define("formaviva-web/_pods/components/table/cell/track-mobile/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    persistentPlayerState: Ember.inject.service(),
    isOverlayActionVisible: false,
    isActiveTrack: Ember.computed("row.content.id", "extra.extraData.currentTrack", function () {
      var currentTrack = this.get("extra.extraData.currentTrack");
      return currentTrack && currentTrack.id === this.row.content.id;
    })
  });

  _exports.default = _default;
});