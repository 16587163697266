define("formaviva-web/_pods/components/profile/profile-grid/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "dkEHjKvJ",
    "block": "{\"symbols\":[\"artist\",\"index\"],\"statements\":[[1,[29,\"profile/profile-info\",null,[[\"profile\"],[[25,[\"profile\"]]]]],false],[0,\"\\n\\n\"],[4,\"if\",[[25,[\"artists\"]]],null,{\"statements\":[[0,\"  \"],[7,\"div\"],[11,\"class\",\"offset-top\"],[9],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"collection-title clearfix\"],[9],[0,\"\\n      \"],[7,\"span\"],[11,\"class\",\"pull-left\"],[9],[0,\"Artists (\"],[1,[25,[\"artists\",\"length\"]],false],[0,\")\"],[10],[0,\"\\n    \"],[10],[0,\"\\n\"],[4,\"each\",[[25,[\"artists\"]]],null,{\"statements\":[[4,\"if\",[[29,\"and\",[[29,\"lt\",[[24,2,[]],[25,[\"artistsLimit\"]]],null],[29,\"not-eq\",[[25,[\"profile\"]],[24,1,[]]],null]],null]],null,{\"statements\":[[0,\"        \"],[7,\"div\"],[12,\"class\",[29,\"if\",[[29,\"gt\",[[24,2,[]],0],null],\"border-top__thin\"],null]],[9],[0,\"\\n          \"],[1,[29,\"profile/profile-info\",null,[[\"profile\",\"class\"],[[24,1,[]],\"offset-top offset-bottom\"]]],false],[0,\"\\n        \"],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[1,2]},null],[4,\"liquid-if\",[[29,\"lt\",[[25,[\"artistsLimit\"]],[25,[\"artists\",\"length\"]]],null]],[[\"use\"],[\"toDown\"]],{\"statements\":[[0,\"      \"],[7,\"div\"],[11,\"class\",\"artists__view-all cursor-pointer text-center clearfix\"],[12,\"onclick\",[29,\"action\",[[24,0,[]],\"toggleHiddenArtists\"],null]],[9],[0,\"\\n        View all artists\\n      \"],[10],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"  \"],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "formaviva-web/_pods/components/profile/profile-grid/template.hbs"
    }
  });

  _exports.default = _default;
});