define("formaviva-web/helpers/display-round-off-value", ["exports", "formaviva-web/utils/display-round-off"], function (_exports, _displayRoundOff) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.displayRoundOffValue = displayRoundOffValue;
  _exports.default = void 0;

  function displayRoundOffValue(params) {
    return (0, _displayRoundOff.default)(params[0]);
  }

  var _default = Ember.Helper.helper(displayRoundOffValue);

  _exports.default = _default;
});