define("formaviva-web/_pods/track/show/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    headData: Ember.inject.service(),
    analyticsTracker: Ember.inject.service("analytics/event-tracker"),
    hasCustomAsyncTitle: true,
    afterModel: function afterModel(model) {
      // Set meta tags in the head (important for crawlers);
      this.set("headData.title", model.get("displayName"));
      this.set("headData.description", model.get("description"));
      this.set("headData.imageUrl", model.get("adjustedImageUrl"));
      this.set("headData.audio", model.get("lowQualityUrl"));
      this.set("headData.canonicalUrl", model.get("canonicalUrl"));
    },
    actions: {
      didTransition: function didTransition() {
        var track = this.controller.get("track");
        this.get("analyticsTracker").track({
          track_id: track.id,
          event: "view"
        });
        this.userBehaviourTracker.track({
          event: "track_page",
          name: track.get("displayName")
        });
        return true; // Bubble the didTransition event
      }
    }
  });

  _exports.default = _default;
});