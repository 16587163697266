define("formaviva-web/_pods/components/track/track-likes-widget/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    currentSession: Ember.inject.service(),
    currentProfile: Ember.computed.alias("currentSession.profile"),
    fastboot: Ember.inject.service(),
    ajax: Ember.inject.service(),
    store: Ember.inject.service(),
    showLikedProfiles: false,
    classNames: ["mr-2", "track-likes-widget"],
    init: function init() {
      this._super.apply(this, arguments);

      if (this.userAgent.device.isMobile) {
        this.set("profilesLimit", 3);
      } else {
        this.set("profilesLimit", 5);
      }
    },
    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);

      if (this.fastboot.isFastBoot) return;
      this.set("likedProfiles", Ember.A());
      this.loadLikedProfiles();
    },
    loadLikedProfiles: function loadLikedProfiles() {
      var _this = this;

      var page = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 1;
      if (Ember.isPresent(this.get("likedProfiles")) && page === 1) return;
      var promise = this.store.query("profile", {
        page: page,
        per_page: 20,
        engagements: true,
        filter: {
          engagement_type: "liked_profiles_for_track",
          track_slug: this.track.slug,
          slug: this.track.profileSlug
        }
      });
      this.set("isFetching", true);
      promise.then(function (profiles) {
        _this.set("currentPage", page);

        _this.set("totalProfiles", parseInt(profiles.meta.total));

        _this.set("totalProfilePages", parseInt(profiles.meta.pages));

        var existingLikedProfiles = _this.get("likedProfiles") || [];

        if (existingLikedProfiles.length !== _this.totalProfiles) {
          profiles = profiles.sortBy("adjustedImageUrl").reverse();
          existingLikedProfiles = existingLikedProfiles.concat(profiles);

          _this.set("likedProfiles", existingLikedProfiles);
        }

        _this.set("hideLoadMore", existingLikedProfiles.length === _this.totalProfiles);
      });
      promise.finally(function () {
        _this.set("isFetching", false);
      });
      return promise;
    },
    actions: {
      toggleShowLikedProfiles: function toggleShowLikedProfiles() {
        var showLikedProfiles = this.showLikedProfiles;
        this.set("showLikedProfiles", !showLikedProfiles);
      }
    }
  });

  _exports.default = _default;
});