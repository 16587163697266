define("formaviva-web/_pods/components/overlay-spinner/component", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    didInsertElement: function didInsertElement() {
      this._super();

      (0, _jquery.default)("#overlay_spinner").fadeIn("slow");
    },
    willDestroyElement: function willDestroyElement() {
      (0, _jquery.default)("#overlay_spinner").fadeOut("slow");
    }
  });

  _exports.default = _default;
});