define("formaviva-web/_pods/components/table/cell/player-control/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Ae1v8Pbc",
    "block": "{\"symbols\":[\"@row\",\"@column\"],\"statements\":[[7,\"button\"],[11,\"class\",\"main-control-button\"],[11,\"type\",\"button\"],[9],[0,\"\\n\"],[4,\"if\",[[25,[\"isActiveTrack\"]]],null,{\"statements\":[[4,\"if\",[[25,[\"persistentPlayerState\",\"showLoadingIndicator\"]]],null,{\"statements\":[[0,\"      \"],[1,[23,\"ellipsis-animation\"],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[25,[\"persistentPlayerState\",\"isPlayingTrack\"]]],null,{\"statements\":[[0,\"      \"],[1,[29,\"fa-icon\",[\"pause\"],[[\"class\"],[\"icon-pause\"]]],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"      \"],[1,[29,\"fa-icon\",[\"play\"],[[\"class\"],[\"icon-play\"]]],false],[0,\"\\n    \"]],\"parameters\":[]}]],\"parameters\":[]}]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[1,[29,\"fa-icon\",[\"play\"],[[\"class\"],[\"icon-play\"]]],false],[0,\"\\n\"]],\"parameters\":[]}],[3,\"action\",[[24,0,[]],[24,2,[\"togglePlay\"]],[24,1,[]]],[[\"bubbles\"],[false]]],[10],[0,\"\\n\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "formaviva-web/_pods/components/table/cell/player-control/template.hbs"
    }
  });

  _exports.default = _default;
});