define("formaviva-web/_pods/components/release/release-buy-buttons/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    shoppingCart: Ember.inject.service("checkout/shopping-cart"),
    classNames: ["release-top-buy-area"],
    actions: {
      addToCart: function addToCart(item) {
        switch (item.recordType) {
          case "merch":
            this.shoppingCart.addMerchToCart(item);
            break;

          case "release":
            this.shoppingCart.addReleaseToCart(item);
            break;
        }
      }
    }
  });

  _exports.default = _default;
});