define("formaviva-web/_pods/components/table/cell/row-toggle/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "8b8OdCit",
    "block": "{\"symbols\":[\"@row\"],\"statements\":[[7,\"a\"],[11,\"class\",\"row-toggle\"],[9],[0,\"\\n  \"],[7,\"i\"],[12,\"class\",[30,[\"fa \",[29,\"if\",[[24,1,[\"expanded\"]],\"fa-chevron-down\",\"fa-chevron-right\"],null]]]],[9],[10],[0,\"\\n\"],[3,\"action\",[[24,0,[]],[29,\"toggle\",[\"expanded\",[24,1,[]]],null]],[[\"bubbles\"],[false]]],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "formaviva-web/_pods/components/table/cell/row-toggle/template.hbs"
    }
  });

  _exports.default = _default;
});