define("formaviva-web/models/merch", ["exports", "ember-data", "formaviva-web/config/environment", "ember-cp-validations"], function (_exports, _emberData, _environment, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Model = _emberData.default.Model;
  var belongsTo = _emberData.default.belongsTo;
  var hasMany = _emberData.default.hasMany;
  var attr = _emberData.default.attr;
  var Validations = (0, _emberCpValidations.buildValidations)({
    donationAmount: [(0, _emberCpValidations.validator)("presence", {
      presence: true
    }), (0, _emberCpValidations.validator)("number", {
      allowString: true,
      gte: 1,
      lte: 100000
    })]
  });

  var _default = Model.extend(Validations, {
    recordType: "merch",
    name: attr("string"),
    description: attr("string"),
    slug: attr("string"),
    price: attr("number"),
    currency: attr("string"),
    isOpen: attr("boolean"),
    isDonation: attr("boolean"),
    donationAmount: attr("number", {
      defaultValue: 10.0
    }),
    includeDigitalDownload: attr("boolean"),
    availableQuantity: attr("number"),
    sold: attr("number"),
    successMessage: attr("string"),
    imageUrl: attr("string"),
    imageUploadId: attr("string"),
    weight: attr("string"),
    temporary: attr("boolean", {
      defaultValue: true
    }),
    shippingOrigins: hasMany("shipping-origin"),
    release: belongsTo("release"),
    merchType: belongsTo("merch-type"),
    adjustedImageUrl: Ember.computed("imageUrl", function () {
      if (this.get("imageUrl") && this.get("imageUrl")[0] === "/") {
        return "".concat(_environment.default.serverURL, "/").concat(this.get("imageUrl"));
      } else {
        return this.get("imageUrl");
      }
    }),
    isPhysical: Ember.computed("merchType", function () {
      return true; // For now
    }),
    isAvailable: Ember.computed.gt("availableQuantity", 0)
  });

  _exports.default = _default;
});