define("formaviva-web/_pods/components/table/cell/simple-link/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: "",
    actions: {
      stopPropogation: function stopPropogation(e) {
        e.stopPropagation();
      }
    }
  });

  _exports.default = _default;
});