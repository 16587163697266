define("formaviva-web/utils/social-link", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.buildFbUrl = buildFbUrl;
  _exports.buildTwitterUrl = buildTwitterUrl;
  _exports.openSharePopup = openSharePopup;

  function buildFbUrl(url, text) {
    var quote = text ? "&quote=".concat(text) : "";
    var currentUrl = encodeURIComponent(url);
    return "https://facebook.com/sharer.php?display=popup&u=".concat(currentUrl).concat(quote);
  }

  function buildTwitterUrl(url, text) {
    var quote = text ? "text=".concat(text) : "";
    var currentUrl = encodeURIComponent(url);
    return "https://twitter.com/intent/tweet?".concat(quote, "&url=").concat(currentUrl);
  }

  function getPopupPosition() {
    var dualScreenLeft = screen.availLeft;
    var dualScreenTop = screen.availTop;
    var windowWidth = screen.availWidth;
    var windowheight = screen.availHeight;
    var left = windowWidth / 2 - 600 / 2 + dualScreenLeft;
    var top = windowheight / 2 - 600 / 2 + dualScreenTop;
    return {
      left: left,
      top: top
    };
  }

  function openSharePopup(url) {
    var popupPosition = getPopupPosition();
    var newWindow = window.open(url, "Facebook", "location=no,toolbar=no,menubar=no,scrollbars=no,status=no, width=600, height=600, top=" + popupPosition.top + ", left=" + popupPosition.left);

    if (typeof newWindow !== "undefined" && newWindow !== null && newWindow.focus) {
      newWindow.focus();
    }
  }
});