define("formaviva-web/mixins/release-actions", ["exports", "formaviva-web/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var isTest = _environment.default.environment === "test";

  var _default = Ember.Mixin.create({
    actions: {
      saveRelease: function saveRelease(release) {
        var _this = this;

        if (this.artistNamesDisplayStrategy === "extractFromTracks") {
          release.set("artistNames", null);
        }

        this.set("isSaving", true);
        release.save().then(function () {
          _this.get("notifications").success("Release saved successfully.", {
            autoClear: true
          });

          if (!isTest && window.history.length) {
            window.history.back();
          } else {
            _this.transitionToRoute("release.show", release.get("profile.slug"), release.get("slug"));
          }
        }).catch(function () {
          _this.get("notifications").error("There were some errors while saving the release. Please check if all tracks include at least one artist.", {
            autoClear: true
          });
        }).finally(function () {
          _this.set("isSaving", false);
        });
      },
      deleteRelease: function deleteRelease(release) {
        var _this2 = this;

        this.set("isDeleting", true);
        var promise = release.destroyRecord();
        promise.then(function () {
          _this2.get("notifications").success("Release deleted successfully.", {
            autoClear: true
          });

          _this2.transitionToRoute("dashboard");
        });
        promise.catch(function () {
          _this2.get("notifications").error("There were some errors while deleting the release.", {
            autoClear: true
          });
        });
        promise.finally(function () {
          return _this2.set("isDeleting", false);
        });
      }
    }
  });

  _exports.default = _default;
});