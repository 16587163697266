define("formaviva-web/utils/mobile", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.isIOS = _exports.isAndroid = void 0;

  var isAndroid = function isAndroid() {
    return navigator.userAgent.match(/Android/i);
  };

  _exports.isAndroid = isAndroid;

  var isIOS = function isIOS() {
    return navigator.userAgent.match(/iPhone|iPad|iPod/i);
  };

  _exports.isIOS = isIOS;
});