define("formaviva-web/constants/countries", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = [{
    id: "US",
    label: "United States",
    alpha3: "USA"
  }, {
    id: "AF",
    label: "Afghanistan",
    alpha3: "AFG"
  }, {
    id: "AL",
    label: "Albania",
    alpha3: "ALB"
  }, {
    id: "DZ",
    label: "Algeria",
    alpha3: "DZA"
  }, {
    id: "AS",
    label: "American Samoa",
    alpha3: "ASM"
  }, {
    id: "AD",
    label: "Andorra",
    alpha3: "AND"
  }, {
    id: "AO",
    label: "Angola",
    alpha3: "AGO"
  }, {
    id: "AI",
    label: "Anguilla",
    alpha3: "AIA"
  }, {
    id: "AQ",
    label: "Antarctica",
    alpha3: "ATA"
  }, {
    id: "AG",
    label: "Antigua and Barbuda",
    alpha3: "ATG"
  }, {
    id: "AR",
    label: "Argentina",
    alpha3: "ARG"
  }, {
    id: "AM",
    label: "Armenia",
    alpha3: "ARM"
  }, {
    id: "AW",
    label: "Aruba",
    alpha3: "ABW"
  }, {
    id: "AU",
    label: "Australia",
    alpha3: "AUS"
  }, {
    id: "AT",
    label: "Austria",
    alpha3: "AUT"
  }, {
    id: "AZ",
    label: "Azerbaijan",
    alpha3: "AZE"
  }, {
    id: "BS",
    label: "Bahamas",
    alpha3: "BHS"
  }, {
    id: "BH",
    label: "Bahrain",
    alpha3: "BHR"
  }, {
    id: "BD",
    label: "Bangladesh",
    alpha3: "BGD"
  }, {
    id: "BB",
    label: "Barbados",
    alpha3: "BRB"
  }, {
    id: "BY",
    label: "Belarus",
    alpha3: "BLR"
  }, {
    id: "BE",
    label: "Belgium",
    alpha3: "BEL"
  }, {
    id: "BZ",
    label: "Belize",
    alpha3: "BLZ"
  }, {
    id: "BJ",
    label: "Benin",
    alpha3: "BEN"
  }, {
    id: "BM",
    label: "Bermuda",
    alpha3: "BMU"
  }, {
    id: "BT",
    label: "Bhutan",
    alpha3: "BTN"
  }, {
    id: "BO",
    label: "Bolivia",
    alpha3: "BOL"
  }, {
    id: "BQ",
    label: "Bonaire, Sint Eustatius and Saba",
    alpha3: "BES"
  }, {
    id: "BA",
    label: "Bosnia and Herzegovina",
    alpha3: "BIH"
  }, {
    id: "BW",
    label: "Botswana",
    alpha3: "BWA"
  }, {
    id: "BV",
    label: "Bouvet Island",
    alpha3: "BVT"
  }, {
    id: "BR",
    label: "Brazil",
    alpha3: "BRA"
  }, {
    id: "IO",
    label: "British Indian Ocean Territory",
    alpha3: "IOT"
  }, {
    id: "BN",
    label: "Brunei Darussalam",
    alpha3: "BRN"
  }, {
    id: "BG",
    label: "Bulgaria",
    alpha3: "BGR"
  }, {
    id: "BF",
    label: "Burkina Faso",
    alpha3: "BFA"
  }, {
    id: "BI",
    label: "Burundi",
    alpha3: "BDI"
  }, {
    id: "KH",
    label: "Cambodia",
    alpha3: "KHM"
  }, {
    id: "CM",
    label: "Cameroon",
    alpha3: "CMR"
  }, {
    id: "CA",
    label: "Canada",
    alpha3: "CAN"
  }, {
    id: "CV",
    label: "Cape Verde",
    alpha3: "CPV"
  }, {
    id: "KY",
    label: "Cayman Islands",
    alpha3: "CYM"
  }, {
    id: "CF",
    label: "Central African Republic",
    alpha3: "CAF"
  }, {
    id: "TD",
    label: "Chad",
    alpha3: "TCD"
  }, {
    id: "CL",
    label: "Chile",
    alpha3: "CHL"
  }, {
    id: "CN",
    label: "China",
    alpha3: "CHN"
  }, {
    id: "CX",
    label: "Christmas Island",
    alpha3: "CXR"
  }, {
    id: "CC",
    label: "Cocos (Keeling) Islands",
    alpha3: "CCK"
  }, {
    id: "CO",
    label: "Colombia",
    alpha3: "COL"
  }, {
    id: "KM",
    label: "Comoros",
    alpha3: "COM"
  }, {
    id: "CG",
    label: "Congo",
    alpha3: "COG"
  }, {
    id: "CD",
    label: "Congo, The Democratic Republic Of The",
    alpha3: "COD"
  }, {
    id: "CK",
    label: "Cook Islands",
    alpha3: "COK"
  }, {
    id: "CR",
    label: "Costa Rica",
    alpha3: "CRI"
  }, {
    id: "HR",
    label: "Croatia",
    alpha3: "HRV"
  }, {
    id: "CU",
    label: "Cuba",
    alpha3: "CUB"
  }, {
    id: "CW",
    label: "Curaçao",
    alpha3: "CUW"
  }, {
    id: "CY",
    label: "Cyprus",
    alpha3: "CYP"
  }, {
    id: "CZ",
    label: "Czech Republic",
    alpha3: "CZE"
  }, {
    id: "CI",
    label: "Côte D'Ivoire",
    alpha3: "CIV"
  }, {
    id: "DK",
    label: "Denmark",
    alpha3: "DNK"
  }, {
    id: "DJ",
    label: "Djibouti",
    alpha3: "DJI"
  }, {
    id: "DM",
    label: "Dominica",
    alpha3: "DMA"
  }, {
    id: "DO",
    label: "Dominican Republic",
    alpha3: "DOM"
  }, {
    id: "EC",
    label: "Ecuador",
    alpha3: "ECU"
  }, {
    id: "EG",
    label: "Egypt",
    alpha3: "EGY"
  }, {
    id: "SV",
    label: "El Salvador",
    alpha3: "SLV"
  }, {
    id: "GQ",
    label: "Equatorial Guinea",
    alpha3: "GNQ"
  }, {
    id: "ER",
    label: "Eritrea",
    alpha3: "ERI"
  }, {
    id: "EE",
    label: "Estonia",
    alpha3: "EST"
  }, {
    id: "ET",
    label: "Ethiopia",
    alpha3: "ETH"
  }, {
    id: "FK",
    label: "Falkland Islands (Malvinas)",
    alpha3: "FLK"
  }, {
    id: "FO",
    label: "Faroe Islands",
    alpha3: "FRO"
  }, {
    id: "FJ",
    label: "Fiji",
    alpha3: "FJI"
  }, {
    id: "FI",
    label: "Finland",
    alpha3: "FIN"
  }, {
    id: "FR",
    label: "France",
    alpha3: "FRA"
  }, {
    id: "GF",
    label: "French Guiana",
    alpha3: "GUF"
  }, {
    id: "PF",
    label: "French Polynesia",
    alpha3: "PYF"
  }, {
    id: "TF",
    label: "French Southern Territories",
    alpha3: "ATF"
  }, {
    id: "GA",
    label: "Gabon",
    alpha3: "GAB"
  }, {
    id: "GM",
    label: "Gambia",
    alpha3: "GMB"
  }, {
    id: "GE",
    label: "Georgia",
    alpha3: "GEO"
  }, {
    id: "DE",
    label: "Germany",
    alpha3: "DEU"
  }, {
    id: "GH",
    label: "Ghana",
    alpha3: "GHA"
  }, {
    id: "GI",
    label: "Gibraltar",
    alpha3: "GIB"
  }, {
    id: "GR",
    label: "Greece",
    alpha3: "GRC"
  }, {
    id: "GL",
    label: "Greenland",
    alpha3: "GRL"
  }, {
    id: "GD",
    label: "Grenada",
    alpha3: "GRD"
  }, {
    id: "GP",
    label: "Guadeloupe",
    alpha3: "GLP"
  }, {
    id: "GU",
    label: "Guam",
    alpha3: "GUM"
  }, {
    id: "GT",
    label: "Guatemala",
    alpha3: "GTM"
  }, {
    id: "GG",
    label: "Guernsey",
    alpha3: "GGY"
  }, {
    id: "GN",
    label: "Guinea",
    alpha3: "GIN"
  }, {
    id: "GW",
    label: "Guinea-Bissau",
    alpha3: "GNB"
  }, {
    id: "GY",
    label: "Guyana",
    alpha3: "GUY"
  }, {
    id: "HT",
    label: "Haiti",
    alpha3: "HTI"
  }, {
    id: "HM",
    label: "Heard and McDonald Islands",
    alpha3: "HMD"
  }, {
    id: "VA",
    label: "Holy See (Vatican City State)",
    alpha3: "VAT"
  }, {
    id: "HN",
    label: "Honduras",
    alpha3: "HND"
  }, {
    id: "HK",
    label: "Hong Kong",
    alpha3: "HKG"
  }, {
    id: "HU",
    label: "Hungary",
    alpha3: "HUN"
  }, {
    id: "IS",
    label: "Iceland",
    alpha3: "ISL"
  }, {
    id: "IN",
    label: "India",
    alpha3: "IND"
  }, {
    id: "ID",
    label: "Indonesia",
    alpha3: "IDN"
  }, {
    id: "IR",
    label: "Iran, Islamic Republic Of",
    alpha3: "IRN"
  }, {
    id: "IQ",
    label: "Iraq",
    alpha3: "IRQ"
  }, {
    id: "IE",
    label: "Ireland",
    alpha3: "IRL"
  }, {
    id: "IM",
    label: "Isle of Man",
    alpha3: "IMN"
  }, {
    id: "IL",
    label: "Israel",
    alpha3: "ISR"
  }, {
    id: "IT",
    label: "Italy",
    alpha3: "ITA"
  }, {
    id: "JM",
    label: "Jamaica",
    alpha3: "JAM"
  }, {
    id: "JP",
    label: "Japan",
    alpha3: "JPN"
  }, {
    id: "JE",
    label: "Jersey",
    alpha3: "JEY"
  }, {
    id: "JO",
    label: "Jordan",
    alpha3: "JOR"
  }, {
    id: "KZ",
    label: "Kazakhstan",
    alpha3: "KAZ"
  }, {
    id: "KE",
    label: "Kenya",
    alpha3: "KEN"
  }, {
    id: "KI",
    label: "Kiribati",
    alpha3: "KIR"
  }, {
    id: "KP",
    label: "Korea, Democratic People's Republic Of",
    alpha3: "PRK"
  }, {
    id: "KR",
    label: "Korea, Republic of",
    alpha3: "KOR"
  }, {
    id: "KW",
    label: "Kuwait",
    alpha3: "KWT"
  }, {
    id: "KG",
    label: "Kyrgyzstan",
    alpha3: "KGZ"
  }, {
    id: "LA",
    label: "Lao People's Democratic Republic",
    alpha3: "LAO"
  }, {
    id: "LV",
    label: "Latvia",
    alpha3: "LVA"
  }, {
    id: "LB",
    label: "Lebanon",
    alpha3: "LBN"
  }, {
    id: "LS",
    label: "Lesotho",
    alpha3: "LSO"
  }, {
    id: "LR",
    label: "Liberia",
    alpha3: "LBR"
  }, {
    id: "LY",
    label: "Libya",
    alpha3: "LBY"
  }, {
    id: "LI",
    label: "Liechtenstein",
    alpha3: "LIE"
  }, {
    id: "LT",
    label: "Lithuania",
    alpha3: "LTU"
  }, {
    id: "LU",
    label: "Luxembourg",
    alpha3: "LUX"
  }, {
    id: "MO",
    label: "Macao",
    alpha3: "MAC"
  }, {
    id: "MK",
    label: "Macedonia, the Former Yugoslav Republic Of",
    alpha3: "MKD"
  }, {
    id: "MG",
    label: "Madagascar",
    alpha3: "MDG"
  }, {
    id: "MW",
    label: "Malawi",
    alpha3: "MWI"
  }, {
    id: "MY",
    label: "Malaysia",
    alpha3: "MYS"
  }, {
    id: "MV",
    label: "Maldives",
    alpha3: "MDV"
  }, {
    id: "ML",
    label: "Mali",
    alpha3: "MLI"
  }, {
    id: "MT",
    label: "Malta",
    alpha3: "MLT"
  }, {
    id: "MH",
    label: "Marshall Islands",
    alpha3: "MHL"
  }, {
    id: "MQ",
    label: "Martinique",
    alpha3: "MTQ"
  }, {
    id: "MR",
    label: "Mauritania",
    alpha3: "MRT"
  }, {
    id: "MU",
    label: "Mauritius",
    alpha3: "MUS"
  }, {
    id: "YT",
    label: "Mayotte",
    alpha3: "MYT"
  }, {
    id: "MX",
    label: "Mexico",
    alpha3: "MEX"
  }, {
    id: "FM",
    label: "Micronesia, Federated States Of",
    alpha3: "FSM"
  }, {
    id: "MD",
    label: "Moldova, Republic of",
    alpha3: "MDA"
  }, {
    id: "MC",
    label: "Monaco",
    alpha3: "MCO"
  }, {
    id: "MN",
    label: "Mongolia",
    alpha3: "MNG"
  }, {
    id: "ME",
    label: "Montenegro",
    alpha3: "MNE"
  }, {
    id: "MS",
    label: "Montserrat",
    alpha3: "MSR"
  }, {
    id: "MA",
    label: "Morocco",
    alpha3: "MAR"
  }, {
    id: "MZ",
    label: "Mozambique",
    alpha3: "MOZ"
  }, {
    id: "MM",
    label: "Myanmar",
    alpha3: "MMR"
  }, {
    id: "NA",
    label: "Namibia",
    alpha3: "NAM"
  }, {
    id: "NR",
    label: "Nauru",
    alpha3: "NRU"
  }, {
    id: "NP",
    label: "Nepal",
    alpha3: "NPL"
  }, {
    id: "NL",
    label: "Netherlands",
    alpha3: "NLD"
  }, {
    id: "NC",
    label: "New Caledonia",
    alpha3: "NCL"
  }, {
    id: "NZ",
    label: "New Zealand",
    alpha3: "NZL"
  }, {
    id: "NI",
    label: "Nicaragua",
    alpha3: "NIC"
  }, {
    id: "NE",
    label: "Niger",
    alpha3: "NER"
  }, {
    id: "NG",
    label: "Nigeria",
    alpha3: "NGA"
  }, {
    id: "NU",
    label: "Niue",
    alpha3: "NIU"
  }, {
    id: "NF",
    label: "Norfolk Island",
    alpha3: "NFK"
  }, {
    id: "MP",
    label: "Northern Mariana Islands",
    alpha3: "MNP"
  }, {
    id: "NO",
    label: "Norway",
    alpha3: "NOR"
  }, {
    id: "OM",
    label: "Oman",
    alpha3: "OMN"
  }, {
    id: "PK",
    label: "Pakistan",
    alpha3: "PAK"
  }, {
    id: "PW",
    label: "Palau",
    alpha3: "PLW"
  }, {
    id: "PS",
    label: "Palestine, State of",
    alpha3: "PSE"
  }, {
    id: "PA",
    label: "Panama",
    alpha3: "PAN"
  }, {
    id: "PG",
    label: "Papua New Guinea",
    alpha3: "PNG"
  }, {
    id: "PY",
    label: "Paraguay",
    alpha3: "PRY"
  }, {
    id: "PE",
    label: "Peru",
    alpha3: "PER"
  }, {
    id: "PH",
    label: "Philippines",
    alpha3: "PHL"
  }, {
    id: "PN",
    label: "Pitcairn",
    alpha3: "PCN"
  }, {
    id: "PL",
    label: "Poland",
    alpha3: "POL"
  }, {
    id: "PT",
    label: "Portugal",
    alpha3: "PRT"
  }, {
    id: "PR",
    label: "Puerto Rico",
    alpha3: "PRI"
  }, {
    id: "QA",
    label: "Qatar",
    alpha3: "QAT"
  }, {
    id: "RO",
    label: "Romania",
    alpha3: "ROU"
  }, {
    id: "RU",
    label: "Russian Federation",
    alpha3: "RUS"
  }, {
    id: "RW",
    label: "Rwanda",
    alpha3: "RWA"
  }, {
    id: "RE",
    label: "Réunion",
    alpha3: "REU"
  }, {
    id: "BL",
    label: "Saint Barthélemy",
    alpha3: "BLM"
  }, {
    id: "SH",
    label: "Saint Helena",
    alpha3: "SHN"
  }, {
    id: "KN",
    label: "Saint Kitts And Nevis",
    alpha3: "KNA"
  }, {
    id: "LC",
    label: "Saint Lucia",
    alpha3: "LCA"
  }, {
    id: "MF",
    label: "Saint Martin",
    alpha3: "MAF"
  }, {
    id: "PM",
    label: "Saint Pierre And Miquelon",
    alpha3: "SPM"
  }, {
    id: "VC",
    label: "Saint Vincent And The Grenedines",
    alpha3: "VCT"
  }, {
    id: "WS",
    label: "Samoa",
    alpha3: "WSM"
  }, {
    id: "SM",
    label: "San Marino",
    alpha3: "SMR"
  }, {
    id: "ST",
    label: "Sao Tome and Principe",
    alpha3: "STP"
  }, {
    id: "SA",
    label: "Saudi Arabia",
    alpha3: "SAU"
  }, {
    id: "SN",
    label: "Senegal",
    alpha3: "SEN"
  }, {
    id: "RS",
    label: "Serbia",
    alpha3: "SRB"
  }, {
    id: "SC",
    label: "Seychelles",
    alpha3: "SYC"
  }, {
    id: "SL",
    label: "Sierra Leone",
    alpha3: "SLE"
  }, {
    id: "SG",
    label: "Singapore",
    alpha3: "SGP"
  }, {
    id: "SX",
    label: "Sint Maarten",
    alpha3: "SXM"
  }, {
    id: "SK",
    label: "Slovakia",
    alpha3: "SVK"
  }, {
    id: "SI",
    label: "Slovenia",
    alpha3: "SVN"
  }, {
    id: "SB",
    label: "Solomon Islands",
    alpha3: "SLB"
  }, {
    id: "SO",
    label: "Somalia",
    alpha3: "SOM"
  }, {
    id: "ZA",
    label: "South Africa",
    alpha3: "ZAF"
  }, {
    id: "GS",
    label: "South Georgia and the South Sandwich Islands",
    alpha3: "SGS"
  }, {
    id: "SS",
    label: "South Sudan",
    alpha3: "SSD"
  }, {
    id: "ES",
    label: "Spain",
    alpha3: "ESP"
  }, {
    id: "LK",
    label: "Sri Lanka",
    alpha3: "LKA"
  }, {
    id: "SD",
    label: "Sudan",
    alpha3: "SDN"
  }, {
    id: "SR",
    label: "Suriname",
    alpha3: "SUR"
  }, {
    id: "SJ",
    label: "Svalbard And Jan Mayen",
    alpha3: "SJM"
  }, {
    id: "SZ",
    label: "Swaziland",
    alpha3: "SWZ"
  }, {
    id: "SE",
    label: "Sweden",
    alpha3: "SWE"
  }, {
    id: "CH",
    label: "Switzerland",
    alpha3: "CHE"
  }, {
    id: "SY",
    label: "Syrian Arab Republic",
    alpha3: "SYR"
  }, {
    id: "TW",
    label: "Taiwan, Republic Of China",
    alpha3: "TWN"
  }, {
    id: "TJ",
    label: "Tajikistan",
    alpha3: "TJK"
  }, {
    id: "TZ",
    label: "Tanzania, United Republic of",
    alpha3: "TZA"
  }, {
    id: "TH",
    label: "Thailand",
    alpha3: "THA"
  }, {
    id: "TL",
    label: "Timor-Leste",
    alpha3: "TLS"
  }, {
    id: "TG",
    label: "Togo",
    alpha3: "TGO"
  }, {
    id: "TK",
    label: "Tokelau",
    alpha3: "TKL"
  }, {
    id: "TO",
    label: "Tonga",
    alpha3: "TON"
  }, {
    id: "TT",
    label: "Trinidad and Tobago",
    alpha3: "TTO"
  }, {
    id: "TN",
    label: "Tunisia",
    alpha3: "TUN"
  }, {
    id: "TR",
    label: "Turkey",
    alpha3: "TUR"
  }, {
    id: "TM",
    label: "Turkmenistan",
    alpha3: "TKM"
  }, {
    id: "TC",
    label: "Turks and Caicos Islands",
    alpha3: "TCA"
  }, {
    id: "TV",
    label: "Tuvalu",
    alpha3: "TUV"
  }, {
    id: "UG",
    label: "Uganda",
    alpha3: "UGA"
  }, {
    id: "UA",
    label: "Ukraine",
    alpha3: "UKR"
  }, {
    id: "AE",
    label: "United Arab Emirates",
    alpha3: "ARE"
  }, {
    id: "GB",
    label: "United Kingdom",
    alpha3: "GBR"
  }, {
    id: "GB",
    label: "United Kingdom",
    alpha3: "GBR"
  }, {
    id: "UM",
    label: "United States Minor Outlying Islands",
    alpha3: "UMI"
  }, {
    id: "UY",
    label: "Uruguay",
    alpha3: "URY"
  }, {
    id: "UZ",
    label: "Uzbekistan",
    alpha3: "UZB"
  }, {
    id: "VU",
    label: "Vanuatu",
    alpha3: "VUT"
  }, {
    id: "VE",
    label: "Venezuela, Bolivarian Republic of",
    alpha3: "VEN"
  }, {
    id: "VN",
    label: "Vietnam",
    alpha3: "VNM"
  }, {
    id: "VG",
    label: "Virgin Islands, British",
    alpha3: "VGB"
  }, {
    id: "VI",
    label: "Virgin Islands, U.S.",
    alpha3: "VIR"
  }, {
    id: "WF",
    label: "Wallis and Futuna",
    alpha3: "WLF"
  }, {
    id: "EH",
    label: "Western Sahara",
    alpha3: "ESH"
  }, {
    id: "YE",
    label: "Yemen",
    alpha3: "YEM"
  }, {
    id: "ZM",
    label: "Zambia",
    alpha3: "ZMB"
  }, {
    id: "ZW",
    label: "Zimbabwe",
    alpha3: "ZWE"
  }, {
    id: "AX",
    label: "Åland Islands",
    alpha3: "ALA"
  }];
  _exports.default = _default;
});