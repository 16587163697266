define("formaviva-web/services/app-state", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    isImpersonatedSession: false,
    localStorageKey: "fv:is_impersonated_user",
    init: function init() {
      this._super.apply(this, arguments);

      if (window.localStorage) {
        var isImpersonatedSession = String(window.localStorage.getItem(this.localStorageKey)) == "true";
        this.set("isImpersonatedSession", isImpersonatedSession);
      }
    },
    setIsImpersonatedSession: function setIsImpersonatedSession(impersonated) {
      if (window.localStorage) {
        if (impersonated) {
          window.localStorage.setItem(this.localStorageKey, "true");
          this.set("isImpersonatedSession", true);
        } else {
          window.localStorage.removeItem(this.localStorageKey);
          this.set("isImpersonatedSession", false);
        }
      }
    }
  });

  _exports.default = _default;
});