define("formaviva-web/_pods/purchase/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "lOSvY0Lv",
    "block": "{\"symbols\":[],\"statements\":[[1,[29,\"sub-menu\",null,[[\"isBreadcrumb\",\"tabs\"],[true,[29,\"array\",[[29,\"hash\",null,[[\"name\",\"route\"],[\"Purchases\",\"purchases\"]]],[29,\"hash\",null,[[\"name\",\"route\"],[[25,[\"purchase\",\"description\"]],\"purchase\"]]]],null]]]],false],[0,\"\\n\\n\"],[4,\"if\",[[25,[\"justPurchased\"]]],null,{\"statements\":[[0,\"  \"],[7,\"div\"],[11,\"class\",\"row\"],[9],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"col-md-12\"],[9],[0,\"\\n      \"],[7,\"div\"],[11,\"class\",\"alert alert-success\"],[9],[0,\"\\n        \"],[7,\"strong\"],[9],[0,\"Purchase successful!\"],[10],[0,\"\\n        \"],[7,\"p\"],[9],[0,\"\\n          Here is the overview of your purchase.\\n        \"],[10],[0,\"\\n      \"],[10],[0,\"\\n    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[7,\"div\"],[11,\"class\",\"row\"],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"col-md-12\"],[9],[0,\"\\n    \"],[1,[29,\"purchase/purchase-list\",null,[[\"purchase\",\"items\"],[[25,[\"purchase\"]],[25,[\"purchase\",\"purchaseItems\"]]]]],false],[0,\"\\n  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "formaviva-web/_pods/purchase/template.hbs"
    }
  });

  _exports.default = _default;
});