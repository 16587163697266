define("formaviva-web/_pods/components/stats/stats-area/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "bjDZcz60",
    "block": "{\"symbols\":[\"@stats\"],\"statements\":[[7,\"div\"],[11,\"class\",\"stats\"],[9],[0,\"\\n  \"],[1,[29,\"stats/stats-box\",null,[[\"statsValue\",\"statsChange\",\"statsLabel\"],[[24,1,[\"plays\"]],[24,1,[\"playsChange\"]],\"Plays\"]]],false],[0,\"\\n\\n  \"],[1,[29,\"stats/stats-box\",null,[[\"statsValue\",\"statsChange\",\"statsLabel\"],[[24,1,[\"visits\"]],[24,1,[\"visitsChange\"]],\"Visits\"]]],false],[0,\"\\n\\n  \"],[1,[29,\"stats/stats-box\",null,[[\"statsValue\",\"statsChange\",\"statsLabel\"],[[24,1,[\"followers\"]],[24,1,[\"followersChange\"]],\"Followers\"]]],false],[0,\"\\n\\n  \"],[1,[29,\"stats/stats-box\",null,[[\"statsValue\",\"statsChange\",\"statsLabel\"],[[24,1,[\"downloads\"]],[24,1,[\"downloadsChange\"]],\"Downloads\"]]],false],[0,\"\\n\\n  \"],[1,[29,\"stats/stats-box\",null,[[\"statsValue\",\"statsChange\",\"statsLabel\"],[[24,1,[\"purchases\"]],[24,1,[\"purchasesChange\"]],\"Sales\"]]],false],[0,\"\\n\\n  \"],[1,[29,\"stats/stats-box\",null,[[\"statsValue\",\"statsChange\",\"isCurrency\",\"statsLabel\"],[[24,1,[\"revenue\"]],[29,\"round-currency\",[[24,1,[\"revenueChange\"]]],null],true,\"Revenue\"]]],false],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "formaviva-web/_pods/components/stats/stats-area/template.hbs"
    }
  });

  _exports.default = _default;
});