define("formaviva-web/_pods/components/player/footer-player-area/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    persistentPlayerState: Ember.inject.service()
  });

  _exports.default = _default;
});