define("formaviva-web/_pods/purchases/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    queryParams: ["page", "sort"],
    page: 1,
    sort: "-created_at",
    purchases: Ember.computed.alias("model"),
    actions: {
      goToPurchase: function goToPurchase(purchase) {
        this.transitionToRoute("purchase", purchase);
      },
      fetchLatest: function fetchLatest() {
        this.send("invalidateModel");
      }
    }
  });

  _exports.default = _default;
});