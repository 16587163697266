define("formaviva-web/_pods/components/claim/initiate-claim/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "sqlPasZe",
    "block": "{\"symbols\":[],\"statements\":[[7,\"h2\"],[11,\"class\",\"claim__header no-text-transform\"],[9],[0,\"Claim an artist profile\"],[10],[0,\"\\n\"],[7,\"div\"],[11,\"class\",\"claim__description\"],[9],[0,\"\\n  \"],[7,\"p\"],[11,\"class\",\"lead\"],[9],[0,\"If you already have your music on Formaviva, claim your profile to get access\\n    advanced analytics, enjoy better merchandising experience, and more.\"],[10],[0,\"\\n\"],[4,\"unless\",[[25,[\"currentSession\",\"isAuthenticated\"]]],null,{\"statements\":[[0,\"    \"],[7,\"p\"],[11,\"class\",\"lead\"],[9],[0,\"You need to be logged in to claim an account.\\n      Click here to \"],[4,\"link-to\",[\"login\"],null,{\"statements\":[[0,\"login\"]],\"parameters\":[]},null],[0,\" or \"],[4,\"link-to\",[\"signup\"],null,{\"statements\":[[0,\"signup\"]],\"parameters\":[]},null],[0,\".\"],[10],[0,\"\\n\"]],\"parameters\":[]},null],[10],[0,\"\\n\"],[7,\"div\"],[11,\"class\",\"claim__buttons\"],[9],[0,\"\\n  \"],[7,\"button\"],[12,\"onclick\",[29,\"action\",[[24,0,[]],[25,[\"moveToNextStep\"]],[25,[\"currentClaimStep\"]]],null]],[11,\"class\",\"btn btn-primary btn-primary-2\"],[9],[0,\" Continue \"],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "formaviva-web/_pods/components/claim/initiate-claim/template.hbs"
    }
  });

  _exports.default = _default;
});