define("formaviva-web/_pods/track/analytics/route", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin"], function (_exports, _authenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    pageTitle: "Track Analytics | Formaviva",
    model: function model() {
      var track = this.modelFor("track");
      return Ember.RSVP.hash({
        track: track,
        sessions: this.store.query("play-session", {
          track_id: track.get("id")
        })
      });
    }
  });

  _exports.default = _default;
});