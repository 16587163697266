define("formaviva-web/_pods/components/tag/tag-indicator/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "3rzYeGEN",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[12,\"class\",[30,[\"tags-selector__item \",[29,\"if\",[[29,\"eq\",[[25,[\"tag\",\"id\"]],[25,[\"selectedTag\",\"id\"]]],null],\"tags-selector__item-selected\"],null]]]],[12,\"onclick\",[23,\"onclick\"]],[9],[0,\"\\n\"],[4,\"if\",[[25,[\"tag\",\"slug\"]]],null,{\"statements\":[[4,\"link-to\",[\"releases.tagged\",[25,[\"tag\",\"slug\"]]],null,{\"statements\":[[0,\"      \"],[1,[25,[\"tag\",\"name\"]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},{\"statements\":[[4,\"link-to\",[\"releases.index\"],null,{\"statements\":[[0,\"      \"],[1,[25,[\"tag\",\"name\"]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]}],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "formaviva-web/_pods/components/tag/tag-indicator/template.hbs"
    }
  });

  _exports.default = _default;
});