define("formaviva-web/_pods/purchase/controller", ["exports", "formaviva-web/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    authenticatedAjax: Ember.inject.service(),
    purchase: Ember.computed.alias("model"),
    queryParams: ["justPurchased"],
    justPurchased: false,
    session: Ember.inject.service(),
    actions: {
      downloadPurchase: function downloadPurchase(purchase) {
        if (!purchase.get("downloadReady")) {
          return;
        }

        var accessToken = this.get("session.data.authenticated.access_token");
        var downloadLink = "".concat(_environment.default.apiBaseURL, "/purchases/").concat(purchase.id, "/download?access_token=").concat(accessToken);
        window.location = downloadLink;
      }
    }
  });

  _exports.default = _default;
});