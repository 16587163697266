define("formaviva-web/_pods/components/profile/profile-header-image/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Z9Gaw9e4",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"profile__cover\"],[9],[0,\"\\n  \"],[1,[29,\"fv-profile-image\",null,[[\"image\",\"defaultName\",\"backgroundColor\",\"alt\",\"title\",\"textColor\",\"class\"],[[25,[\"profile\",\"adjustedImageUrl\"]],[25,[\"profile\",\"name\"]],\"#141414\",\"\",\"\",\"white\",\"profile__cover-image\"]]],false],[0,\"\\n\"],[10],[0,\"\\n\"],[4,\"if\",[[29,\"and\",[[25,[\"profile\",\"bio\"]],[29,\"not\",[[29,\"user-agent\",[\"device.isMobile\"],null]],null]],null]],null,{\"statements\":[[0,\"  \"],[7,\"div\"],[11,\"class\",\"profile__info\"],[9],[0,\"\\n\"],[4,\"layout/overlay-shortener\",null,[[\"maxSize\"],[400]],{\"statements\":[[0,\"      \"],[1,[29,\"new-lines-to-paragraphs\",[[25,[\"profile\",\"bio\"]]],null],true],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"  \"],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "formaviva-web/_pods/components/profile/profile-header-image/template.hbs"
    }
  });

  _exports.default = _default;
});