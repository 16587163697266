define("formaviva-web/_pods/components/dashboard/dashboard-track-list/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "6IO3VWZO",
    "block": "{\"symbols\":[],\"statements\":[[1,[29,\"table/base-layout\",null,[[\"dir\",\"model\",\"sort\",\"class\",\"rowHeight\",\"columns\",\"onRowClick\",\"sortChange\",\"emptyTableText\"],[[25,[\"dir\"]],[25,[\"tracks\"]],[29,\"camelize\",[[25,[\"sort\"]]],null],\"track-list-table dashboard-track-list\",50,[25,[\"columns\"]],[29,\"action\",[[24,0,[]],\"onRowClick\"],null],[25,[\"sortChange\"]],\"No tracks found\"]]],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "formaviva-web/_pods/components/dashboard/dashboard-track-list/template.hbs"
    }
  });

  _exports.default = _default;
});