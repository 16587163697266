define("formaviva-web/helpers/parse-integer", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.parseInteger = parseInteger;
  _exports.default = void 0;

  function parseInteger(str) {
    return Number.parseInt(str);
  }

  var _default = Ember.Helper.helper(parseInteger);

  _exports.default = _default;
});