define("formaviva-web/_pods/components/fv-radio-button/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "EkxG8eNQ",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[7,\"div\"],[11,\"class\",\"fv-radio-button__checkmark\"],[9],[10],[0,\"\\n\"],[7,\"div\"],[11,\"class\",\"fv-radio-button__content\"],[9],[15,1],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "formaviva-web/_pods/components/fv-radio-button/template.hbs"
    }
  });

  _exports.default = _default;
});