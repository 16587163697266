define("formaviva-web/_pods/profile/about/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    headData: Ember.inject.service(),
    hasCustomAsyncTitle: true,
    afterModel: function afterModel(model) {
      var profile = model.profile;
      var title = "".concat(profile.name, " About | Formaviva");
      this.set("headData.title", title);
      this.set("headData.description", profile.bio);
      this.set("headData.imageUrl", profile.adjustedImageUrl);
    },
    renderTemplate: function renderTemplate() {
      this.render({
        into: "profile",
        outlet: "profile-content"
      });
    },
    setupController: function setupController(controller, model) {
      var _this = this;

      this._super(controller, model);

      if (this.isFastBoot) return;

      if (model.merches) {
        model.merches.then(function (merches) {
          // 'isOpen' flag can be used during later stages
          // for our users to toggle the receipt of donation
          var donation = merches.filterBy("isDonation", true).filterBy("isOpen", true).firstObject;
          if (donation) controller.set("donation", _this.store.peekRecord("merch", donation.id));
        });
      }
    }
  });

  _exports.default = _default;
});