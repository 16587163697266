define("formaviva-web/_pods/components/release/release-track-selector-item/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "FAywROoH",
    "block": "{\"symbols\":[],\"statements\":[[7,\"td\"],[11,\"class\",\"track-order-column\"],[9],[0,\"\\n  \"],[1,[29,\"add\",[[25,[\"index\"]],1],null],false],[0,\".\\n\"],[10],[0,\"\\n\"],[7,\"td\"],[11,\"class\",\"track-image-column\"],[9],[0,\"\\n\"],[4,\"if\",[[25,[\"track\",\"adjustedImageUrl\"]]],null,{\"statements\":[[0,\"    \"],[7,\"img\"],[12,\"src\",[30,[[25,[\"track\",\"adjustedImageUrl\"]]]]],[11,\"class\",\"table-thumbnail\"],[11,\"alt\",\"\"],[11,\"role\",\"none\"],[9],[10],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[1,[29,\"fa-icon\",[\"music\"],[[\"class\"],[\"table-thumbnail\"]]],false],[0,\"\\n\"]],\"parameters\":[]}],[10],[0,\"\\n\"],[7,\"td\"],[11,\"class\",\"track-title-column\"],[9],[0,\"\\n  \"],[1,[25,[\"track\",\"displayName\"]],false],[0,\"\\n\"],[10],[0,\"\\n\"],[7,\"td\"],[11,\"class\",\"track-length-column\"],[9],[0,\"\\n  \"],[1,[29,\"format-duration\",[[25,[\"track\",\"length\"]]],null],false],[0,\"\\n\"],[10],[0,\"\\n\"],[7,\"td\"],[11,\"class\",\"track-uploaded-column\"],[9],[0,\"\\n  \"],[1,[29,\"moment-from-now\",[[25,[\"track\",\"createdAt\"]]],null],false],[0,\"\\n\"],[10],[0,\"\\n\"],[7,\"td\"],[11,\"class\",\"track-actions-column\"],[9],[0,\"\\n\"],[4,\"if\",[[25,[\"isSelected\"]]],null,{\"statements\":[[0,\"    \"],[7,\"button\"],[11,\"class\",\"btn btn-variant-1 btn-table-action pull-right\"],[11,\"type\",\"button\"],[9],[0,\"\\n      \"],[1,[29,\"fa-icon\",[\"minus\"],null],false],[0,\"\\n    \"],[3,\"action\",[[24,0,[]],[25,[\"toggleTrack\"]],[25,[\"track\"]]]],[10],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[7,\"button\"],[11,\"class\",\"btn btn-variant-1 btn-table-action pull-right\"],[11,\"type\",\"button\"],[9],[0,\"\\n      \"],[1,[29,\"fa-icon\",[\"plus\"],null],false],[0,\"\\n    \"],[3,\"action\",[[24,0,[]],[25,[\"toggleTrack\"]],[25,[\"track\"]]]],[10],[0,\"\\n\"]],\"parameters\":[]}],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "formaviva-web/_pods/components/release/release-track-selector-item/template.hbs"
    }
  });

  _exports.default = _default;
});