define("formaviva-web/_pods/dashboard/stats/controller", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    currentSession: Ember.inject.service(),
    profile: Ember.computed.readOnly("currentSession.profile"),
    dateFrom: (0, _moment.default)().subtract(7, "d"),
    dateTo: (0, _moment.default)(),
    actions: {
      setDateRange: function setDateRange(dateFrom, dateTo) {
        this.setProperties({
          dateFrom: dateFrom,
          dateTo: dateTo
        });
        this.send("fetchStats", dateFrom, dateTo);
      }
    }
  });

  _exports.default = _default;
});