define("formaviva-web/_pods/error/route", ["exports", "ember-data", "ember-ajax/errors"], function (_exports, _emberData, _errors) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var NotFoundError = _emberData.default.NotFoundError;

  var _default = Ember.Route.extend({
    fastboot: Ember.inject.service(),
    isFastBoot: Ember.computed.reads("fastboot.isFastBoot"),
    setupController: function setupController(controller, error) {
      if (!error) return;
      if (window.Rollbar) Rollbar.error(error); // check payload to handle ajax errors

      var status = Ember.isPresent(error.errors) ? error.errors[0].status : error.payload && error.payload.status; // Currently both 404 (not found) and 403 (unauthorized)
      // render the "error" template because it includes explanation for both

      if (status === "404" || status === "403" || (0, _errors.isNotFoundError)(error) || error instanceof NotFoundError) {
        if (this.isFastBoot) {
          this.set("fastboot.response.statusCode", status ? parseInt(status, 10) : 404);
        }

        controller.set("pageNotFound", true);
      }
    }
  });

  _exports.default = _default;
});