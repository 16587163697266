define("formaviva-web/models/shipping-by-quantity-price", ["exports", "ember-data", "formaviva-web/models/shipping-prices-container", "ember-cp-validations"], function (_exports, _emberData, _shippingPricesContainer, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var attr = _emberData.default.attr;
  var Validations = (0, _emberCpValidations.buildValidations)({
    pricePerUnit: (0, _emberCpValidations.validator)("number", {
      allowString: true,
      gte: 0
    }),
    pricePerAdditionalUnit: (0, _emberCpValidations.validator)("number", {
      allowString: true,
      gte: 0
    })
  });

  var _default = _shippingPricesContainer.default.extend(Validations, {
    pricePerUnit: attr("number"),
    pricePerAdditionalUnit: attr("number")
  });

  _exports.default = _default;
});