define("formaviva-web/_pods/components/release/purchase-table/component", ["exports", "ember-awesome-macros/array", "ember-awesome-macros"], function (_exports, _array, _emberAwesomeMacros) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * @typedef {Object} PurchaseItem
   * @property {'merch'|'release'} type
   * @property {string} name
   * @property {string} price
   * @property {object} item merch or release
   * @property {string} [description]
   * @property {string} [adjustedImageUrl]
   */
  var _default = Ember.Component.extend({
    shoppingCart: Ember.inject.service("checkout/shoppingCart"),

    /**
     * Returns array of purchase items by merches +
     * purchase items by release if release allow purchase
     * @property {PurchaseItem[]}
     */
    purchaseItems: (0, _array.concat)((0, _array.map)("release.merches", function (merch) {
      return {
        type: "merch",
        name: merch.name,
        description: merch.description,
        price: merch.price,
        item: merch,
        availableQuantity: Ember.get(merch, "availableQuantity"),
        isAvailable: Ember.get(merch, "isAvailable"),
        includeDigitalDownload: Ember.get(merch, "includeDigitalDownload"),
        adjustedImageUrl: Ember.get(merch, "adjustedImageUrl")
      };
    }), (0, _emberAwesomeMacros.conditional)("release.allowPurchase", (0, _emberAwesomeMacros.collect)((0, _emberAwesomeMacros.hash)({
      type: (0, _emberAwesomeMacros.raw)("release"),
      name: (0, _emberAwesomeMacros.raw)("Digital album"),
      price: "release.price",
      item: "release",
      isAvailable: true,
      isPhysical: false,
      adjustedImageUrl: "release.adjustedImageUrl"
    })), [])),
    actions: {
      /**
       * Adds item to shopping cart
       * @param {PurchaseItem} purchaseItem
       */
      addToCart: function addToCart(purchaseItem) {
        switch (purchaseItem.type) {
          case "merch":
            this.shoppingCart.addMerchToCart(purchaseItem.item);
            break;

          case "release":
            this.shoppingCart.addReleaseToCart(purchaseItem.item);
            break;
        }
      }
    }
  });

  _exports.default = _default;
});