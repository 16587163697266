define("formaviva-web/_pods/components/shipping/rest-of-the-world-prices/component", ["exports", "ember-awesome-macros"], function (_exports, _emberAwesomeMacros) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ["row", "shipping-zone"],
    isPriceObjectPresent: (0, _emberAwesomeMacros.notEmpty)("priceObject"),
    restCountryCount: 0,
    actions: {
      changeCheckbox: function changeCheckbox() {
        if (this.get("isPriceObjectPresent")) {
          this.disableRestOfTheWorldPrices();
        } else {
          this.enableRestOfTheWorldPrices();
        }
      },
      edit: function edit() {
        this.onEdit(this.priceObject);
      }
    }
  });

  _exports.default = _default;
});