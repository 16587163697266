define("formaviva-web/_pods/upload/mix/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "rICroq5l",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"row upload-section form-dark\"],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"col-md-8 col-md-offset-2\"],[9],[0,\"\\n    \"],[1,[29,\"track/track-form-with-upload\",null,[[\"track\",\"isSaved\",\"disableSave\",\"errorMessage\",\"saveTrack\"],[[25,[\"model\"]],[25,[\"isSaved\"]],[25,[\"disableSave\"]],[25,[\"errorMessage\"]],[29,\"action\",[[24,0,[]],\"saveTrack\"],null]]]],false],[0,\"\\n  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\\n\"],[7,\"div\"],[11,\"class\",\"row with-space-for-player\"],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"col-md-8 col-md-offset-2\"],[9],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"fv-separator-main\"],[9],[10],[0,\"\\n    \"],[7,\"button\"],[12,\"disabled\",[23,\"disableSave\"]],[11,\"class\",\"btn btn-primary btn-primary-2 mt-12 pull-right\"],[11,\"type\",\"button\"],[9],[0,\"\\n      \"],[1,[29,\"if\",[[25,[\"isSaving\"]],\"Publishing...\",\"Publish Mix\"],null],false],[0,\"\\n    \"],[3,\"action\",[[24,0,[]],\"saveTrack\",[25,[\"model\"]]]],[10],[0,\"\\n  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "formaviva-web/_pods/upload/mix/template.hbs"
    }
  });

  _exports.default = _default;
});