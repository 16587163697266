define("formaviva-web/_pods/components/table/base-layout/component", ["exports", "ember-light-table"], function (_exports, _emberLightTable) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var HEADER_HEIGHT = 42;
  var EXPANDED_ROW_HEIGHT = 202; // wrapper component around ember-ligh-table
  // adds sort functionality sugar

  var _default = Ember.Component.extend({
    tagName: "",
    dir: "asc",
    sort: "",
    lastCheckboxIndex: -1,
    canToggleHidden: false,
    rowHeights: Ember.computed("rowHeight", "table.rows.@each.expanded", function () {
      var rowHeight = this.rowHeight,
          table = this.table;

      if (rowHeight && table) {
        var height = table.rows.reduce(function (sum, row) {
          return sum + rowHeight + (row.expanded ? EXPANDED_ROW_HEIGHT : 0);
        }, HEADER_HEIGHT);
        return height + "px";
      }

      return undefined;
    }),
    mergedColumns: Ember.computed("canToggleHidden", "columns", function () {
      var mergedColumns = [];

      if (this.canToggleHidden) {
        mergedColumns.push({
          width: "30px",
          sortable: false,
          cellComponent: "table/cell/row-toggle",
          breakpoints: ["mobile", "tablet"]
        });
      }

      return mergedColumns.concat(this.columns).map(function (props) {
        props.component = props.component || "table/columns/custom";
        return props;
      });
    }),
    sortedModel: Ember.computed.sort("model", "sortBy"),
    sortBy: Ember.computed("dir", "sort", "defaultSort", function () {
      var defaultSort = this.defaultSort || [];
      return ["".concat(this.sort, ":").concat(this.dir)].concat(defaultSort);
    }),
    table: Ember.computed("dir", "sortedModel", function () {
      var mergedColumns = this.mergedColumns,
          sortedModel = this.sortedModel;

      var table = _emberLightTable.default.create({
        columns: mergedColumns
      });

      table.rows.setObjects(sortedModel.map(function (r, index) {
        var row = _emberLightTable.default.createRow(r);

        row.index = index + 1;
        return row;
      }));
      var sort = this.sort;

      if (sort) {
        var sortColumn = table.allColumns.findBy("valuePath", sort);

        if (sortColumn) {
          sortColumn.set("sorted", true);
          sortColumn.set("ascending", this.dir === "asc");
        }
      }

      return table;
    }),
    sortedModelChange: Ember.observer("sortedModel.[]", "table", function () {
      this.table.rows.setObjects(this.sortedModel.map(function (r, index) {
        var row = _emberLightTable.default.createRow(r);

        row.index = index + 1;
        return row;
      }));
    }),
    actions: {
      onColumnClick: function onColumnClick(column) {
        if (!column.sortable) {
          return;
        }

        var ascending = column.ascending,
            valuePath = column.valuePath;

        if (this.sortChange) {
          var dir = ascending ? "asc" : "desc";
          this.sortChange(dir, valuePath, column);
        }
      }
    }
  });

  _exports.default = _default;
});