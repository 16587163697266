define("formaviva-web/_pods/claim/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    currentSession: Ember.inject.service(),
    appWideModals: Ember.inject.service(),
    claimSteps: null,
    currentClaimStep: "initiateClaim",
    init: function init() {
      this._super.apply(this, arguments);

      this.canAllowClaim();
      this.set("claimSteps", ["initiateClaim", "selectProfile", "confirmSelection", "claimSuccess"]);
    },
    canAllowClaim: function canAllowClaim() {
      if (this.currentSession.isAuthenticated) {
        var user = this.currentSession.get("user");
        var profile = this.currentSession.get("profile");

        if (!profile.get("isListener") && profile.get("approved")) {
          this.set("preventRequestMessage", "Your account is already linked to an approved profile. Please create a new account to claim an artist.");
          return false;
        } else if (user.get("pendingClaimsCount") > 0) {
          this.set("preventRequestMessage", "You already have a pending claim.  Please wait until you hear back from us.");
          return false;
        }
      }

      return true;
    },
    moveToNextStep: function moveToNextStep(currentClaimStep, profile) {
      if (!this.currentSession.isAuthenticated) {
        // On login/signup, pending action will be triggered
        var pendingAction = [this.moveToNextStep.bind(this), currentClaimStep];
        this.get("appWideModals").toggleLoginModal(true, pendingAction);
      } else {
        if (currentClaimStep === "selectProfile") this.set("claimedProfile", profile);
        var currentStepIndex = this.claimSteps.indexOf(currentClaimStep);
        var nextStep = this.claimSteps[currentStepIndex + 1];
        this.set("currentClaimStep", nextStep);
      }
    },
    moveToPrevStep: function moveToPrevStep(currentClaimStep) {
      var currentStepIndex = this.claimSteps.indexOf(currentClaimStep);
      var prevStep = this.claimSteps[currentStepIndex - 1];
      this.set("currentClaimStep", prevStep);
    }
  });

  _exports.default = _default;
});