define("formaviva-web/_pods/dashboard/tracks/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    queryParams: {
      page: {
        refreshModel: true
      },
      sort: {
        refreshModel: true
      },
      dir: {
        refreshModel: true
      }
    },
    model: function model(_ref) {
      var _ref$page = _ref.page,
          page = _ref$page === void 0 ? 1 : _ref$page,
          _ref$sort = _ref.sort,
          sort = _ref$sort === void 0 ? "-created_at" : _ref$sort,
          _ref$dir = _ref.dir,
          dir = _ref$dir === void 0 ? "desc" : _ref$dir;
      return this.store.query("track", {
        page: page,
        sort: "".concat(dir === "desc" ? "-" : "").concat(sort),
        per_page: 20,
        filter: {
          mix: false
        }
      });
    }
  });

  _exports.default = _default;
});