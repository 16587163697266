define("formaviva-web/_pods/components/checkout/go-to-payment-button/component", ["exports", "ember-awesome-macros"], function (_exports, _emberAwesomeMacros) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: "",
    shoppingCart: Ember.inject.service("checkout/shoppingCart"),
    disabled: (0, _emberAwesomeMacros.or)(Ember.computed.notEmpty("shoppingCart.merchWithShippingErrorIds"), (0, _emberAwesomeMacros.not)("isValid"))
  });

  _exports.default = _default;
});