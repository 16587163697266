define("formaviva-web/_pods/upload/mix/controller", ["exports", "formaviva-web/mixins/track-actions"], function (_exports, _trackActions) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_trackActions.default, {
    disableSave: false,
    errorMessage: null
  });

  _exports.default = _default;
});