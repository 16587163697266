define("formaviva-web/_pods/dashboard/releases/controller", ["exports", "formaviva-web/mixins/can-edit"], function (_exports, _canEdit) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_canEdit.default, {
    currentSession: Ember.inject.service(),
    profile: Ember.computed.readOnly("currentSession.profile"),
    page: 1
  });

  _exports.default = _default;
});