define("formaviva-web/initializers/media-element", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.initialize = initialize;
  _exports.default = void 0;

  function initialize()
  /* application */
  {
    // Define a `playing` property on a MediaElement
    // for easy check whether or not the element is playing.
    if (!window.HTMLMediaElement) {
      return;
    }

    Object.defineProperty(HTMLMediaElement.prototype, "playing", {
      get: function get() {
        // readyState > 2 means data is available to continue playback.
        var hasData = this.readyState > 2;
        return !!(this.currentTime > 0 && !this.paused && !this.ended && hasData);
      }
    }); // For representational purpose of buffering (show loading indicator)

    Object.defineProperty(HTMLMediaElement.prototype, "buffering", {
      get: function get() {
        // The browser is downloading HTMLMediaElement data.
        var isDownloading = this.networkState == 2;
        return isDownloading && !this.playing;
      }
    });
  }

  var _default = {
    name: "mediaelement",
    initialize: initialize
  };
  _exports.default = _default;
});