define("formaviva-web/_pods/edit/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "MVUXO7IP",
    "block": "{\"symbols\":[],\"statements\":[[7,\"h3\"],[9],[0,\"Edit\"],[10],[0,\"\\n\"],[1,[29,\"sub-menu\",null,[[\"class\",\"tabs\"],[\"small-margin\",[25,[\"tabsArray\"]]]]],false],[0,\"\\n\\n\"],[1,[23,\"outlet\"],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "formaviva-web/_pods/edit/template.hbs"
    }
  });

  _exports.default = _default;
});