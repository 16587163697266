define("formaviva-web/models/static-page", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Model = _emberData.default.Model;
  var attr = _emberData.default.attr;

  var _default = Model.extend({
    title: attr("string"),
    description: attr("string"),
    slug: attr("string"),
    imageUrl: attr("string"),
    content: attr("string"),
    updatedAt: attr("date")
  });

  _exports.default = _default;
});