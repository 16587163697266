define("formaviva-web/_pods/upload/mix/route", ["exports", "formaviva-web/mixins/unsaved-record-notification", "formaviva-web/models/track"], function (_exports, _unsavedRecordNotification, _track) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_unsavedRecordNotification.default, {
    pageTitle: "Upload Mix | Formaviva",
    resetController: function resetController(controller) {
      this._super.apply(this, arguments);

      controller.set("disableSave", false);
    },
    model: function model() {
      var track = (0, _track.build)(this.store);
      track.set("mix", true);
      track.set("price", 2);
      return track;
    }
  });

  _exports.default = _default;
});