define("formaviva-web/_pods/dashboard/route", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin"], function (_exports, _authenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    pageTitle: "Dashboard | Formaviva",
    currentSession: Ember.inject.service(),
    userBehaviourTracker: Ember.inject.service("behaviour-tracking/tracker"),
    beforeModel: function beforeModel() {
      this._super.apply(this, arguments);

      if (this.get("currentSession.profile.isListener")) {
        return this.transitionTo("feed");
      }
    },
    actions: {
      didTransition: function didTransition() {
        this._super.apply(this, arguments);

        this.userBehaviourTracker.track({
          event: "dashboard_page"
        });
        return true;
      }
    }
  });

  _exports.default = _default;
});