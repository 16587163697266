define("formaviva-web/_pods/components/tag/tag-filter/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "L5o6LxQX",
    "block": "{\"symbols\":[\"tag\"],\"statements\":[[7,\"ul\"],[11,\"class\",\"tags-selector\"],[9],[0,\"\\n  \"],[1,[29,\"tag/tag-indicator\",null,[[\"tag\",\"selectedTag\",\"onclick\"],[[25,[\"allTag\"]],[25,[\"selectedTag\"]],[29,\"action\",[[24,0,[]],[25,[\"setSelectedTag\"]],[25,[\"tag\"]]],null]]]],false],[0,\"\\n\"],[4,\"each\",[[25,[\"tags\"]]],null,{\"statements\":[[4,\"if\",[[24,1,[\"releaseCount\"]]],null,{\"statements\":[[0,\"      \"],[7,\"li\"],[9],[0,\"\\n      \"],[1,[29,\"tag/tag-indicator\",null,[[\"tag\",\"selectedTag\",\"onclick\"],[[24,1,[]],[25,[\"selectedTag\"]],[29,\"action\",[[24,0,[]],[25,[\"setSelectedTag\"]],[24,1,[]]],null]]]],false],[0,\"\\n      \"],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[1]},null],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "formaviva-web/_pods/components/tag/tag-filter/template.hbs"
    }
  });

  _exports.default = _default;
});