define("formaviva-web/_pods/merch/show/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    // status stuff
    queryParams: ["status"],
    status: null,
    hasStatus: Ember.computed("status", function () {
      return !Ember.isEmpty(this.get("status"));
    }),
    isSuccessful: Ember.computed("status", function () {
      return this.get("status") == "success" ? true : false;
    }),
    isUnsuccessful: Ember.computed("status", function () {
      return this.get("status") == "failure" ? true : false;
    }),
    isCancelled: Ember.computed("status", function () {
      return this.get("status") == "cancel" ? true : false;
    }),
    isDonationType: Ember.computed("model.merchType", function () {
      return this.get("model.merchType") == 1 ? true : false;
    }),
    hasSuccessMessage: Ember.computed("model.successMessage", function () {
      return !Ember.isEmpty(this.get("model.successMessage"));
    }),
    // other
    totalPrice: Ember.computed("model.price", "order.quantity", function () {
      return this.get("order.quantity") * this.get("model.price");
    }),
    disableForm: Ember.computed.reads("formSubmitted"),
    actions: {
      submit: function submit(order) {
        var _this = this;

        order.validate().then(function (_ref) {
          var validations = _ref.validations;

          if (validations.get("isValid")) {
            order.set("totalPrice", order.get("quantity") * _this.get("model.price"));

            _this.set("formSubmitted", true);

            _this.send("submitOrder", order); // save in the route

          } else {
            _this.setProperties({
              showQuantityError: true,
              showNameError: true,
              showEmailError: true
            });
          }
        });
      }
    }
  });

  _exports.default = _default;
});