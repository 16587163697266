define("formaviva-web/initializers/window-resize", ["exports", "jquery", "formaviva-web/utils/scrollable-table"], function (_exports, _jquery, _scrollableTable) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.initialize = initialize;
  _exports.default = void 0;

  // For scrollable tables, a recalculation of widths
  // is needed when resize happens
  function initialize() {
    if (window.$ == null) {
      // Fastboot
      return;
    }

    (0, _jquery.default)(window).resize(function () {
      var $tablesToResize = (0, _jquery.default)(".fv-table-scrollable");
      $tablesToResize.each(function (i, table) {
        (0, _scrollableTable.default)((0, _jquery.default)(table));
      });
    });
  }

  var _default = {
    initialize: initialize
  };
  _exports.default = _default;
});