define("formaviva-web/_pods/components/shipping/by-weight-prices-container/component", ["exports", "ember-awesome-macros/array", "ember-awesome-macros", "ember-awesome-macros/raw"], function (_exports, _array, _emberAwesomeMacros, _raw) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ["shipping-by-weight-prices-container"],
    canRemovePrice: (0, _emberAwesomeMacros.gt)((0, _array.length)("prices"), (0, _raw.default)(1)),
    actions: {
      addPrice: function addPrice() {
        this.prices.createFragment({});
      },
      removePrice: function removePrice(price) {
        this.prices.removeObject(price);
      }
    }
  });

  _exports.default = _default;
});