define("formaviva-web/_pods/components/table/cell/icon-text/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "4RqpqyLA",
    "block": "{\"symbols\":[\"item\",\"@rawValue\",\"@row\",\"@column\"],\"statements\":[[4,\"let\",[[29,\"call-fn\",[[24,4,[\"getIcon\"]],[24,3,[\"content\"]]],null]],null,{\"statements\":[[0,\"  \"],[4,\"truncate-with-tooltip\",null,null,{\"statements\":[[1,[24,2,[]],false]],\"parameters\":[]},null],[0,\"\\n\\n\"],[4,\"if\",[[24,1,[]]],null,{\"statements\":[[0,\"    \"],[7,\"div\"],[9],[1,[29,\"fa-icon\",[[24,1,[\"icon\"]]],[[\"class\"],[[29,\"concat\",[[24,1,[\"color\"]],\" cell-icon\"],null]]]],false],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "formaviva-web/_pods/components/table/cell/icon-text/template.hbs"
    }
  });

  _exports.default = _default;
});