define("formaviva-web/_pods/components/track/track-buy/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ["inline-block"],
    shoppingCart: Ember.inject.service("checkout/shoppingCart"),
    actions: {
      addToShoppingCart: function addToShoppingCart(event) {
        var track = this.track,
            release = this.release;
        this.get("shoppingCart").addItem({
          track: track,
          release: release
        });
        event.stopPropagation();
      }
    }
  });

  _exports.default = _default;
});