define("formaviva-web/_pods/components/release/release-main-track/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "6uaICj5H",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[25,[\"currentTrack\"]]],null,{\"statements\":[[0,\"  \"],[1,[29,\"track/track-main\",null,[[\"track\",\"canEdit\",\"release\"],[[25,[\"currentTrack\"]],[25,[\"canEdit\"]],[25,[\"release\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[7,\"div\"],[11,\"class\",\"pt-8\"],[9],[0,\"\\n\"],[4,\"content-loader\",null,[[\"primaryColor\",\"secondaryColor\",\"height\"],[\"#3a3a3a\",\"#4d4d4d\",50]],{\"statements\":[[0,\"      \"],[7,\"rect\"],[11,\"x\",\"35\"],[11,\"y\",\"12\"],[11,\"rx\",\"5\"],[11,\"ry\",\"5\"],[11,\"width\",\"340\"],[11,\"height\",\"25\"],[9],[10],[0,\"\\n      \"],[7,\"circle\"],[11,\"cx\",\"15\"],[11,\"cy\",\"26\"],[11,\"r\",\"15\"],[9],[10],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"  \"],[10],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "formaviva-web/_pods/components/release/release-main-track/template.hbs"
    }
  });

  _exports.default = _default;
});