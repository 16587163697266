define("formaviva-web/_pods/analytics/sale/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "BEtP31MU",
    "block": "{\"symbols\":[],\"statements\":[[1,[29,\"sub-menu\",null,[[\"isBreadcrumb\",\"tabs\"],[true,[29,\"array\",[[29,\"hash\",null,[[\"name\",\"route\"],[\"Sales\",\"analytics.sales\"]]],[29,\"hash\",null,[[\"name\",\"route\",\"model\"],[[25,[\"purchase\",\"description\"]],\"analytics.sale\",[25,[\"purchase\",\"id\"]]]]]],null]]]],false],[0,\"\\n\\n\"],[7,\"div\"],[11,\"class\",\"row\"],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"col-md-12\"],[9],[0,\"\\n    \"],[1,[29,\"purchase/sales-list\",null,[[\"purchase\",\"items\"],[[25,[\"purchase\"]],[25,[\"purchase\",\"purchaseItems\"]]]]],false],[0,\"\\n  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\\n\"],[4,\"if\",[[25,[\"purchase\",\"hasShipping\"]]],null,{\"statements\":[[0,\"  \"],[7,\"div\"],[11,\"class\",\"row\"],[9],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"col-md-12 offset-top\"],[9],[0,\"\\n      \"],[1,[29,\"purchase/buyer-shipping-data\",null,[[\"data\"],[[25,[\"purchase\",\"checkoutData\",\"shipping_data\"]]]]],false],[0,\"\\n    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[1,[23,\"outlet\"],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "formaviva-web/_pods/analytics/sale/template.hbs"
    }
  });

  _exports.default = _default;
});