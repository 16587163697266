define("formaviva-web/macros/country-by-code", ["exports", "ember-macro-helpers/curried-computed", "formaviva-web/helpers/country-by-code"], function (_exports, _curriedComputed, _countryByCode) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _curriedComputed.default)(function (countryCode) {
    return (0, _countryByCode.countryByCode)(countryCode);
  });

  _exports.default = _default;
});