define("formaviva-web/_pods/components/track/tracks-table/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "iQEQLolz",
    "block": "{\"symbols\":[\"track\",\"index\"],\"statements\":[[7,\"div\"],[11,\"class\",\"table fv-table\"],[9],[0,\"\\n\"],[4,\"each\",[[25,[\"slicedTracks\"]]],null,{\"statements\":[[0,\"    \"],[1,[29,\"track/tracks-table-row\",null,[[\"index\",\"release\",\"track\",\"purchaseItem\",\"trackArtistsAllSame\",\"currentTrack\",\"isDownloading\"],[[24,2,[]],[25,[\"release\"]],[24,1,[]],[29,\"if\",[[24,1,[\"purchaseItem\"]],[24,1,[\"purchaseItem\"]],[25,[\"release\",\"purchaseItem\"]]],null],[25,[\"trackArtistsAllSame\"]],[25,[\"currentTrack\"]],[25,[\"isDownloading\"]]]]],false],[0,\"\\n\"]],\"parameters\":[1,2]},null],[4,\"liquid-if\",[[29,\"not-eq\",[[25,[\"slicedTracks\",\"length\"]],[25,[\"tracks\",\"length\"]]],null]],[[\"use\"],[\"toDown\"]],{\"statements\":[[0,\"    \"],[7,\"div\"],[11,\"class\",\"tracks-table-row cursor-pointer text-center clearfix\"],[12,\"onclick\",[29,\"action\",[[24,0,[]],\"toggleHiddenTracks\"],null]],[9],[0,\"\\n      View \"],[1,[29,\"sub\",[[25,[\"tracks\",\"length\"]],[25,[\"visibleTracksCount\"]]],null],false],[0,\" tracks\\n    \"],[10],[0,\"\\n\"]],\"parameters\":[]},null],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "formaviva-web/_pods/components/track/tracks-table/template.hbs"
    }
  });

  _exports.default = _default;
});