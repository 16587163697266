define("formaviva-web/services/password-reset", ["exports", "formaviva-web/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    ajax: Ember.inject.service(),
    endpoint: "".concat(_environment.default.apiBaseURL, "/passwords"),
    postUserEmail: function postUserEmail(email) {
      var endpointUrl = "".concat(this.get("endpoint"), "/forgot");
      return this.get("ajax").post(endpointUrl, {
        data: {
          password_reset: {
            email: email
          }
        }
      });
    },
    postNewPassword: function postNewPassword(password, token) {
      var endpointUrl = "".concat(this.get("endpoint"), "/reset");
      return this.get("ajax").post(endpointUrl, {
        data: {
          password_reset: {
            password: password,
            token: token
          }
        }
      });
    }
  });

  _exports.default = _default;
});