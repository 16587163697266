define("formaviva-web/_pods/components/profile/profile-form/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    countries: Ember.inject.service(),
    store: Ember.inject.service(),
    actions: {
      changeCountry: function changeCountry(countryCode) {
        this.set("profile.country", countryCode);
      },
      setShippingRegionCountry: function setShippingRegionCountry(shippingOrigin, countryCode) {
        shippingOrigin.set("countryCode", countryCode);
        var shippingGroups = [{
          from: countryCode,
          to: countryCode,
          price_per_unit: null,
          price_per_additional_unit: null
        }, {
          from: countryCode,
          to: "*",
          // The rest of the world,
          price_per_unit: null,
          price_per_additional_unit: null
        }];

        if (this.get("countries").isEuCountry(countryCode)) {
          var euShippingGroup = {
            from: countryCode,
            to: this.get("countries.euCountries").filter(function (c) {
              return c !== countryCode;
            }),
            price_per_unit: null,
            price_per_additional_unit: null,
            is_deletable: true
          };
          shippingGroups.splice(1, 0, euShippingGroup);
        }

        shippingOrigin.set("shippingRegionPrices", {
          shipping_groups: shippingGroups
        });
      },
      addShippingOrigin: function addShippingOrigin() {
        var shippingOrigin = this.get("store").createRecord("shipping-origin", {
          profile: this.get("profile")
        });
        this.get("profile.shippingOrigins").pushObject(shippingOrigin);
      },
      removeShippingOrigin: function removeShippingOrigin(shippingOrigin) {
        if (!confirm("Are you sure you want to delete this shipping origin? Merchandise shipping configurations with this origin country will be removed from all your merchandise.")) {
          return;
        }

        shippingOrigin.destroyRecord();
      }
    }
  });

  _exports.default = _default;
});