define("formaviva-web/_pods/release/edit/controller", ["exports", "formaviva-web/mixins/release-actions"], function (_exports, _releaseActions) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_releaseActions.default, {
    currentSession: Ember.inject.service(),
    release: Ember.computed.alias("model"),
    artistNamesDisplayStrategy: Ember.computed("release.artistNames", function () {
      return Ember.isBlank(this.release.artistNames) ? "extractFromTracks" : "custom";
    }),
    actions: {
      closeDeleteRelease: function closeDeleteRelease() {
        this.set("releaseForModal", null);
        this.set("releaseDeleteModalShown", false);
      },
      openDeleteRelease: function openDeleteRelease(release) {
        this.set("releaseForModal", release);
        this.set("releaseDeleteModalShown", true);
      }
    }
  });

  _exports.default = _default;
});