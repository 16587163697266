define("formaviva-web/_pods/feed/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Nytf8bGe",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"feed\"],[9],[0,\"\\n  \"],[1,[29,\"sub-menu\",null,[[\"tabs\"],[[29,\"array\",[[29,\"hash\",null,[[\"route\",\"name\",\"click\"],[\"feed.explore\",\"Feed\",[29,\"action\",[[24,0,[]],\"fetchLatest\"],null]]]],[29,\"hash\",null,[[\"route\",\"name\",\"click\"],[\"feed.likes\",\"Likes\",[29,\"action\",[[24,0,[]],\"fetchLatest\"],null]]]],[29,\"hash\",null,[[\"route\",\"name\",\"click\"],[\"feed.following\",\"Following\",[29,\"action\",[[24,0,[]],\"fetchLatest\"],null]]]],[29,\"hash\",null,[[\"route\",\"name\",\"click\"],[\"purchases\",\"Purchases\",[29,\"action\",[[24,0,[]],\"fetchLatest\"],null]]]]],null]]]],false],[0,\"\\n\\n  \"],[1,[23,\"outlet\"],false],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "formaviva-web/_pods/feed/template.hbs"
    }
  });

  _exports.default = _default;
});