define("formaviva-web/_pods/components/stats/stats-change/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "SVVueOj9",
    "block": "{\"symbols\":[],\"statements\":[[7,\"span\"],[12,\"class\",[30,[[29,\"change-class\",[[25,[\"change\"]]],null]]]],[9],[0,\"\\n  \"],[1,[29,\"stats/stats-change-icon\",null,[[\"change\"],[[25,[\"change\"]]]]],false],[0,\"\\n\\n\"],[4,\"unless\",[[29,\"eq\",[[25,[\"change\"]],0],null]],null,{\"statements\":[[0,\"    \"],[1,[29,\"abbreviate-number\",[[29,\"abs\",[[25,[\"change\"]]],null]],null],false],[0,\"\\n\"]],\"parameters\":[]},null],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "formaviva-web/_pods/components/stats/stats-change/template.hbs"
    }
  });

  _exports.default = _default;
});