define("formaviva-web/_pods/components/svg-symbol/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "vO49XFMn",
    "block": "{\"symbols\":[],\"statements\":[[7,\"use\"],[12,\"xlink:href\",[30,[\"#\",[23,\"icon\"]]],\"http://www.w3.org/1999/xlink\"],[9],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "formaviva-web/_pods/components/svg-symbol/template.hbs"
    }
  });

  _exports.default = _default;
});