define("formaviva-web/_pods/edit/notifications/controller", ["exports", "ember-awesome-macros", "ember-awesome-macros/raw"], function (_exports, _emberAwesomeMacros, _raw) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    currentSession: Ember.inject.service(),
    currentProfile: Ember.computed.reads("currentSession.profile"),
    notificationPreference: Ember.computed.alias("model"),
    likes: Ember.computed.reads("notificationPreference.likes"),
    follows: Ember.computed.reads("notificationPreference.follows"),
    releases: Ember.computed.reads("notificationPreference.releases"),
    newsletters: Ember.computed.reads("notificationPreference.newsletters"),
    releasesEmailPreference: (0, _emberAwesomeMacros.equal)("releases", (0, _raw.default)("email")),
    likesEmailPreference: (0, _emberAwesomeMacros.equal)("likes", (0, _raw.default)("email")),
    followsEmailPreference: (0, _emberAwesomeMacros.equal)("follows", (0, _raw.default)("email")),
    newslettersEmailPreference: (0, _emberAwesomeMacros.equal)("newsletters", (0, _raw.default)("email")),
    getNotificationPreference: function getNotificationPreference() {
      var notificationPreference = this.notificationPreference;
      var releasesPreference = this.releasesEmailPreference ? "email" : "none";
      notificationPreference.set("releases", releasesPreference);
      var followsPreference = this.followsEmailPreference ? "email" : "none";
      notificationPreference.set("follows", followsPreference);
      var likesPreference = this.likesEmailPreference ? "email" : "none";
      notificationPreference.set("likes", likesPreference);
      var newslettersPreference = this.newslettersEmailPreference ? "email" : "none";
      notificationPreference.set("newsletters", newslettersPreference);
      return notificationPreference;
    },
    actions: {
      saveUserPreferences: function saveUserPreferences() {
        var _this = this;

        var userPreference = this.getNotificationPreference();
        this.set("isSaving", true);
        userPreference.save().then(function () {
          _this.notifications.success("Notification preferences saved successfully.", {
            autoClear: true
          });
        }).catch(function (error) {
          if (window.Rollbar) Rollbar.error(error);

          _this.notifications.error("There was an unexpected error. Please try again or contact support.", {
            autoClear: true
          });
        }).finally(function () {
          return _this.set("isSaving", false);
        });
      }
    }
  });

  _exports.default = _default;
});