define("formaviva-web/_pods/dashboard/controller", ["exports", "formaviva-web/mixins/can-edit"], function (_exports, _canEdit) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_canEdit.default, {
    currentSession: Ember.inject.service(),
    profile: Ember.computed.readOnly("currentSession.profile"),
    router: Ember.inject.service(),
    currentRouteName: Ember.computed.readOnly("router.currentRouteName")
  });

  _exports.default = _default;
});