define("formaviva-web/_pods/components/release/release-content/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    queryParams: ["current_track_id"],
    current_track_id: null,
    currentSession: Ember.inject.service(),
    persistentPlayerState: Ember.inject.service(),
    shoppingCart: Ember.inject.service("checkout/shopping-cart"),
    release: Ember.computed.alias("model"),
    profile: Ember.computed.alias("release.profile"),
    currentTrack: Ember.computed("current_track_id", "persistentPlayerState.currentTrack.id", "tracks.[]", function () {
      var _this = this;

      return this.tracks && (this.tracks.find(function (t) {
        return t.id === _this.get("persistentPlayerState.currentTrack.id");
      }) || this.get("tracks.firstObject"));
    }),
    artistsToDisplay: Ember.computed("release.artists", "tracks", function () {
      if (Ember.isPresent(this.get("release.artists"))) {
        return this.get("release.artists");
      } else {
        var trackArtists = [];
        var tracks = this.tracks;

        if (tracks) {
          tracks.forEach(function (track) {
            var individualTrackArtists = track.get("artists");
            individualTrackArtists.forEach(function (artist) {
              trackArtists.push(artist);
            });
          });
        }

        return trackArtists.uniq();
      }
    }),
    actions: {
      addReleaseToCart: function addReleaseToCart(release) {
        this.get("shoppingCart").addItem({
          release: release
        });
      },
      addMerchToCart: function addMerchToCart(merch) {
        this.get("shoppingCart").addItem({
          merch: merch
        });
      }
    }
  });

  _exports.default = _default;
});