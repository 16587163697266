define("formaviva-web/utils/url-utils", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    getJsonFromUrl: function getJsonFromUrl(url) {
      if (!url) url = location.search;
      var query = url.substr(1);
      var result = {};
      query.split("&").forEach(function (part) {
        var item = part.split("=");
        result[item[0]] = decodeURIComponent(item[1]);
      });
      return result;
    }
  };
  _exports.default = _default;
});