define("formaviva-web/services/profile-stats", ["exports", "formaviva-web/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    authenticatedAjax: Ember.inject.service(),
    fetchStats: function fetchStats(_ref) {
      var dateFrom = _ref.dateFrom,
          dateTo = _ref.dateTo;
      var url = "".concat(_environment.default.apiBaseURL, "/profile_stats/stats");
      return this.get("authenticatedAjax").request(url, {
        data: {
          date_from: dateFrom.toISOString(),
          date_to: dateTo.toISOString()
        }
      });
    },
    fetchPayoutStats: function fetchPayoutStats() {
      var url = "".concat(_environment.default.apiBaseURL, "/profile_stats/payout");
      return this.get("authenticatedAjax").request(url);
    }
  });

  _exports.default = _default;
});