define("formaviva-web/_pods/components/release/release-left-bar/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "y0Rhnvca",
    "block": "{\"symbols\":[],\"statements\":[[1,[29,\"cover-image\",null,[[\"class\",\"record\",\"isPrelaunchLocked\",\"url\"],[\"release-image\",[25,[\"release\"]],[25,[\"isPrelaunchLocked\"]],[25,[\"release\",\"adjustedImageUrl\"]]]]],false],[0,\"\\n\\n\"],[7,\"div\"],[11,\"class\",\"release-left-bar__description\"],[9],[0,\"\\n\"],[4,\"layout/overlay-shortener\",null,[[\"maxSize\"],[350]],{\"statements\":[[0,\"    \"],[1,[29,\"new-lines-to-paragraphs\",[[25,[\"release\",\"description\"]]],null],true],[0,\"\\n\"]],\"parameters\":[]},null],[10],[0,\"\\n\"],[7,\"div\"],[11,\"class\",\"release-left-bar__label\"],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"collection-title offset-top clearfix\"],[9],[0,\"\\n    \"],[7,\"span\"],[11,\"class\",\"pull-left\"],[9],[0,\"Released by\"],[10],[0,\"\\n\"],[4,\"if\",[[25,[\"release\",\"releaseDate\"]]],null,{\"statements\":[[0,\"      \"],[7,\"span\"],[11,\"class\",\"pull-right collection-title__release-date\"],[9],[0,\"\\n        \"],[1,[29,\"moment-format\",[[25,[\"release\",\"releaseDate\"]],\"DD MMMM YYYY\"],null],false],[0,\"\\n      \"],[10],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"  \"],[10],[0,\"\\n  \"],[1,[29,\"profile/profile-grid\",null,[[\"profile\",\"artists\",\"class\"],[[25,[\"release\",\"profile\"]],[25,[\"release\",\"artists\"]],\"offset-top\"]]],false],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"release-tags border-top__thin clearfix pt-8 pb-4 mt-8\"],[9],[0,\"\\n    \"],[1,[29,\"tag/tags-list\",null,[[\"filterByTag\",\"record\"],[[25,[\"filterByTag\"]],[25,[\"release\"]]]]],false],[0,\"\\n  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "formaviva-web/_pods/components/release/release-left-bar/template.hbs"
    }
  });

  _exports.default = _default;
});