define("formaviva-web/templates/loading", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "IdPX2+tk",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"css-loader\"],[9],[0,\"\\n  \"],[1,[23,\"loader-line-scale-pulse-out\"],false],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "formaviva-web/templates/loading.hbs"
    }
  });

  _exports.default = _default;
});