define("formaviva-web/initializers/route-transition-action", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.initialize = initialize;
  _exports.default = void 0;

  function initialize()
  /* application */
  {
    Ember.Route.reopen({
      metrics: Ember.inject.service(),
      fastboot: Ember.inject.service(),
      actions: {
        didTransition: function didTransition() {
          /* prevent the function from running in the application route so it
             doesn't run twice */
          if (this.routeName === "application") return true;

          if (!this.get("hasCustomAsyncTitle")) {
            this.controllerFor("application").setPageTitle(this.get("pageTitle"));
          }

          if (!this.get("fastboot.isFastBoot")) {
            /* send pageview to Google Analytics */
            Ember.run.scheduleOnce("afterRender", this, this._trackPage);
          }

          return true; // bubble the event
        }
      },
      _trackPage: function _trackPage() {
        var page = window.location.pathname + window.location.search;
        var title = this.routeName;

        if (page && page.includes("izvir")) {
          // Do not track for this page. It's a custom event page and we don't want it to mess the music portal stats
          return;
        }

        this.metrics.trackPage("GoogleAnalytics", {
          page: page,
          title: title
        });
        this.metrics.trackPage("FacebookPixel", {
          page: page,
          title: title
        });
      }
    });
  }

  var _default = {
    initialize: initialize
  };
  _exports.default = _default;
});