define("formaviva-web/components/loader-line-scale-pulse-out-rapid", ["exports", "ember-cli-loaders/components/loader-line-scale-pulse-out-rapid"], function (_exports, _loaderLineScalePulseOutRapid) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _loaderLineScalePulseOutRapid.default;
    }
  });
});