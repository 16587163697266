define("formaviva-web/initializers/rollbar-error-tracking", ["exports", "formaviva-web/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.initialize = initialize;
  _exports.default = void 0;

  function initialize() {
    if (_environment.default.environment === "development" || _environment.default.environment === "test") {
      return;
    }

    var errorLogger = Ember.Logger.error;

    var stringify = function stringify(object) {
      return JSON ? JSON.stringify(object) : object.toString();
    };

    var isError = function isError(object) {
      return Ember.typeOf(object) === "error";
    };

    Ember.Logger.error = function () {
      var args = Array.prototype.slice.call(arguments),
          err = isError(args[0]) ? args[0] : new Error(stringify(args));

      if (window.Rollbar) {
        Rollbar.error.call(Rollbar, err);
      }

      errorLogger.apply(this, arguments);
    };

    Ember.onerror = function (error) {
      if (window.Rollbar) {
        Rollbar.error.call(Rollbar, error);
      }
    };

    Ember.RSVP.on("error", function (error) {
      // https://github.com/emberjs/ember.js/issues/12505
      // Don't propagate aborted transitions
      if (window.Rollbar && error.name !== "TransitionAborted") {
        Rollbar.error.call(Rollbar, error);
      }
    });
  }

  var _default = {
    name: "rollbar-error-tracking",
    initialize: initialize
  };
  _exports.default = _default;
});