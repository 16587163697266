define("formaviva-web/_pods/analytics/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "mcXO/Yi/",
    "block": "{\"symbols\":[],\"statements\":[[1,[29,\"sub-menu\",null,[[\"tabs\"],[[29,\"array\",[[29,\"hash\",null,[[\"route\",\"name\"],[\"analytics.play-sessions\",\"Play Session\"]]],[29,\"hash\",null,[[\"route\",\"name\",\"currentWhen\"],[\"analytics.sales\",\"Sales\",\"analytics.sales analytics.sale.index analytics.sale\"]]],[29,\"hash\",null,[[\"route\",\"name\"],[\"analytics.payouts\",\"Payouts\"]]]],null]]]],false],[0,\"\\n\"],[1,[23,\"outlet\"],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "formaviva-web/_pods/analytics/template.hbs"
    }
  });

  _exports.default = _default;
});