define("formaviva-web/_pods/components/shopping-cart/opener/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "mL15td07",
    "block": "{\"symbols\":[\"dd\"],\"statements\":[[4,\"click-outside\",null,[[\"action\",\"except-selector\"],[[29,\"action\",[[24,0,[]],\"closeShoppingCart\"],null],\".dont-trigger-shopping-cart-toggle\"]],{\"statements\":[[4,\"bs-dropdown\",null,[[\"isOpen\",\"closeOnMenuClick\",\"class\"],[[25,[\"shoppingCart\",\"isOpened\"]],false,\"dropdown-checkout\"]],{\"statements\":[[0,\"    \"],[4,\"component\",[[29,\"-assert-implicit-component-helper-argument\",[[24,1,[\"button\"]],\"expected `dd.button` to be a contextual component but found a string. Did you mean `(component dd.button)`? ('formaviva-web/_pods/components/shopping-cart/opener/template.hbs' @ L3:C7) \"],null]],[[\"class\"],[\"btn btn-navbar-icon\"]],{\"statements\":[[1,[29,\"fa-icon\",[\"shopping-cart\"],null],false]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"if\",[[29,\"gt\",[[25,[\"shoppingCart\",\"cartItems\",\"length\"]],0],null]],null,{\"statements\":[[0,\"      \"],[7,\"div\"],[11,\"class\",\"notification-item-count\"],[9],[0,\"\\n        \"],[1,[25,[\"shoppingCart\",\"cartItems\",\"length\"]],false],[0,\"\\n      \"],[10],[0,\"\\n\"]],\"parameters\":[]},null],[4,\"component\",[[29,\"-assert-implicit-component-helper-argument\",[[24,1,[\"menu\"]],\"expected `dd.menu` to be a contextual component but found a string. Did you mean `(component dd.menu)`? ('formaviva-web/_pods/components/shopping-cart/opener/template.hbs' @ L9:C7) \"],null]],null,{\"statements\":[[0,\"      \"],[7,\"li\"],[9],[0,\"\\n        \"],[1,[29,\"shopping-cart/cart\",null,[[\"showCheckoutButton\",\"showShippingCountrySelector\",\"class\"],[true,true,\"dont-trigger-shopping-cart-toggle\"]]],false],[0,\"\\n      \"],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[1]},null]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "formaviva-web/_pods/components/shopping-cart/opener/template.hbs"
    }
  });

  _exports.default = _default;
});