define("formaviva-web/_pods/index/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    currentSession: Ember.inject.service(),
    metrics: Ember.inject.service(),
    beforeModel: function beforeModel(transition) {
      if (transition.targetName === "index" && this.get("currentSession.isAuthenticated")) {
        this.transitionTo("dashboard");
      }
    },
    model: function model() {
      var mixes = this.store.query("track", {
        homepage: true,
        filter: {
          mix: true
        }
      }).catch(function (error) {
        if (window.Rollbar) Rollbar.error(error);
        return [];
      });
      var releases = this.store.query("release", {
        public: true,
        all: true,
        sort: "-release_date",
        filter: {
          landing_page: true
        }
      }).catch(function (error) {
        if (window.Rollbar) Rollbar.error(error);
        return [];
      });
      return Ember.RSVP.hash({
        mixes: mixes,
        releases: releases
      });
    },
    actions: {
      didTransition: function didTransition() {
        this._super.apply(this, arguments);

        this.userBehaviourTracker.track({
          event: "landing_page"
        });
        return true;
      }
    }
  });

  _exports.default = _default;
});