define("formaviva-web/mixins/can-edit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    canEdit: Ember.computed("currentSession.user.{canAdmin,profileId}", "profile.id", function () {
      // Is the current user looking at a profile they manage?
      var hasAccess = String(this.get("profile.id")) === String(this.get("currentSession.user.profileId")); // Can administrate?

      var canAdmin = this.get("currentSession.user.canAdmin");
      return hasAccess && canAdmin;
    })
  });

  _exports.default = _default;
});