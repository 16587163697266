define("formaviva-web/utils/listen-signup-manager", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var LISTEN_SIGNUP_MANAGER = {
    time: 0,
    timeLimit: 60,
    // ~120 seconds
    onTick: function onTick() {
      this.time++;

      if (this.time > this.timeLimit) {
        this.appRoute.showRegisrationModal();
      }
    },
    startTimer: function startTimer() {
      clearInterval(this._timer);
      this._timer = setInterval(this.onTick.bind(this), 2000);
    },
    stopTimer: function stopTimer() {
      clearInterval(this._timer);
    },
    start: function start(appRoute) {
      this.time = 0;
      this.appRoute = appRoute;
      appRoute.messageBus.subscribe("playback:global:play", this, this.startTimer);
      appRoute.messageBus.subscribe("playback:global:pause", this, this.stopTimer);
    },
    stop: function stop(appRoute) {
      this.time = 0;
      this.appRoute = null;
      appRoute.messageBus.unsubscribe("playback:global:play", this, this.startTimer);
      appRoute.messageBus.unsubscribe("playback:global:pause", this, this.stopTimer);
      this.stopTimer();
    }
  };
  var _default = LISTEN_SIGNUP_MANAGER;
  _exports.default = _default;
});