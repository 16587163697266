define("formaviva-web/_pods/welcome/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "L7VvpePQ",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"row\"],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"col-md-8 col-md-offset-2 copy-text-area\"],[9],[0,\"\\n    \"],[7,\"h4\"],[9],[0,\"Welcome to Formaviva, \"],[1,[25,[\"currentSession\",\"user\",\"name\"]],false],[0,\"!\"],[10],[0,\"\\n\\n    \"],[7,\"p\"],[9],[0,\"\\n      Formaviva is a new platform for alternative electronic music. We want to enable artists and music lovers to enjoy new\\n      ways streaming, discovering and merchandising alternative electronic music.\\n    \"],[10],[0,\"\\n\\n\"],[4,\"link-to\",[\"releases\"],[[\"class\"],[\"btn btn-primary offset-top\"]],{\"statements\":[[0,\"      Browse releases\\n\"]],\"parameters\":[]},null],[0,\"\\n    \"],[7,\"hr\"],[11,\"class\",\"offset-top\"],[9],[10],[0,\"\\n\\n    \"],[7,\"h5\"],[9],[0,\"Are you an artist or a label?\"],[10],[0,\"\\n\\n    \"],[7,\"p\"],[9],[0,\"\\n      In order to be able to ensure all the music and content on Formaviva complies to certain quality standards, we require\\n      every label and artist to go through our approval procces.\\n    \"],[10],[0,\"\\n\\n\"],[4,\"link-to\",[\"apply\"],[[\"class\",\"target\"],[\"btn btn-secondary offset-top\",\"_blank\"]],{\"statements\":[[0,\"      Apply for a profile\\n\"]],\"parameters\":[]},null],[0,\"  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "formaviva-web/_pods/welcome/template.hbs"
    }
  });

  _exports.default = _default;
});