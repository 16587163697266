define("formaviva-web/utils/scrollable-table", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = scrollableTable;

  // Scrollable tables have to have block layout, which
  // breaks the native table layout. This function manually sets
  // the header widths to match tbody cell widths.
  function scrollableTable($table) {
    var $bodyCells = $table.find("tbody tr:first").children();
    var colWidth; // Get the tbody columns width array

    colWidth = $bodyCells.map(function () {
      return (0, _jquery.default)(this).width();
    }).get(); // Set the width of thead columns

    $table.find("thead tr").children().each(function (i, v) {
      (0, _jquery.default)(v).width(colWidth[i]);
    });
  }
});