define("formaviva-web/models/shipping-weighted-price", ["exports", "ember-data", "ember-data-model-fragments/fragment", "ember-cp-validations"], function (_exports, _emberData, _fragment, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var attr = _emberData.default.attr;
  var Validations = (0, _emberCpValidations.buildValidations)({
    minWeight: (0, _emberCpValidations.validator)("number", {
      allowString: true,
      gte: 0
    }),
    cost: (0, _emberCpValidations.validator)("number", {
      allowString: true,
      gte: 0
    })
  });

  var _default = _fragment.default.extend(Validations, {
    minWeight: attr("number"),
    cost: attr("number")
  });

  _exports.default = _default;
});