define("formaviva-web/_pods/components/svg-symbol/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: "svg",
    classNames: ["svg-symbol"]
  });

  _exports.default = _default;
});