define("formaviva-web/models/shipping-zone", ["exports", "ember-data", "ember-data-model-fragments/fragment", "ember-data-model-fragments/attributes", "ember-cp-validations"], function (_exports, _emberData, _fragment, _attributes, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var attr = _emberData.default.attr;
  var Validations = (0, _emberCpValidations.buildValidations)({
    to: (0, _emberCpValidations.validator)("presence", true),
    pricesContainer: (0, _emberCpValidations.validator)("belongs-to")
  });

  var _default = _fragment.default.extend(Validations, {
    to: attr(),
    isCalculationTypeQuantity: Ember.computed.equal("pricesContainer.type", "shipping_by_quantity_price"),
    isCalculationTypeWeight: Ember.computed.equal("pricesContainer.type", "shipping_by_weight_price"),
    isItRestOfWorld: Ember.computed.equal("to", "*"),
    pricesContainer: (0, _attributes.fragment)("shipping-prices-container", {
      polymorphic: true,
      typeKey: "type",
      defaultValue: {
        type: "shipping_by_quantity_price"
      }
    })
  });

  _exports.default = _default;
});