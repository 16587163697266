define("formaviva-web/utils/display-round-off", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _default;

  function _default(number) {
    var baseValue = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 100;
    return number > baseValue ? "".concat(baseValue, "+") : number;
  }
});