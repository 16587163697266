define("formaviva-web/utils/generate-random-token", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Generates random string with a given length
   * @param {number} stringLength
   * @returns {string}
   */
  var _default = function _default(stringLength) {
    return Math.random().toString(36).substring(2, stringLength + 2);
  };

  _exports.default = _default;
});