define("formaviva-web/_pods/profile/releases/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    currentSession: Ember.inject.service(),
    profile: Ember.computed.alias("model.profile")
  });

  _exports.default = _default;
});