define("formaviva-web/_pods/blog/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "K0HFCIoz",
    "block": "{\"symbols\":[],\"statements\":[[1,[29,\"sub-menu\",null,[[\"isBreadcrumb\",\"tabs\"],[true,[29,\"array\",[[29,\"hash\",null,[[\"name\"],[\"Pages\"]]],[29,\"hash\",null,[[\"name\"],[[25,[\"model\",\"title\"]]]]]],null]]]],false],[0,\"\\n\\n\"],[7,\"section\"],[11,\"class\",\"static-page-container\"],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"static-page-header\"],[9],[0,\"\\n    \"],[7,\"span\"],[11,\"class\",\"static-page-date\"],[9],[1,[29,\"moment-format\",[[25,[\"model\",\"updatedAt\"]],\"MMMM D,YYYY\"],null],false],[10],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"static-page-title\"],[9],[1,[25,[\"model\",\"title\"]],false],[10],[0,\"\\n  \"],[10],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"static-page-body\"],[9],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"static-page-links social-links\"],[9],[0,\"\\n      \"],[1,[29,\"fb-share-button\",null,[[\"title\",\"url\"],[[25,[\"model\",\"title\"]],[25,[\"shareUrl\"]]]]],false],[0,\"\\n      \"],[1,[29,\"twitter-share-button\",null,[[\"title\",\"url\"],[[25,[\"model\",\"title\"]],[25,[\"shareUrl\"]]]]],false],[0,\"\\n    \"],[10],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"static-page-content\"],[9],[0,\"\\n      \"],[1,[29,\"html-safe\",[[25,[\"model\",\"content\"]]],null],false],[0,\"\\n    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "formaviva-web/_pods/blog/template.hbs"
    }
  });

  _exports.default = _default;
});