define("formaviva-web/_pods/components/table/cell/multiple-value-paths/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "AkVSSMWF",
    "block": "{\"symbols\":[\"valuePath\",\"@row\",\"@column\"],\"statements\":[[4,\"truncate-with-tooltip\",null,null,{\"statements\":[[4,\"each\",[[24,3,[\"valuePaths\"]]],null,{\"statements\":[[0,\"    \"],[1,[29,\"get\",[[24,2,[\"content\"]],[24,1,[]]],null],false],[0,\"\\n\"]],\"parameters\":[1]},null]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "formaviva-web/_pods/components/table/cell/multiple-value-paths/template.hbs"
    }
  });

  _exports.default = _default;
});