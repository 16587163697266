define("formaviva-web/validations/order", ["exports", "ember-changeset-validations/validators"], function (_exports, _validators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    name: [(0, _validators.validatePresence)(true), (0, _validators.validateLength)({
      min: 4
    })],
    email: (0, _validators.validateFormat)({
      type: "email"
    })
    /*
    redirectUrl: [
      validatePresence(true)
    ],
    productType: [
      validatePresence(true)
    ],
    productId: [
      validatePresence(true)
    ]
    */

  };
  _exports.default = _default;
});