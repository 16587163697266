define("formaviva-web/_pods/components/fv-profile-image/component", ["exports", "ember-initials/components/initials"], function (_exports, _initials) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // This is a wrapper component to re-export the ember-initials
  // component. Since the application is based on pods structure
  // ember-initials doesn't work out of the box and so there is a
  // necessity to re-export and use it accordingly.
  // For more info
  // 1. https://github.com/Exelord/ember-initials/issues/32
  // 2. https://github.com/Exelord/ember-initials/issues/58
  var _default = _initials.default.extend({});

  _exports.default = _default;
});