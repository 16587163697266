define("formaviva-web/_pods/components/purchase/purchase-list/component", ["exports", "formaviva-web/mixins/track-download", "ember-awesome-macros", "formaviva-web/constants/common"], function (_exports, _trackDownload, _emberAwesomeMacros, _common) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_trackDownload.default, {
    downloadOptions: Object.freeze(["mp3", "wav", "flac", "aiff"]),
    isDownloading: false,
    totalTracks: null,
    fileDownloadLimit: (0, _emberAwesomeMacros.raw)(_common.FILE_DOWNLOAD_LIMIT),
    downloadPopupVisibleAtTop: false,
    downloadPopupVisibleAtBottom: false,
    downloadList: Ember.computed("totalTracks", function () {
      var totalTracks = this.totalTracks;

      if (totalTracks && totalTracks.length <= _common.FILE_DOWNLOAD_LIMIT) {
        return totalTracks.mapBy("id");
      } else return [];
    }),
    numberOfSelectedTracks: Ember.computed.reads("downloadList.length"),
    withinTrackLimits: (0, _emberAwesomeMacros.lte)("numberOfSelectedTracks", (0, _emberAwesomeMacros.raw)(_common.FILE_DOWNLOAD_LIMIT)),
    actions: {
      toggleDownloadPopup: function toggleDownloadPopup(position) {
        if (!this.downloadDisabledReason) {
          if (position === "top") this.toggleProperty("downloadPopupVisibleAtTop");else this.toggleProperty("downloadPopupVisibleAtBottom");
        }
      },
      addToDownloadList: function addToDownloadList(trackId) {
        var index = this.downloadList.indexOf(trackId);
        if (index < 0) this.downloadList.pushObject(trackId);else this.downloadList.removeAt(index);
      }
    }
  });

  _exports.default = _default;
});