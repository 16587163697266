define("formaviva-web/utils/shopping-cart-item", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Object.extend({
    setItem: function setItem(_ref) {
      var track = _ref.track,
          release = _ref.release,
          merch = _ref.merch,
          quantity = _ref.quantity;
      this.setProperties({
        release: release,
        track: track,
        merch: merch,
        quantity: quantity || 1
      });
    },
    computedId: Ember.computed("track", "release", "merch", function () {
      var _this$getProperties = this.getProperties("track", "release", "merch"),
          track = _this$getProperties.track,
          release = _this$getProperties.release,
          merch = _this$getProperties.merch;

      var idArray = [];

      if (track) {
        idArray.push("track_".concat(track.get("id")));
      }

      if (release) {
        idArray.push("release_".concat(release.get("id")));
      }

      if (merch) {
        idArray.push("merch_".concat(merch.get("id")));
      }

      return idArray.join("_");
    }),
    item: Ember.computed("track", "release", "merch", function () {
      var _this$getProperties2 = this.getProperties("track", "release", "merch"),
          track = _this$getProperties2.track,
          release = _this$getProperties2.release,
          merch = _this$getProperties2.merch;

      if (track) {
        return track;
      } else if (release) {
        return release;
      } else if (merch) {
        return merch;
      } else {
        return undefined;
      }
    }),
    price: Ember.computed("track", "release", "merch", function () {
      var _this$getProperties3 = this.getProperties("track", "release", "merch"),
          track = _this$getProperties3.track,
          release = _this$getProperties3.release,
          merch = _this$getProperties3.merch;

      if (track && release) {
        return release.get("pricePerTrack");
      } else if (track && track.mix) {
        return track.get("price");
      } else if (release) {
        return release.get("price");
      } else if (merch) {
        return merch.get("price");
      } else {
        return undefined;
      }
    }),
    name: Ember.computed.alias("item.name"),
    mix: Ember.computed.alias("item.mix"),
    imageUrl: Ember.computed.alias("item.adjustedImageUrl"),
    serialize: function serialize() {
      var _this$getProperties4 = this.getProperties("track", "release", "merch", "quantity"),
          track = _this$getProperties4.track,
          release = _this$getProperties4.release,
          merch = _this$getProperties4.merch,
          quantity = _this$getProperties4.quantity;

      var trackId = track ? track.id : null;
      var releaseId = release ? release.id : null;
      var merchId = merch ? merch.id : null;
      return {
        trackId: trackId,
        releaseId: releaseId,
        merchId: merchId,
        quantity: quantity
      };
    }
  });

  _exports.default = _default;
});