define("formaviva-web/services/countries", ["exports", "formaviva-web/constants/countries"], function (_exports, _countries) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    ajax: Ember.inject.service(),
    init: function init() {
      this._super.apply(this, arguments);

      this.set("countries", _countries.default);
    },
    searchByLabel: function searchByLabel(label) {
      return _countries.default.filter(function (c) {
        return c.label.toLowerCase().indexOf(label.toLowerCase()) !== -1;
      }).mapBy("id");
    },
    isEuCountry: function isEuCountry(countryCode) {
      return this.get("euCountries").includes(countryCode);
    },
    codeToName: function codeToName(countryCode) {
      var attr = countryCode.length === 2 ? "id" : "alpha3";
      var c = this.get("countries").findBy(attr, countryCode);
      return c.label;
    },
    countryCodes: Ember.computed("countries", function () {
      return this.get("countries").mapBy("id");
    }),
    euCountries: Ember.computed("countries", function () {
      return ["AT", "BE", "BG", "CY", "CZ", "DE", "DK", "EE", "ES", "FI", "FR", "GB", "GR", "HR", "HU", "IE", "IT", "LT", "LU", "LV", "MT", "NL", "PL", "PT", "RO", "SE", "SI", "SK"];
    }),
    getIpInfo: function getIpInfo() {
      return this.get("ajax").request("https://ipinfo.io").then(function (data) {
        return data;
      }, function () {
        return {};
      });
    },
    getCurrentCountry: function getCurrentCountry() {
      return this.getIpInfo().then(function (data) {
        return data.country;
      });
    },
    compareCodes: function compareCodes(codeA, codeB) {
      if (codeA === codeB) return true;
      var nameA = this.codeToName(codeA);
      var nameB = this.codeToName(codeB);
      return nameA === nameB;
    }
  });

  _exports.default = _default;
});