define("formaviva-web/_pods/dashboard/releases/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    queryParams: {
      page: {
        refreshModel: true
      },
      sort: {
        refreshModel: true
      },
      dir: {
        refreshModel: true
      }
    },
    model: function model(_ref) {
      var page = _ref.page,
          _ref$sort = _ref.sort,
          sort = _ref$sort === void 0 ? "release_date" : _ref$sort,
          _ref$dir = _ref.dir,
          dir = _ref$dir === void 0 ? "desc" : _ref$dir;
      return this.store.query("release", {
        page: page,
        sort: "".concat(dir === "desc" ? "-" : "").concat(sort),
        per_page: 20
      });
    }
  });

  _exports.default = _default;
});