define("formaviva-web/_pods/components/track/track-row/component", ["exports", "formaviva-web/mixins/track-download", "formaviva-web/_pods/components/player/player-slave/component"], function (_exports, _trackDownload, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend(_trackDownload.default, {
    notifications: Ember.inject.service("notification-messages"),
    trackActions: Ember.inject.service("track/track-actions"),
    shoppingCart: Ember.inject.service("checkout/shoppingCart"),
    playerHeight: 28,
    barWidth: 2,
    cursorWidth: 0,
    sharePopupVisible: false,
    actions: {
      addToCart: function addToCart(track, release) {
        if (track.mix) this.shoppingCart.addMixToCart(track);else this.shoppingCart.addTrackToCart(track, release);
      },
      likeTrack: function likeTrack(track) {
        this.get("trackActions").likeTrack(track);
      },
      toggleSharePopup: function toggleSharePopup() {
        this.toggleProperty("sharePopupVisible");
      }
    },
    isTheCurrentlyPlayingTrack: Ember.computed("persistentPlayerState.currentTrack.id", "track.id", function () {
      return this.get("persistentPlayerState.currentTrack.id") === this.get("track.id");
    }),
    init: function init() {
      this._super.apply(this, arguments);

      this.set("downloadOptions", ["mp3", "wav", "flac", "aiff"]);
      this.set("timestamp", this.track.likedAt);
    }
  });

  _exports.default = _default;
});