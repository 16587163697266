define("formaviva-web/_pods/components/date-range-picker/component", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    rangeOptions: Ember.computed("ranges", function () {
      var rangeOptions = {};

      if (!this.get("ranges") || this.get("ranges").indexOf("day") !== -1) {
        rangeOptions["Last Day"] = [(0, _moment.default)().subtract(1, "days"), (0, _moment.default)()];
      }

      if (!this.get("ranges") || this.get("ranges").indexOf("week") !== -1) {
        rangeOptions["Last 7 Days"] = [(0, _moment.default)().subtract(7, "days"), (0, _moment.default)()];
      }

      if (!this.get("ranges") || this.get("ranges").indexOf("month") !== -1) {
        rangeOptions["Last month"] = [(0, _moment.default)().subtract(30, "days"), (0, _moment.default)()];
      }

      if (!this.get("ranges") || this.get("ranges").indexOf("3_months") !== -1) {
        rangeOptions["Last 3 months"] = [(0, _moment.default)().subtract(30 * 3, "days"), (0, _moment.default)()];
      }

      if (!this.get("ranges") || this.get("ranges").indexOf("6_months") !== -1) {
        rangeOptions["Last 6 months"] = [(0, _moment.default)().subtract(30 * 6, "days"), (0, _moment.default)()];
      }

      if (!this.get("ranges") || this.get("ranges").indexOf("12_months") !== -1) {
        rangeOptions["Last year"] = [(0, _moment.default)().subtract(365, "days"), (0, _moment.default)()];
      }

      return rangeOptions;
    }),
    didInsertElement: function didInsertElement() {
      this.setupRangePicker();
    },
    didUpdateAttrs: function didUpdateAttrs() {
      Ember.run.scheduleOnce("afterRender", this, this.setupRangePicker);
    },
    setupRangePicker: function setupRangePicker() {
      var _this = this;

      var startDate = this.get("dateFrom");
      var endDate = this.get("dateTo");
      var minDate = this.get("minDate");
      var ranges = this.get("rangeOptions");
      var format = this.get("dateFormat") ? this.get("dateFormat") : "YYYY-MM-DD";
      return this.$(".daterangepicker").daterangepicker({
        startDate: startDate,
        endDate: endDate,
        autoApply: true,
        locale: {
          format: format
        },
        minDate: minDate,
        maxDate: (0, _moment.default)(),
        opens: "left",
        ranges: ranges
      }, function (startDate, endDate) {
        _this.get("setDateRange")(startDate, endDate);
      });
    }
  });

  _exports.default = _default;
});