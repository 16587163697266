define("formaviva-web/breakpoints", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    mobile: "(max-width: 767px)",
    tablet: "(min-width: 768px) and (max-width: 991px)",
    desktop: "(min-width: 992px)"
  };
  _exports.default = _default;
});