define("formaviva-web/_pods/merch/show/route", ["exports", "formaviva-web/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    metrics: Ember.inject.service(),
    setupController: function setupController(controller, model) {
      this._super(controller, model); // setup order


      var return_url = "".concat(_environment.default.webURL) + this.get("router.url");
      var order = this.get("store").createRecord("order", {
        productType: "Merch",
        productId: model.get("id"),
        returnUrl: return_url,
        quantity: 1,
        totalPrice: model.get("price")
      });
      controller.set("order", order);
    },
    actions: {
      didTransition: function didTransition() {
        var metrics = this.get("metrics"),
            merch = this.controller.get("model");
        metrics.trackEvent({
          event: "View Merch",
          type: "event",
          name: merch.get("name"),
          slug: merch.get("slug")
        });
        return true;
      },
      submitOrder: function submitOrder(order) {
        var _this = this;

        order.save().then(function (response) {
          try {
            var merch = _this.controller.get("model");

            _this.get("metrics").trackEvent({
              event: "Checkout",
              type: "event",
              name: merch.get("name"),
              slug: merch.get("slug")
            });
          } finally {
            var redirect = response.get("checkoutUrl");
            Ember.run.later(function () {
              window.location.href = redirect;
            }, 1000);
          }
        });
      }
    }
  });

  _exports.default = _default;
});