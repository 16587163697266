define("formaviva-web/models/purchase", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Model = _emberData.default.Model;
  var belongsTo = _emberData.default.belongsTo;
  var hasMany = _emberData.default.hasMany;
  var attr = _emberData.default.attr;

  var _default = Model.extend({
    createdAt: attr("date"),
    subtotal: attr("number"),
    vat: attr("number"),
    total: attr("number"),
    revenueShare: attr("number"),
    shipping: attr("number"),
    downloadReady: attr("boolean"),
    checkoutData: attr(),
    shippingPerSeller: attr(),
    profile: belongsTo("profile", {
      async: false
    }),
    purchaseItems: hasMany("purchase-item"),
    hasDigitalItem: Ember.computed("purchaseItems", function () {
      return this.purchaseItems.isAny("digital", true);
    }),
    revenue: Ember.computed("revenueShare", "subtotal", "shipping", function () {
      return this.get("subtotal") + this.get("shipping") || 0;
    }),
    itemsCount: Ember.computed("purchaseItems", function () {
      return this.hasMany("purchaseItems").ids().length;
    }),
    totalWithoutShipping: Ember.computed("total", "shipping", function () {
      return this.get("total") - this.get("shipping") || 0;
    }),
    forPayout: Ember.computed("revenueShare", "subtotal", "shipping", function () {
      return Math.round((this.get("shipping") + this.get("subtotal") - this.get("revenueShare")) * 100) / 100;
    }),
    description: Ember.computed("purchaseItems.[]", function () {
      return this.get("purchaseItems").mapBy("description").join(", ");
    }),
    hasShipping: Ember.computed("purchaseItems", "shipping", function () {
      return this.get("purchaseItems").toArray().some(function (i) {
        return i.resourceType == "merch";
      });
    }),
    allowMultiDownload: attr("boolean"),
    status: Ember.computed("purchaseItems.{[],firstObject.status}", function () {
      if (this.get("purchaseItems.length") == 1) {
        return this.purchaseItems.firstObject.status;
      } else {
        var statuses = this.get("purchaseItems").mapBy("status").uniq();

        if (statuses.every(function (s) {
          return s == "processed";
        })) {
          return "processed";
        } else if (statuses.every(function (s) {
          return s == "shipped";
        })) {
          return "shipped";
        } else {
          return "in progress";
        }
      }
    })
  });

  _exports.default = _default;
});