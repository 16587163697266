define("formaviva-web/_pods/components/table/cell/action-btns/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "en0e/AU7",
    "block": "{\"symbols\":[\"@extra\",\"@row\"],\"statements\":[[4,\"if\",[[24,2,[\"content\",\"slug\"]]],null,{\"statements\":[[0,\"  \"],[1,[29,\"action-btns\",null,[[\"track\",\"release\",\"allowPurchase\"],[[24,2,[\"content\"]],[24,1,[\"extraData\",\"release\"]],[24,1,[\"extraData\",\"release\",\"allowIndividualTracksPurchase\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "formaviva-web/_pods/components/table/cell/action-btns/template.hbs"
    }
  });

  _exports.default = _default;
});