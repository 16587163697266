define("formaviva-web/_pods/claim/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    pageTitle: "Claim Profile | Formaviva"
  });

  _exports.default = _default;
});