define("formaviva-web/_pods/components/player/footer-player-area/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "jWunpkqV",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[12,\"class\",[30,[\"footer-player-area \",[29,\"unless\",[[25,[\"persistentPlayerState\",\"showMasterPlayer\"]],\"invisible\"],null]]]],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"container\"],[9],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"row\"],[9],[0,\"\\n      \"],[7,\"div\"],[11,\"class\",\"col-md-12\"],[9],[0,\"\\n        \"],[7,\"div\"],[11,\"class\",\"player-master clearfix\"],[9],[0,\"\\n          \"],[1,[29,\"player/player-master\",null,[[\"track\",\"renderInto\"],[[25,[\"persistentPlayerState\",\"currentTrack\"]],\"master_player\"]]],false],[0,\"\\n        \"],[10],[0,\"\\n      \"],[10],[0,\"\\n    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "formaviva-web/_pods/components/player/footer-player-area/template.hbs"
    }
  });

  _exports.default = _default;
});