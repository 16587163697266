define("formaviva-web/_pods/feed/following/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    pageTitle: "Following | Formaviva",
    queryParams: {
      page: {
        refreshModel: true
      }
    },
    model: function model(_ref) {
      var _ref$page = _ref.page,
          page = _ref$page === void 0 ? 1 : _ref$page;
      return this.store.query("profile", {
        page: page,
        per_page: 24,
        public: true,
        filter: {
          followed: true
        }
      });
    }
  });

  _exports.default = _default;
});