define("formaviva-web/_pods/track/show/controller", ["exports", "formaviva-web/mixins/can-edit", "formaviva-web/mixins/track-download"], function (_exports, _canEdit, _trackDownload) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_trackDownload.default, _canEdit.default, {
    shoppingCart: Ember.inject.service("checkout/shoppingCart"),
    trackActions: Ember.inject.service("track/track-actions"),
    downloadOptions: Object.freeze(["mp3", "wav", "flac", "aiff"]),
    currentSession: Ember.inject.service(),
    track: Ember.computed.alias("model"),
    profile: Ember.computed.alias("track.profile"),
    release: Ember.computed.alias("track.release"),
    mixes: Ember.computed.alias("track.mixes"),
    artists: Ember.computed.alias("track.artists"),
    actions: {
      addToCart: function addToCart(track, release) {
        if (track.mix) this.shoppingCart.addMixToCart(track);else this.shoppingCart.addTrackToCart(track, release);
      }
    }
  });

  _exports.default = _default;
});