define("formaviva-web/utils/scroll-lock", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var $body = document.querySelector("body");
  var scrollPosition = 0;
  var _default = {
    enable: function enable() {
      scrollPosition = window.pageYOffset;
      $body.style.overflow = "hidden";
      $body.style.position = "fixed";
      $body.style.top = "-".concat(scrollPosition, "px");
      $body.style.width = "100%";
    },
    disable: function disable() {
      $body.style.removeProperty("overflow");
      $body.style.removeProperty("position");
      $body.style.removeProperty("top");
      $body.style.removeProperty("width");
      window.scrollTo(0, scrollPosition);
    }
  };
  _exports.default = _default;
});