define("formaviva-web/_pods/components/merch/merch-form/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    currentSession: Ember.inject.service(),
    actions: {
      changeMerchType: function changeMerchType(type) {
        Ember.set(this.merch, "merchType", type);
        Ember.set(this.merch, "weight", Ember.get(type, "defaultWeight"));
      }
    }
  });

  _exports.default = _default;
});