define("formaviva-web/_pods/components/track/mix-form-inputs/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    setSlug: function setSlug(track) {
      if (!track.get("isNew")) {
        return;
      }

      if (Ember.isBlank(track.get("name"))) {
        return;
      }

      var name = track.get("name"); // retain only alphanumeric characters and join them by '-'

      var slug = name.toLowerCase().replace(/[^a-zA-Z0-9\s+]/g, " ").trim().replace(/\s+/g, "-");
      track.set("slug", slug);
    },
    actions: {
      setTags: function setTags(value) {
        this.set("track.tags", value);
      },
      setPublishDate: function setPublishDate(dropdown, options) {
        dropdown.actions.close();
        var date = options.date;
        var utcDate = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()));
        this.set("track.publishedAt", utcDate);
      },
      setName: function setName(value) {
        this.set("track.name", value.trim());
        this.setSlug(this.track);
      },
      resetSlugIfEmpty: function resetSlugIfEmpty() {
        if (Ember.isBlank(this.get("track.slug"))) this.setSlug();
      }
    }
  });

  _exports.default = _default;
});