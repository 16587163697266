define("formaviva-web/_pods/analytics/sale/edit/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model: function model(_model, transition) {
      var itemId = transition.params["analytics.sale.edit"].item_id;
      return this.modelFor("analytics.sale").get("purchaseItems").findBy("id", itemId);
    },
    resetController: function resetController(controller) {
      controller.setProperties({
        newStatus: null
      });
      controller.get("saleItem").rollbackAttributes();
    }
  });

  _exports.default = _default;
});