define("formaviva-web/_pods/feed/following/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Q0s3naG2",
    "block": "{\"symbols\":[\"profile\"],\"statements\":[[7,\"div\"],[11,\"class\",\"feed-body feed-body--tracks\"],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"feed-content\"],[9],[0,\"\\n\"],[4,\"if\",[[25,[\"profiles\",\"length\"]]],null,{\"statements\":[[0,\"      \"],[7,\"div\"],[11,\"class\",\"feed-content__title pb-8\"],[9],[0,\"\\n        \"],[7,\"span\"],[11,\"class\",\"collection-title\"],[9],[0,\"Following Labels\"],[10],[0,\"\\n\"],[4,\"link-to\",[\"labels\"],[[\"class\"],[\"pull-right\"]],{\"statements\":[[0,\"          See more \"],[1,[29,\"fa-icon\",[\"angle-right\"],null],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"      \"],[10],[0,\"\\n      \"],[1,[29,\"collection-pagination\",null,[[\"collection\",\"path\",\"currentPage\"],[[25,[\"profiles\"]],\"feed.following\",[25,[\"page\"]]]]],false],[0,\"\\n      \"],[7,\"div\"],[11,\"class\",\"fv-box-list\"],[9],[0,\"\\n\"],[4,\"each\",[[25,[\"profiles\"]]],null,{\"statements\":[[0,\"          \"],[1,[29,\"profile/profile-box\",null,[[\"profile\",\"isCurrentProfile\",\"class\"],[[24,1,[]],[29,\"eq\",[[24,1,[\"id\"]],[25,[\"currentSession\",\"profile\",\"id\"]]],null],\"fv-box-list__item\"]]],false],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"      \"],[10],[0,\"\\n      \"],[1,[29,\"collection-pagination\",null,[[\"collection\",\"path\",\"currentPage\"],[[25,[\"profiles\"]],\"feed.following\",[25,[\"page\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"      \"],[7,\"div\"],[11,\"class\",\"offset-top\"],[9],[0,\"\\n        \"],[7,\"span\"],[9],[0,\"Explore and enjoy music by following to some interesting\\n          labels from \"],[4,\"link-to\",[\"labels\"],null,{\"statements\":[[0,\"here.\"]],\"parameters\":[]},null],[0,\"\\n        \"],[10],[0,\"\\n      \"],[10],[0,\"\\n\"]],\"parameters\":[]}],[0,\"  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "formaviva-web/_pods/feed/following/template.hbs"
    }
  });

  _exports.default = _default;
});