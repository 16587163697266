define("formaviva-web/_pods/components/dashboard/dashboard-track-list/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    router: Ember.inject.service(),
    sort: "created_at",
    dir: "desc",
    columns: Ember.computed(function () {
      var _this = this;

      return [{
        label: "",
        valuePath: "adjustedImageUrl",
        sortable: false,
        align: "center",
        width: "58px",
        isCircle: true,
        cellComponent: "table/cell/image"
      }, {
        label: "Title",
        valuePath: "name",
        sortable: true,
        cellComponent: "table/cell/truncate-with-tooltip"
      }, {
        label: "Artist(s)",
        linkValuePath: "artists",
        valuePath: "artistNames",
        sortable: true,
        width: "25%",
        breakpoints: ["tablet", "desktop"],
        cellComponent: "table/cell/simple-link",
        getLink: function getLink(artist) {
          if (artist.approved) {
            return {
              label: artist.name,
              link: _this.router.urlFor("profile.index", artist.slug)
            };
          } else {
            return {
              label: artist.name
            };
          }
        }
      }, {
        label: "Length",
        valuePath: "length",
        sortable: true,
        width: "100px",
        breakpoints: ["tablet", "desktop"],
        cellComponent: "table/cell/duration"
      }, {
        label: "Uploaded",
        valuePath: "createdAt",
        sortable: true,
        width: "130px",
        fromNow: true,
        cellComponent: "table/cell/date"
      }, {
        label: "Private",
        sortable: false,
        width: "52px",
        customClass: "btn btn-variant-1 btn-table-action",
        cellComponent: "table/cell/icon-text",
        getIcon: function getIcon(track) {
          return {
            icon: track.private ? "lock" : ""
          };
        }
      }, {
        label: "Edit",
        sortable: false,
        width: "52px",
        customClass: "btn btn-variant-1 btn-table-action",
        cellComponent: "table/cell/simple-link",
        getLink: function getLink(track) {
          return {
            icon: "pencil",
            link: _this.router.urlFor("track.edit", track.profileSlug, track.slug)
          };
        }
      }, {
        label: "Processing",
        sortable: false,
        width: "75px",
        customClass: "btn btn-variant-1 btn-table-action",
        cellComponent: "table/cell/icon-text",
        getIcon: function getIcon(track) {
          return {
            icon: track.processed ? "" : "hourglass-half"
          };
        }
      }];
    }),
    actions: {
      onRowClick: function onRowClick(_ref) {
        var content = _ref.content;
        this.router.transitionTo("track.show", content.profileSlug, content.slug);
      }
    }
  });

  _exports.default = _default;
});