define("formaviva-web/_pods/purchases/route", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin"], function (_exports, _authenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    pageTitle: "Purchases | Formaviva",
    queryParams: {
      page: {
        refreshModel: true
      },
      sort: {
        refreshModel: true
      }
    },
    model: function model(_ref) {
      var _ref$page = _ref.page,
          page = _ref$page === void 0 ? 1 : _ref$page,
          _ref$sort = _ref.sort,
          sort = _ref$sort === void 0 ? "-created_at" : _ref$sort;
      return this.store.query("purchase", {
        page: page,
        sort: sort
      });
    },
    actions: {
      invalidateModel: function invalidateModel() {
        this.refresh();
      }
    }
  });

  _exports.default = _default;
});