define("formaviva-web/_pods/components/shipping/with-sorted-weighted-prices/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var normalizeWeight = function normalizeWeight(w) {
    return Ember.isBlank(w) ? Infinity : parseFloat(w);
  };

  var getNormalizeWeight = function getNormalizeWeight(price) {
    return normalizeWeight(Ember.get(price, "minWeight"));
  };

  var comparePrices = function comparePrices(a, b) {
    return getNormalizeWeight(a) - getNormalizeWeight(b);
  };

  var _default = Ember.Component.extend({
    tagName: "",
    sortedPrices: Ember.computed("prices.{@each.minWeight,content}", function () {
      return this.prices.content.sort(comparePrices);
    })
  });

  _exports.default = _default;
});