define("formaviva-web/_pods/analytics/route", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin"], function (_exports, _authenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    hasCustomAsyncTitle: true,
    beforeModel: function beforeModel(transition) {
      this._super.apply(this, arguments);

      if (transition.targetName === "analytics.index") {
        this.transitionTo("analytics.play-sessions");
      }
    }
  });

  _exports.default = _default;
});