define("formaviva-web/_pods/track/edit/route", ["exports", "formaviva-web/mixins/unsaved-record-notification", "ember-simple-auth/mixins/authenticated-route-mixin"], function (_exports, _unsavedRecordNotification, _authenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, _unsavedRecordNotification.default, {
    pageTitle: Ember.computed("routeName", function () {
      var recordName = this.modelFor(this.routeName).get("mix") ? "Mix" : "Track";
      return "Edit ".concat(recordName, " | Formaviva");
    }),
    resetController: function resetController(controller) {
      this._super.apply(this, arguments);

      controller.set("disableSave", false);
    }
  });

  _exports.default = _default;
});