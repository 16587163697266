define("formaviva-web/_pods/components/profile/profile-info/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "1f1lJB1y",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"col-xs-5\"],[9],[0,\"\\n\"],[4,\"link-to\",[\"profile.index\",[25,[\"profile\",\"slug\"]]],null,{\"statements\":[[0,\"    \"],[1,[29,\"fv-profile-image\",null,[[\"image\",\"defaultName\",\"backgroundColor\",\"alt\",\"title\",\"textColor\",\"class\"],[[25,[\"profile\",\"adjustedImageUrl\"]],[25,[\"profile\",\"name\"]],\"#141414\",\"\",\"\",\"white\",\"profile__cover-image\"]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[10],[0,\"\\n\"],[7,\"div\"],[11,\"class\",\"col-xs-7 col-release-header-label\"],[9],[0,\"\\n\"],[4,\"link-to\",[\"profile.index\",[25,[\"profile\",\"slug\"]]],null,{\"statements\":[[0,\"    \"],[7,\"h1\"],[11,\"class\",\"no-text-transform\"],[9],[1,[25,[\"profile\",\"name\"]],false],[10],[0,\"\\n\"]],\"parameters\":[]},null],[4,\"if\",[[29,\"not-eq\",[[25,[\"profile\",\"id\"]],[25,[\"currentProfile\",\"id\"]]],null]],null,{\"statements\":[[0,\"    \"],[1,[29,\"profile/follow-profile-button\",null,[[\"class\",\"profile\"],[\"profile__action pt-2\",[25,[\"profile\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "formaviva-web/_pods/components/profile/profile-info/template.hbs"
    }
  });

  _exports.default = _default;
});