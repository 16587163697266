define("formaviva-web/_pods/components/table/cell/index/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "EnJXaex4",
    "block": "{\"symbols\":[\"@row\"],\"statements\":[[7,\"span\"],[11,\"class\",\"table-row-index\"],[9],[0,\"\\n  \"],[1,[24,1,[\"index\"]],false],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "formaviva-web/_pods/components/table/cell/index/template.hbs"
    }
  });

  _exports.default = _default;
});