define("formaviva-web/_pods/components/release/release-main-track/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    persistentPlayerState: Ember.inject.service(),
    currentTrack: Ember.computed("persistentPlayerState.currentTrack.id", "tracks.[]", function () {
      return this.tracks && this.tracks.findBy("id", this.persistentPlayerState.currentTrack.id) || this.tracks.firstObject;
    })
  });

  _exports.default = _default;
});