define("formaviva-web/_pods/components/analytics/play-session-heatmap/component", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function round(x) {
    return Math.round(x * 100) / 100;
  }

  var _default = Ember.Component.extend({
    fastboot: Ember.inject.service(),
    isFastBoot: Ember.computed.reads("fastboot.isFastBoot"),
    didInsertElement: function didInsertElement() {
      if (this.isFastBoot) {
        return;
      }

      var ctx = document.createElement("canvas").getContext("2d");
      var linGradWave = ctx.createLinearGradient(0, 64, 0, 200);
      linGradWave.addColorStop(0.5, "#00DAB8");
      linGradWave.addColorStop(0.5, "#23675B");
      var linGradProgress = ctx.createLinearGradient(0, 64, 0, 200);
      linGradProgress.addColorStop(0.5, "#00ab90");
      linGradProgress.addColorStop(0.5, "#1b4f46");
      var wavesurfer = window.WaveSurfer.create({
        container: this.element.querySelector(".heat-map-container"),
        waveColor: linGradWave,
        progressColor: linGradProgress,
        backend: "MediaElement",
        hideScrollbar: true,
        forceDecode: false,
        barWidth: 2,
        height: this.height || 56,
        pixelRatio: 2
      });
      var peaks = this.get("track.peaks"); // Show dots in silent parts

      var adjustedPeaks = peaks.map(function (p) {
        return p <= 0.01 ? 0.01 : p;
      });
      var trackUrl = "";
      wavesurfer.load(trackUrl, adjustedPeaks, "none");
      this.set("player", wavesurfer); // Draw peaks immediately, without waiting for the first byte range to load

      wavesurfer.drawBuffer();
      this.drawSegments();
    },
    // probably would be better to accomplish this with canvas draw?
    drawSegments: function drawSegments() {
      var container = this.element.querySelector("wave");
      var segmentsEl = (0, _jquery.default)('<div class="segments">');
      var size = this.get("playSession.segments").length,
          width = round(100.0 / size); // optimize segments so we don't render 1 element per segment
      // transform [1, 0, 1, 1, 0, 1, 1, 1]
      // to [ [1, 1], [0, 1], [1, 2], [0, 1], [1, 3] ]

      var optimizedSegments = this.get("playSession.segments").reduce(function (total, amount) {
        if (total.length > 0 && amount == total[total.length - 1][0]) {
          total[total.length - 1][1]++;
        } else {
          total.push([amount, 1]);
        }

        return total;
      }, []);
      var x = 0;
      optimizedSegments.forEach(function (segment) {
        var w = round(width * segment[1]),
            classname = segment[0] == 1 ? " segment-played" : "";
        segment = "";
        segmentsEl.append('<div class="segment' + classname + '" style="left: ' + x + "%; width: " + w + '%">' + segment + "</div>");
        x = round(x + w);
      });
      (0, _jquery.default)(container).append(segmentsEl);
    }
  });

  _exports.default = _default;
});