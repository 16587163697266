define("formaviva-web/_pods/checkout/payment/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "oJ54Zqfd",
    "block": "{\"symbols\":[],\"statements\":[[1,[29,\"sub-menu\",null,[[\"isBreadcrumb\",\"tabs\"],[true,[29,\"array\",[[29,\"hash\",null,[[\"name\",\"route\"],[\"Billing Info\",\"checkout.info\"]]],[29,\"hash\",null,[[\"name\"],[\"Payment Method\"]]]],null]]]],false],[0,\"\\n\"],[7,\"div\"],[11,\"class\",\"row\"],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"col-md-12\"],[9],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"fv-section-header clearfix\"],[9],[0,\"\\n      \"],[7,\"div\"],[11,\"class\",\"pull-left\"],[9],[0,\"\\n        \"],[7,\"div\"],[11,\"class\",\"fv-section-header-title\"],[9],[0,\"\\n          Checkout\\n        \"],[10],[0,\"\\n      \"],[10],[0,\"\\n    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\\n\"],[1,[29,\"billing/payment-form\",null,[[\"paymentToken\",\"onPaymentSuccess\"],[[25,[\"model\",\"paymentToken\",\"token\"]],[29,\"route-action\",[\"onPaymentSuccess\"],null]]]],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "formaviva-web/_pods/checkout/payment/template.hbs"
    }
  });

  _exports.default = _default;
});