define("formaviva-web/models/release", ["exports", "ember-data", "formaviva-web/config/environment", "ember-awesome-macros"], function (_exports, _emberData, _environment, _emberAwesomeMacros) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Model = _emberData.default.Model;
  var belongsTo = _emberData.default.belongsTo;
  var hasMany = _emberData.default.hasMany;
  var attr = _emberData.default.attr;

  var _default = Model.extend({
    recordType: "release",
    name: attr("string"),
    description: attr("string"),
    slug: attr("string"),
    price: attr("number"),
    releaseDate: attr("date"),
    publishedAt: attr("date"),
    pricePerTrack: attr("number"),
    allowPurchase: attr("boolean"),
    allowIndividualTracksPurchase: attr("boolean"),
    allowToPayMore: attr("boolean"),
    allowToPayMoreForIndividualTracks: attr("boolean"),
    imageUploadId: attr("string"),
    releasedAt: attr("date"),
    releaseType: attr("string"),
    imageUrl: attr("string"),
    status: attr("string"),
    private: attr("boolean", {
      defaultValue: false
    }),
    privateToken: attr("string"),
    tracks: hasMany("track", {
      async: false
    }),
    tags: hasMany("tag"),
    artists: hasMany("profile"),
    // This prop is used to show out artist names in index pages. Rather
    // than iterating through every artist to retrieve, the backend responds
    // this attribute directly in the required format. The backend responds artistNames
    // in the expected way for both the `custom` and `pull from track` options
    artistNames: attr("string"),
    merches: hasMany("merch"),
    profile: belongsTo("profile"),
    isPrelaunch: Ember.computed("releaseDate", "status", function () {
      return this.get("status") == "prelaunch" && Date.parse(this.get("releaseDate")) > Date.now();
    }),
    adjustedImageUrl: Ember.computed("imageUrl", function () {
      var imageUrl = this.imageUrl;

      if (imageUrl && imageUrl[0] === "/") {
        return "".concat(_environment.default.serverURL, "/").concat(imageUrl);
      } else {
        return imageUrl;
      }
    }),
    canonicalUrl: Ember.computed("profile.slug", "slug", function () {
      return "".concat(_environment.default.webURL, "/").concat(this.get("profile.slug"), "/").concat(this.get("slug"));
    }),
    trackArtistsAllSame: Ember.computed("tracks", function () {
      return this.tracks.mapBy("originalArtistNames").uniq().length === 1;
    }),
    profileSlug: Ember.computed.alias("profile.slug"),
    purchaseItemsCount: (0, _emberAwesomeMacros.conditional)("allowPurchase", (0, _emberAwesomeMacros.add)("merches.length", 1), "merches.length"),
    purchased: attr("boolean"),
    purchaseItem: belongsTo("purchase-item")
  });

  _exports.default = _default;
});