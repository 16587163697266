define("formaviva-web/_pods/profile/following/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    currentSession: Ember.inject.service(),
    init: function init() {
      this._super.apply(this, arguments);

      var filters = ["type"];
      this.set("availableFilters", filters);
      var filterOptions = {
        type: {
          options: [{
            label: "All",
            field: "all"
          }, {
            label: "Labels",
            field: "label"
          }, {
            label: "Artists",
            field: "artist"
          }, {
            label: "Fans",
            field: "listener"
          }],
          selectedOptionName: "filterByType",
          defaultOption: {
            label: "All",
            field: "all"
          }
        }
      };
      this.set("availableFilterOptions", filterOptions);
    }
  });

  _exports.default = _default;
});