define("formaviva-web/utils/transform-object-keys", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    decamelizeKeys: function decamelizeKeys(obj) {
      var decamelizedObj = {};
      Object.keys(obj).map(function (key) {
        var newKey = Ember.String.underscore(Ember.String.decamelize(key));
        decamelizedObj[newKey] = obj[key];
      });
      return decamelizedObj;
    },
    camelizeKeys: function camelizeKeys(obj) {
      var camelizedObj = {};
      Object.keys(obj).map(function (key) {
        var newKey = Ember.String.camelize(key);
        camelizedObj[newKey] = obj[key];
      });
      return camelizedObj;
    }
  };
  _exports.default = _default;
});