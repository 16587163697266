define("formaviva-web/initializers/inject-behaviour-tracker", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.initialize = initialize;
  _exports.default = void 0;

  function initialize(application) {
    application.inject("route", "userBehaviourTracker", "service:behaviour-tracking/tracker");
  }

  var _default = {
    name: "inject-behaviour-tracker",
    initialize: initialize
  };
  _exports.default = _default;
});