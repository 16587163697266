define("formaviva-web/_pods/components/nav-bar/component", ["exports", "formaviva-web/utils/mobile"], function (_exports, _mobile) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    shoppingCart: Ember.inject.service("checkout/shoppingCart"),
    router: Ember.inject.service(),
    tagName: "",
    collapsed: true,
    isMobileMode: Ember.computed.not("collapsed"),
    showSearch: false,
    actions: {
      toggleNavbar: function toggleNavbar() {
        this.set("collapsed", true);
      },
      hideSearchAndReturnToPreviousRoute: function hideSearchAndReturnToPreviousRoute() {
        this.toggleProperty("showSearch");

        if (window.history.length) {
          window.history.back();
        } else {
          this.router.transitionTo("index");
        }
      },
      openInApp: function openInApp() {
        var packageName = "com.shadow.formaviva";
        var androidURL = "https://play.google.com/store/apps/details?id=".concat(packageName);
        var appleURL = "https://apps.apple.com/us/app/".concat(packageName, "/1635315911");

        if ((0, _mobile.isIOS)()) {
          window.location.replace(appleURL);
        } else if ((0, _mobile.isAndroid)()) {
          window.location.replace(androidURL);
        } else {
          return;
        }
      }
    }
  });

  _exports.default = _default;
});