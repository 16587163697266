define("formaviva-web/_pods/components/release/release-widget/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    tracks: Ember.computed("release", "filteredTracks", function () {
      if (Ember.isPresent(this.filteredTracks)) {
        return this.filteredTracks;
      }

      var release = this.release;

      if (!release) {
        return [];
      } // lookup whether the data in relationship is loaded
      // and decide on whether the API is to be made to load
      // the data if not present


      if (release.hasMany("tracks").value()) {
        return release.tracks;
      } else {
        return this.store.query("track", {
          public: true,
          sort: "release_order",
          filter: {
            release_ids: release.id
          }
        });
      }
    }),
    artistsToDisplay: Ember.computed("release.artists", "tracks", function () {
      if (Ember.isPresent(this.get("release.artists"))) {
        return this.get("release.artists");
      } else {
        var trackArtists = [];
        var tracks = this.tracks;
        if (this.tracks) tracks.forEach(function (track) {
          var individualTrackArtists = track.get("artists");
          individualTrackArtists.forEach(function (artist) {
            trackArtists.push(artist);
          });
        });
        return trackArtists.uniq();
      }
    })
  });

  _exports.default = _default;
});