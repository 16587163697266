define("formaviva-web/_pods/profile/index/route", ["exports", "formaviva-web/mixins/profile-data"], function (_exports, _profileData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_profileData.default, {
    renderTemplate: function renderTemplate(controller, model) {
      var profile = model.profile;

      if (profile.isListener) {
        this.render("profile.likes", {
          into: "profile",
          outlet: "profile-content",
          model: model,
          controller: controller
        });
      } else if (profile.associatedReleasesCount == 0) {
        this.render("profile.mixes", {
          into: "profile",
          outlet: "profile-content",
          model: model,
          controller: controller
        });
      } else {
        this.render("profile.releases", {
          into: "profile",
          outlet: "profile-content",
          model: model,
          controller: controller
        });
      }
    },
    headData: Ember.inject.service(),
    hasCustomAsyncTitle: true,
    afterModel: function afterModel(model) {
      var profile = model.profile;
      var title = "".concat(profile.name, " | Formaviva");
      this.set("headData.title", title);
      this.set("headData.description", profile.bio);
      this.set("headData.imageUrl", profile.adjustedImageUrl);
      this.set("headData.canonicalUrl", profile.canonicalUrl);
    },
    setupController: function setupController(controller, model) {
      var profile = model.profile;
      controller.set("isLoading", true);

      if (profile.isListener) {
        if (profile.likesCount > 0) {
          this.fetchLikedTracks(model.profile.slug, model.page, model.sort, model.filterByType).then(function (tracks) {
            controller.set("tracks", tracks);
            controller.set("page", model.page);
          }).finally(function () {
            return controller.set("isLoading", false);
          });
        } else {
          controller.set("isLoading", false);
        }
      } else if (profile.associatedReleasesCount == 0) {
        if (profile.associatedMixesCount > 0) {
          this.fetchMixes(model.profile.slug, model.page).then(function (mixes) {
            controller.set("mixes", mixes);
            controller.set("page", model.page);
          }).finally(function () {
            controller.set("isLoading", false);
          });
        } else {
          controller.set("isLoading", false);
        }
      } else {
        if (profile.associatedReleasesCount > 0) {
          this.fetchReleases(model.profile.slug, model.page).then(function (releases) {
            controller.set("releases", releases);
            controller.set("page", model.page);
          }).finally(function () {
            controller.set("isLoading", false);
          });
        } else {
          controller.set("isLoading", false);
        }
      }
    }
  });

  _exports.default = _default;
});