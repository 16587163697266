define("formaviva-web/_pods/feed/explore/controller", ["exports", "formaviva-web/constants/common"], function (_exports, _common) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    store: Ember.inject.service(),
    currentSession: Ember.inject.service(),
    currentProfile: Ember.computed.alias("currentSession.profile"),
    releases: Ember.computed.alias("model"),
    FEED_VISIBLE_TRACKS_COUNT: _common.FEED_VISIBLE_TRACKS_COUNT
  });

  _exports.default = _default;
});