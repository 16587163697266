define("formaviva-web/_pods/components/shipping/shipping-zone/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "PWPhBoVs",
    "block": "{\"symbols\":[\"countryCode\"],\"statements\":[[7,\"div\"],[11,\"class\",\"col-md-10 col-sm-12\"],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"shipping-zone__title\"],[9],[0,\"\\n\"],[4,\"each\",[[25,[\"zone\",\"to\"]]],null,{\"statements\":[[0,\"      \"],[1,[29,\"country-with-flag\",null,[[\"countryCode\"],[[24,1,[]]]]],false],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"  \"],[10],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"shipping-zone__prices\"],[9],[0,\"\\n    \"],[1,[29,\"shipping/zone-price-details\",null,[[\"zone\"],[[25,[\"zone\"]]]]],false],[0,\"\\n  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\"],[7,\"div\"],[11,\"class\",\"col-md-2 col-sm-12\"],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"pull-right\"],[9],[0,\"\\n    \"],[7,\"a\"],[12,\"onClick\",[29,\"action\",[[24,0,[]],\"edit\"],null]],[11,\"class\",\"cursor-pointer\"],[9],[0,\"\\n      Edit\\n    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "formaviva-web/_pods/components/shipping/shipping-zone/template.hbs"
    }
  });

  _exports.default = _default;
});