define("formaviva-web/_pods/components/shopping-cart/cart/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    shoppingCart: Ember.inject.service("checkout/shoppingCart"),
    items: Ember.computed.alias("shoppingCart.cartItems"),
    router: Ember.inject.service(),
    countries: Ember.inject.service(),
    checkoutData: Ember.inject.service("checkout/checkoutData"),
    actions: {
      removeItem: function removeItem(item) {
        this.get("shoppingCart").removeItem(item);
      },
      goToCheckout: function goToCheckout() {
        this.set("shoppingCart.isOpened", false);
        this.get("router").transitionTo("checkout.info");
      },
      changeCountry: function changeCountry(countryCode) {
        this.set("checkoutData.shippingData.countryCode", countryCode);
        this.get("checkoutData").persist();
        this.get("shoppingCart.recalculatePaymentAmounts").perform();
      },
      refreshAmounts: function refreshAmounts() {
        this.get("shoppingCart.recalculatePaymentAmounts").perform();
      }
    }
  });

  _exports.default = _default;
});