define("formaviva-web/_pods/mixes/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Gii2L5kA",
    "block": "{\"symbols\":[\"mix\"],\"statements\":[[7,\"div\"],[11,\"class\",\"row pb-4\"],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"col-xs-6\"],[9],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"fv-breadcrumbs\"],[9],[0,\"\\n      \"],[4,\"link-to\",[\"mixes\"],null,{\"statements\":[[0,\"Mixes\"]],\"parameters\":[]},null],[0,\"\\n    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"col-xs-6\"],[9],[0,\"\\n    \"],[1,[29,\"sortable-select\",null,[[\"class\",\"sortOptions\",\"selectedSortOption\"],[\"sort-labels\",[25,[\"availableSortOptions\"]],[25,[\"selectedSortOption\"]]]]],false],[0,\"\\n  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\"],[1,[29,\"collection-pagination\",null,[[\"collection\",\"path\",\"currentPage\"],[[25,[\"model\"]],\"mixes\",[25,[\"page\"]]]]],false],[0,\"\\n\"],[7,\"div\"],[11,\"class\",\"fv-box-list\"],[9],[0,\"\\n\"],[4,\"each\",[[25,[\"model\"]]],null,{\"statements\":[[0,\"    \"],[1,[29,\"fv-box\",null,[[\"track\",\"hasActions\",\"truncateTitle\",\"class\"],[[24,1,[]],true,true,\"fv-box-list__item\"]]],false],[0,\"\\n\"]],\"parameters\":[1]},{\"statements\":[[0,\"    \"],[7,\"div\"],[11,\"class\",\"fv-box-list__placeholder\"],[9],[0,\"There are no mixes available right now.\"],[10],[0,\"\\n\"]],\"parameters\":[]}],[10],[0,\"\\n\"],[1,[29,\"collection-pagination\",null,[[\"collection\",\"path\",\"currentPage\"],[[25,[\"model\"]],\"mixes\",[25,[\"page\"]]]]],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "formaviva-web/_pods/mixes/template.hbs"
    }
  });

  _exports.default = _default;
});