define("formaviva-web/_pods/analytics/play-sessions/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    playSessions: Ember.computed.alias("model"),
    queryParams: ["track_id", "page"],
    track_id: null,
    page: 1,
    selectedTrack: Ember.computed("track_id", "allProfileTracks.[]", function () {
      return this.get("allProfileTracks").findBy("id", this.get("track_id"));
    }),
    actions: {
      setSelectedTrack: function setSelectedTrack(track) {
        this.setProperties({
          track_id: track ? track.id : null,
          page: 1
        }); // Setting these properties Will refresh the model.
      }
    }
  });

  _exports.default = _default;
});