define("formaviva-web/_pods/components/track/track-like/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: "",
    trackActions: Ember.inject.service("track/track-actions"),
    actions: {
      likeTrack: function likeTrack(track) {
        this.get("trackActions").likeTrack(track);
      }
    }
  });

  _exports.default = _default;
});