define("formaviva-web/_pods/components/release/release-header/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "hvGxdNFo",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"release-title-bar\"],[9],[0,\"\\n  \"],[1,[29,\"release/release-title\",null,[[\"class\",\"release\",\"artistsToDisplay\",\"isPrelaunchLocked\",\"shouldLinkToRelease\"],[\"visible-xs\",[25,[\"release\"]],[25,[\"artistsToDisplay\"]],[25,[\"isPrelaunchLocked\"]],[25,[\"shouldLinkToRelease\"]]]]],false],[0,\"\\n  \"],[1,[29,\"release/release-title\",null,[[\"class\",\"release\",\"isPrelaunchLocked\",\"artistsToDisplay\",\"shouldLinkToRelease\"],[\"hidden-xs\",[25,[\"release\"]],[25,[\"isPrelaunchLocked\"]],[25,[\"artistsToDisplay\"]],[25,[\"shouldLinkToRelease\"]]]]],false],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "formaviva-web/_pods/components/release/release-header/template.hbs"
    }
  });

  _exports.default = _default;
});