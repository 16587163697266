define("formaviva-web/_pods/components/purchase/buyer-shipping-data/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Qi3HII4k",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"shipping-details\"],[9],[0,\"\\n  \"],[7,\"p\"],[11,\"class\",\"shipping-details__title\"],[9],[0,\"Shipping details\"],[10],[0,\"\\n\\n  \"],[7,\"div\"],[11,\"class\",\"offset-top\"],[9],[0,\"\\n    \"],[7,\"p\"],[9],[0,\"\\n      \"],[1,[25,[\"data\",\"first_name\"]],false],[0,\" \"],[1,[25,[\"data\",\"last_name\"]],false],[0,\"\\n    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n\\n  \"],[7,\"div\"],[11,\"class\",\"offset-bottom\"],[9],[0,\"\\n    \"],[7,\"p\"],[9],[0,\"\\n      \"],[1,[25,[\"data\",\"address\"]],false],[0,\"\\n    \"],[10],[0,\"\\n    \"],[7,\"p\"],[9],[0,\"\\n      \"],[1,[25,[\"data\",\"post_number\"]],false],[0,\", \"],[1,[25,[\"data\",\"city\"]],false],[0,\"\\n    \"],[10],[0,\"\\n    \"],[7,\"p\"],[9],[0,\"\\n\"],[4,\"if\",[[25,[\"data\",\"state\"]]],null,{\"statements\":[[0,\"        \"],[1,[29,\"concat\",[[25,[\"data\",\"state\"]],\", \"],null],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"      \"],[1,[29,\"country-code-to-description\",[[25,[\"data\",\"country_code\"]]],null],false],[0,\"\\n    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n  \"],[7,\"p\"],[9],[0,\"\\n    \"],[1,[25,[\"data\",\"phone_number\"]],false],[0,\"\\n  \"],[10],[0,\"\\n  \"],[7,\"p\"],[9],[0,\"\\n    \"],[1,[25,[\"data\",\"email\"]],false],[0,\"\\n  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "formaviva-web/_pods/components/purchase/buyer-shipping-data/template.hbs"
    }
  });

  _exports.default = _default;
});