define("formaviva-web/_pods/components/user/user-signup/component", ["exports", "formaviva-web/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var isTest = _environment.default.environment === "test"; // TODO: MERGE THIS LOGIC WITH SIGNUP.JS

  var _default = Ember.Component.extend({
    tagName: "",
    store: Ember.inject.service(),
    session: Ember.inject.service(),
    router: Ember.inject.service(),
    appWideModals: Ember.inject.service(),
    metrics: Ember.inject.service(),
    appState: Ember.inject.service(),
    mixpanelTracking: Ember.inject.service("tracking/mixpanel"),
    userBehaviourTracker: Ember.inject.service("behaviour-tracking/tracker"),
    isDisabled: Ember.computed(function () {
      return !isTest;
    }),
    init: function init() {
      this._super.apply(this, arguments);

      var user = this.store.createRecord("user", {
        profileType: "listener"
      });
      this.set("user", user);
    },
    actions: {
      setUsername: function setUsername(user) {
        if (Ember.isBlank(user.get("name"))) {
          return;
        }

        var name = user.get("name"); // retain only alphanumeric characters and join them by '-'

        var username = name.toLowerCase().replace(/[^a-zA-Z0-9\s+]/g, " ").trim().replace(/\s+/g, "-");
        user.set("username", username);
      },
      goToApply: function goToApply() {
        this.get("appWideModals").toggleLoginModal(false);
        this.get("router").transitionTo("apply");
      },
      signup: function signup(e) {
        var _this = this;

        e.preventDefault();
        this.appState.setIsImpersonatedSession(false);
        var user = this.get("user");
        this.set("isSigningUp", true);
        user.set("fv_user_behaviour_initial_distinct_id", this.userBehaviourTracker.getInitialDistinctId());
        user.set("recaptcha", this.controller.get("recaptcha"));
        user.save().then(function () {
          // this.get("mixpanelTracking").identifyAndSetup(user);
          if (window.posthog !== undefined) {
            // eslint-disable-next-line no-undef
            posthog.identify(user.id, {
              email: user.email
            });
          }

          _this.get("session").authenticate("authenticator:oauth2", user.get("email"), user.get("password")).then(function () {
            _this.get("notifications").success("Welcome onboard, ".concat(user.get("name"), "!"), {
              autoClear: true,
              clearDuration: isTest ? 10 : 10000
            });

            _this.get("userDidSignup")(user);
          });
        }).finally(function () {
          return _this.set("isSigningUp", false);
        });
      }
    }
  });

  _exports.default = _default;
});