define("formaviva-web/_pods/izvir/route", ["exports", "formaviva-web/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    metrics: Ember.inject.service(),
    headData: Ember.inject.service(),
    model: function model() {
      return this.store.query("merch", {
        public: true,
        filter: {
          profile_slug: "2390",
          slug: "izvir"
        }
      }).then(function (t) {
        return t.get("firstObject");
      });
    },
    afterModel: function afterModel(model) {
      this.set("headData.title", "Izvir");
      this.set("headData.description", "Izvir is where the Formaviva story began. On our birth grounds, we'll celebrate its 6th anniversary in an intimate family circle with guests and friends from all over the world.");
      this.set("headData.imageUrl", model.imageUrl);
    },
    setupController: function setupController(controller, model) {
      this._super(controller, model);

      controller.set("names", []); // setup order

      var return_url = "".concat(_environment.default.webURL) + this.get("router.url");
      var order = this.get("store").createRecord("order", {
        productType: "Merch",
        productId: model.get("id"),
        returnUrl: return_url,
        quantity: 1,
        totalPrice: model.get("price"),
        attendees: []
      });
      controller.set("order", order);
    },
    actions: {
      didTransition: function didTransition() {
        var metrics = this.get("metrics"),
            merch = this.controller.get("model");
        metrics.trackEvent({
          event: "View Merch",
          type: "event",
          name: merch.get("name"),
          slug: merch.get("slug")
        });
        return true;
      },
      submitOrder: function submitOrder(order) {
        var _this = this;

        var serializedAttendees = order.attendees.toArray().map(function (a) {
          return {
            name: a.name,
            email: a.email
          };
        });
        order.set("attendees", serializedAttendees);
        order.save().then(function (response) {
          try {
            var merch = _this.controller.get("model");

            _this.get("metrics").trackEvent({
              event: "Checkout",
              type: "event",
              name: merch.get("name"),
              slug: merch.get("slug"),
              order_name: order.get("name")
            });
          } finally {
            var redirect = response.get("checkoutUrl");
            Ember.run.later(function () {
              window.location.href = redirect;
            }, 1000);
          }
        });
      }
    }
  });

  _exports.default = _default;
});