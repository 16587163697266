define("formaviva-web/_pods/components/table/cell/duration/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "lq7fsuNO",
    "block": "{\"symbols\":[\"@rawValue\",\"@column\"],\"statements\":[[4,\"if\",[[24,1,[]]],null,{\"statements\":[[4,\"if\",[[24,2,[\"format\"]]],null,{\"statements\":[[0,\"    \"],[1,[29,\"format-duration\",[[24,1,[]],[24,2,[\"format\"]]],null],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[1,[29,\"format-duration\",[[24,1,[]]],null],false],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "formaviva-web/_pods/components/table/cell/duration/template.hbs"
    }
  });

  _exports.default = _default;
});