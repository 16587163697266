define("formaviva-web/_pods/components/table/cell/currency/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "aqAto/gu",
    "block": "{\"symbols\":[\"@column\",\"@rawValue\"],\"statements\":[[1,[24,1,[\"prefix\"]],false],[0,\" \"],[1,[29,\"round-currency\",[[24,2,[]]],null],false],[0,\" \"],[1,[24,1,[\"suffix\"]],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "formaviva-web/_pods/components/table/cell/currency/template.hbs"
    }
  });

  _exports.default = _default;
});