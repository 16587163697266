define("formaviva-web/_pods/components/shipping/by-weight-price-form/component", ["exports", "ember-awesome-macros"], function (_exports, _emberAwesomeMacros) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: "",
    isMinWeightInputDisabled: Ember.computed.equal("price.minWeight", 0),
    shownRemoveButton: (0, _emberAwesomeMacros.and)((0, _emberAwesomeMacros.not)("isMinWeightInputDisabled"), "canRemove"),
    actions: {
      remove: function remove() {
        this.onRemove(this.price);
      }
    }
  });

  _exports.default = _default;
});