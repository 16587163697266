define("formaviva-web/_pods/profile/likes/route", ["exports", "formaviva-web/mixins/profile-data"], function (_exports, _profileData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_profileData.default, {
    renderTemplate: function renderTemplate() {
      this.render({
        into: "profile",
        outlet: "profile-content"
      });
    },
    headData: Ember.inject.service(),
    hasCustomAsyncTitle: true,
    afterModel: function afterModel(model) {
      var profile = model.profile;
      var title = "".concat(profile.name, " Likes | Formaviva");
      this.set("headData.title", title);
      this.set("headData.description", profile.bio);
      this.set("headData.imageUrl", profile.adjustedImageUrl);
    },
    setupController: function setupController(controller, model) {
      controller.set("isLoading", true);
      this.fetchLikedTracks(model.profile.slug, model.page, model.sort, model.filterByType).then(function (tracks) {
        controller.set("tracks", tracks);
        controller.set("page", model.page);
      }).finally(function () {
        controller.set("isLoading", false);
      });
    }
  });

  _exports.default = _default;
});