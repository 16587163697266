define("formaviva-web/_pods/components/truncate-with-tooltip/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ["truncate"],
    classNameBindings: ["maxRowsClass"],
    maxRowsClass: Ember.computed("maxRows", function () {
      var rows = this.maxRows;
      return rows ? "max-rows max-rows__".concat(rows) : "";
    }),
    mouseEnter: function mouseEnter() {
      var element = this.element;

      if (this.maxRows) {
        this.set("needTooltip", element.scrollHeight > element.offsetHeight);
      } else {
        this.set("needTooltip", element.scrollWidth > element.offsetWidth);
      }
    }
  });

  _exports.default = _default;
});