define("formaviva-web/_pods/components/time-input/component", ["exports", "moment", "formaviva-web/utils/keyboard"], function (_exports, _moment, _keyboard) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }

  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(n); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  function _iterableToArrayLimit(arr, i) { if (typeof Symbol === "undefined" || !(Symbol.iterator in Object(arr))) return; var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

  var HOURS_24 = 24 * 60 * 60;

  function replaceAt(string, index, replacement) {
    return string.substr(0, index) + replacement + string.substr(index + replacement.length);
  }

  var _default = Ember.Component.extend({
    tagName: "",
    formattedValue: Ember.computed("value", {
      get: function get() {
        return (0, _moment.default)().startOf("day").seconds(this.value || 0).format("HH:mm:ss");
      },
      set: function set(key, val) {
        return val;
      }
    }),
    getSeconds: function getSeconds(val) {
      var _val$split = val.split(":"),
          _val$split2 = _slicedToArray(_val$split, 3),
          _val$split2$ = _val$split2[0],
          hour = _val$split2$ === void 0 ? 0 : _val$split2$,
          _val$split2$2 = _val$split2[1],
          minute = _val$split2$2 === void 0 ? 0 : _val$split2$2,
          _val$split2$3 = _val$split2[2],
          second = _val$split2$3 === void 0 ? 0 : _val$split2$3;

      return parseInt(hour, 10) * 60 * 60 + parseInt(minute, 10) * 60 + parseInt(second, 10);
    },
    setValue: function setValue(value, target, index) {
      var _this = this;

      this.onValueChange(value);
      Ember.run.schedule("afterRender", function () {
        _this.notifyPropertyChange("value");

        (0, _keyboard.setCaretPosition)(target, index);
        Ember.run.next(function () {
          Ember.run.schedule("afterRender", function () {
            (0, _keyboard.setCaretPosition)(target, index);
          });
        });
      });
    },
    decrementAt: function decrementAt(index) {
      var value = this.value || 0;
      var type = ["hour", "minute", "second"][Math.floor(index / 3)];

      if (type === "hour") {
        value -= 60 * 60;
      } else if (type === "minute") {
        value -= 60;
      } else if (type === "second") {
        value -= 1;
      }

      if (value <= 0) {
        value = 0;
      }

      return value;
    },
    incrementAt: function incrementAt(index) {
      var value = this.value || 0;
      var type = ["hour", "minute", "second"][Math.floor(index / 3)];

      if (type === "hour") {
        value += 60 * 60;
      } else if (type === "minute") {
        value += 60;
      } else if (type === "second") {
        value += 1;
      }

      if (value >= HOURS_24) {
        value = HOURS_24 - 1;
      }

      return value;
    },
    actions: {
      onKeydown: function onKeydown(e) {
        var target = e.target,
            keyCode = e.keyCode;
        var index = (0, _keyboard.getCaretPosition)(target);

        if ((0, _keyboard.isLetterKey)(keyCode)) {
          e.preventDefault();
          return;
        }

        if (keyCode === _keyboard.KEYS.ARROW_DOWN) {
          var value = this.decrementAt(index);
          this.setValue(value, target, index);
          e.preventDefault();
        } else if (keyCode === _keyboard.KEYS.ARROW_UP) {
          var _value = this.incrementAt(index);

          this.setValue(_value, target, index);
          e.preventDefault();
        }
      },
      onInput: function onInput(e) {
        var data = e.data,
            target = e.target;
        var newValue = this.formattedValue;
        var index = (0, _keyboard.getCaretPosition)(target);

        if (data === null) {
          var atSemiColon = (index + 1) % 3 == 0;

          if (atSemiColon) {
            index--;
          }

          newValue = replaceAt(newValue, index, "0");
          this.setValue(this.getSeconds(newValue), target, index);
        } else {
          var _atSemiColon = (index - 1) % 3 == 0;

          if (_atSemiColon) {
            index++;
          }

          newValue = replaceAt(newValue, index - 2, data);
          this.setValue(this.getSeconds(newValue), target, index);
        }
      }
    }
  });

  _exports.default = _default;
});