define("formaviva-web/_pods/components/shopping-cart/list/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "W3rUqCGK",
    "block": "{\"symbols\":[\"item\",\"@refreshAmounts\",\"@removeItem\",\"@items\"],\"statements\":[[7,\"div\"],[11,\"class\",\"shopping-cart-table\"],[9],[0,\"\\n\"],[4,\"each\",[[24,4,[]]],null,{\"statements\":[[0,\"    \"],[1,[29,\"shopping-cart/list-item\",null,[[\"item\",\"removeItem\",\"refreshAmounts\"],[[24,1,[]],[29,\"action\",[[24,0,[]],[24,3,[]]],null],[29,\"action\",[[24,0,[]],[24,2,[]]],null]]]],false],[0,\"\\n\"]],\"parameters\":[1]},{\"statements\":[[0,\"    You don't have any items in the shopping cart.\\n\"]],\"parameters\":[]}],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "formaviva-web/_pods/components/shopping-cart/list/template.hbs"
    }
  });

  _exports.default = _default;
});