define("formaviva-web/_pods/analytics/sale/edit/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    saleItem: Ember.computed.alias("model"),
    init: function init() {
      this._super.apply(this, arguments);

      this.set("statuses", ["processing", "shipped"]);
    },
    actions: {
      goToSale: function goToSale() {
        this.transitionToRoute("analytics.sale", this.get("saleItem.purchase"));
      },
      save: function save() {
        var _this = this;

        var newStatus = this.get("newStatus");

        if (!newStatus || this.get("saleItem.status") === newStatus) {
          return this.get("notifications").error("Select new status.", {
            autoClear: true
          });
        }

        this.set("saleItem.status", newStatus);
        this.get("saleItem").save().then(function () {
          _this.get("notifications").success("Sale item status successfully changed.", {
            autoClear: true
          });

          _this.send("goToSale");
        }, function () {
          _this.get("notifications").error("There was an error changing the status.", {
            autoClear: true
          });
        });
      }
    }
  });

  _exports.default = _default;
});