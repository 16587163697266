define("formaviva-web/_pods/components/shipping/by-weight-prices-container/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "IUoxDMGZ",
    "block": "{\"symbols\":[\"sortedPrices\",\"price\",\"index\"],\"statements\":[[7,\"table\"],[11,\"class\",\"shipping-by-weight-prices-container__table\"],[9],[0,\"\\n  \"],[7,\"thead\"],[9],[0,\"\\n    \"],[7,\"th\"],[11,\"class\",\"shipping-by-weight-prices-container__th\"],[9],[10],[0,\"\\n    \"],[7,\"th\"],[11,\"class\",\"shipping-by-weight-prices-container__th\"],[11,\"colspan\",\"3\"],[9],[0,\"Weight\"],[10],[0,\"\\n    \"],[7,\"th\"],[11,\"class\",\"shipping-by-weight-prices-container__th with-indent\"],[11,\"colspan\",\"3\"],[9],[0,\"Cost\"],[10],[0,\"\\n  \"],[10],[0,\"\\n  \"],[7,\"tbody\"],[9],[0,\"\\n\"],[4,\"shipping/with-sorted-weighted-prices\",null,[[\"prices\"],[[25,[\"prices\"]]]],{\"statements\":[[4,\"each\",[[24,1,[]]],null,{\"statements\":[[0,\"        \"],[1,[29,\"shipping/by-weight-price-form\",null,[[\"canRemove\",\"price\",\"index\",\"onRemove\"],[[25,[\"canRemovePrice\"]],[24,2,[]],[29,\"inc\",[[24,3,[]]],null],[29,\"action\",[[24,0,[]],\"removePrice\"],null]]]],false],[0,\"\\n\"]],\"parameters\":[2,3]},null]],\"parameters\":[1]},null],[0,\"  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\\n\"],[7,\"a\"],[11,\"class\",\"shipping-by-weight-prices-container__add-price-btn\"],[12,\"onClick\",[29,\"action\",[[24,0,[]],\"addPrice\"],null]],[9],[0,\"\\n  + add weight range\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "formaviva-web/_pods/components/shipping/by-weight-prices-container/template.hbs"
    }
  });

  _exports.default = _default;
});