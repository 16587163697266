define("formaviva-web/_pods/profile/about/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "edcpW6CC",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[29,\"user-agent\",[\"device.isMobile\"],null]],null,{\"statements\":[[0,\"  \"],[7,\"div\"],[11,\"class\",\"profile-overview__content\"],[9],[0,\"\\n\"],[4,\"if\",[[25,[\"profile\",\"bio\"]]],null,{\"statements\":[[0,\"      \"],[7,\"div\"],[11,\"class\",\"profile__info\"],[9],[0,\"\\n\"],[4,\"layout/overlay-shortener\",null,[[\"maxSize\"],[400]],{\"statements\":[[0,\"          \"],[1,[29,\"new-lines-to-paragraphs\",[[25,[\"profile\",\"bio\"]]],null],true],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"      \"],[10],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n    \"],[1,[29,\"profile/social-links\",null,[[\"profile\"],[[25,[\"profile\"]]]]],false],[0,\"\\n\\n\"],[4,\"if\",[[29,\"and\",[[25,[\"donation\"]],[25,[\"profile\",\"approved\"]]],null]],null,{\"statements\":[[0,\"      \"],[1,[29,\"donate-widget\",null,[[\"profile\",\"donation\",\"justDonated\",\"showDonateModal\",\"onDonationSuccess\"],[[25,[\"profile\"]],[25,[\"donation\"]],[25,[\"justDonated\"]],[29,\"and\",[[25,[\"showDonateModal\"]],[29,\"not\",[[25,[\"justDonated\"]]],null]],null],[29,\"route-action\",[\"onDonationSuccess\"],null]]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"  \"],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "formaviva-web/_pods/profile/about/template.hbs"
    }
  });

  _exports.default = _default;
});