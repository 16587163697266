define("formaviva-web/_pods/components/form-errors/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "tJABPxlf",
    "block": "{\"symbols\":[\"error\",\"index\"],\"statements\":[[4,\"each\",[[29,\"get\",[[25,[\"resource\",\"errors\"]],[25,[\"property\"]]],null]],null,{\"statements\":[[4,\"if\",[[25,[\"firstOnly\"]]],null,{\"statements\":[[4,\"if\",[[29,\"eq\",[[24,2,[]],0],null]],null,{\"statements\":[[0,\"      \"],[7,\"div\"],[12,\"class\",[30,[\"input-error \",[23,\"inputErrorClasses\"]]]],[9],[0,\"\\n        \"],[1,[24,1,[\"message\"]],false],[0,\"\\n      \"],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[7,\"div\"],[12,\"class\",[30,[\"input-error \",[23,\"inputErrorClasses\"]]]],[9],[0,\"\\n      \"],[1,[24,1,[\"message\"]],false],[0,\"\\n    \"],[10],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[1,2]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "formaviva-web/_pods/components/form-errors/template.hbs"
    }
  });

  _exports.default = _default;
});