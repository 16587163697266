define("formaviva-web/helpers/date-range-in-words", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.dateRangeInWords = dateRangeInWords;
  _exports.default = void 0;

  function dateRangeInWords(params
  /*, hash*/
  ) {
    var start = (0, _moment.default)(params[0]);
    var end = (0, _moment.default)(params[1]);
    var endIsToday = end.isSame(new Date(), "day");

    var rangeFormat = function rangeFormat(startDate, endDate) {
      return "".concat((0, _moment.default)(startDate).format("MMM Do YY"), " - ").concat((0, _moment.default)(endDate).format("MMM Do YY"));
    };

    if (endIsToday) {
      var daysDiff = Math.abs((0, _moment.default)(start.startOf("day")).diff((0, _moment.default)(end), "days"));

      if (daysDiff === 1) {
        return "Last day";
      } else if (daysDiff === 7) {
        return "Last ".concat(daysDiff, " days");
      } else if (daysDiff === 30) {
        return "Last month";
      } else if (daysDiff === 30 * 3) {
        return "Last 3 months";
      } else if (daysDiff === 30 * 6) {
        return "Last 6 months";
      } else if (daysDiff === 365) {
        return "Last year";
      } else {
        return rangeFormat(start, end);
      }
    } else {
      return rangeFormat(start, end);
    }
  }

  var _default = Ember.Helper.helper(dateRangeInWords);

  _exports.default = _default;
});