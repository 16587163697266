define("formaviva-web/_pods/signup/controller", ["exports", "formaviva-web/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var isTest = _environment.default.environment === "test";

  var _default = Ember.Controller.extend({
    queryParams: ["as"],
    as: "listener",
    isPasswordShown: false,
    isDisabled: Ember.computed(function () {
      return !isTest;
    }),
    displayRecaptcha: Ember.computed(function () {
      return _environment.default.environment === "production" || _environment.default.environment === "staging";
    }),
    recaptcha: false,
    directLabelOrArtistSignupLinkProvided: Ember.computed("as", function () {
      return ["label", "artist"].includes(this.as);
    }),
    actions: {
      setUsername: function setUsername(user) {
        if (Ember.isBlank(user.get("name"))) {
          return;
        }

        var name = user.get("name"); // retain only alphanumeric characters and join them by '-'

        var username = name.toLowerCase().replace(/[^a-zA-Z0-9\s+]/g, " ").trim().replace(/\s+/g, "-");
        user.set("username", username);
      },
      onCaptchaResolved: function onCaptchaResolved(resp) {
        this.recaptcha = resp;
      }
    }
  });

  _exports.default = _default;
});