define("formaviva-web/services/messages", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    notFoundError: "The page you are looking for was either removed, had its name changed, " + "or you don't have the necessary permissions to see it.",
    downloadInitiatedMessage: "Your files are preparing for download! It may take a while for the files to be processed. Please do not refresh or close this tab",
    downloadFailedError: "Something went wrong during download. Please try again or contact support.",
    downloadInitiationFailedError: "Failed to initiate download. Please try again or contact support."
  });

  _exports.default = _default;
});