define("formaviva-web/models/purchase-item", ["exports", "ember-data", "formaviva-web/config/environment"], function (_exports, _emberData, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Model = _emberData.default.Model;
  var belongsTo = _emberData.default.belongsTo;
  var hasMany = _emberData.default.hasMany;
  var attr = _emberData.default.attr;

  var _default = Model.extend({
    description: attr("string"),
    price: attr("number"),
    quantity: attr("number"),
    total: attr("number"),
    tracks: hasMany("track"),
    release: belongsTo("release"),
    lastDownloadedItemId: attr("number"),
    lastDownloadError: attr("string"),
    downloadUrl: attr("string"),
    lastDownloadedFormat: attr("string"),
    subtotal: attr("number"),
    status: attr("string"),
    vat: attr("number"),
    imageUrl: attr("string"),
    statusChangeMessage: attr("string"),
    digital: attr("boolean"),
    resourceType: attr("string"),
    purchaseId: attr("number"),
    purchase: belongsTo("purchase"),
    adjustedImageUrl: Ember.computed("imageUrl", function () {
      if (this.get("imageUrl") && this.get("imageUrl")[0] === "/") {
        return "".concat(_environment.default.serverURL, "/").concat(this.get("imageUrl"));
      } else {
        return this.get("imageUrl");
      }
    })
  });

  _exports.default = _default;
});