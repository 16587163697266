define("formaviva-web/_pods/components/track/new-tracks-table/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "1fhwwrvQ",
    "block": "{\"symbols\":[],\"statements\":[[1,[29,\"table/base-layout\",null,[[\"class\",\"height\",\"model\",\"columns\",\"rowComponent\",\"extraData\",\"onRowDoubleClick\",\"onRowClick\",\"emptyTableLabel\"],[\"tracks__table\",[25,[\"height\"]],[25,[\"slicedTracks\"]],[25,[\"columns\"]],[29,\"component\",[\"table/row/active-track\"],null],[29,\"hash\",null,[[\"release\",\"currentTrack\"],[[25,[\"release\"]],[25,[\"currentTrack\"]]]]],[29,\"action\",[[24,0,[]],\"onRowDoubleClick\"],null],[29,\"action\",[[24,0,[]],\"onRowClick\"],null],\"You have no tracks\"]]],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "formaviva-web/_pods/components/track/new-tracks-table/template.hbs"
    }
  });

  _exports.default = _default;
});