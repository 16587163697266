define("formaviva-web/services/checkout/checkout-data", ["exports", "formaviva-web/utils/transform-object-keys"], function (_exports, _transformObjectKeys) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    localStorageKey: "fv:checkout-data",
    currentSession: Ember.inject.service(),
    init: function init() {
      this._super.apply(this, arguments);

      this.setProperties({
        shippingData: {},
        billingData: {},
        followSellerProfiles: true
      });
      this.restore();
    },
    reset: function reset() {
      this.setProperties({
        shippingData: {},
        billingData: {},
        followSellerProfiles: true
      });
      this.persist();
    },
    restore: function restore() {
      this.ensureShippingAndBillingEmail();

      if (this.isCurrentlyEditing) {
        return;
      }

      var _this$_deserializeChe = this._deserializeCheckoutData(),
          billingData = _this$_deserializeChe.billingData,
          shippingData = _this$_deserializeChe.shippingData,
          followSellerProfiles = _this$_deserializeChe.followSellerProfiles;

      this.setProperties({
        billingData: billingData || {},
        shippingData: shippingData || {},
        followSellerProfiles: followSellerProfiles || true
      });
    },
    ensureShippingAndBillingEmail: function ensureShippingAndBillingEmail() {
      var billingEmail = this.billingData.email;
      var shippingEmail = this.shippingData.email;

      if (Ember.isBlank(billingEmail) || Ember.isBlank(shippingEmail)) {
        var userEmail = this.get("currentSession.user.email");
        this.set("billingData.email", userEmail);
        this.set("shippingData.email", userEmail);
      }
    },
    persist: function persist() {
      var serializedItems = this._serializeCheckoutData();

      window.localStorage.setItem(this.localStorageKey, JSON.stringify(serializedItems));

      if (this.get("currentSession.isAuthenticated")) {
        var user = this.get("currentSession.user");
        user.set("checkoutData", serializedItems);
        user.save();
      }
    },
    _deserializeCheckoutData: function _deserializeCheckoutData() {
      var checkoutData = {};

      if (window.localStorage) {
        var serializedDataFromLocalStorage = window.localStorage.getItem(this.localStorageKey);
        var dataFromLocalStorage = JSON.parse(serializedDataFromLocalStorage) || {};
        checkoutData = dataFromLocalStorage;
      } // Favor user saved data over localStorage


      if (this.get("currentSession.isAuthenticated")) {
        var user = this.get("currentSession.user");
        var dataFromUser = user.get("checkoutData");

        if (dataFromUser) {
          if (dataFromUser.shipping_data) {
            checkoutData.shippingData = _transformObjectKeys.default.camelizeKeys(dataFromUser.shipping_data);
          }

          if (dataFromUser.shipping_data) {
            checkoutData.billingData = _transformObjectKeys.default.camelizeKeys(dataFromUser.billing_data);
          }
        }
      }

      return checkoutData;
    },
    _serializeCheckoutData: function _serializeCheckoutData() {
      return this.getProperties("shippingData", "billingData", "followSellerProfiles");
    }
  });

  _exports.default = _default;
});