define("formaviva-web/_pods/components/shipping/zone-price-details/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "iGflUwDR",
    "block": "{\"symbols\":[\"sortedPrices\",\"price\"],\"statements\":[[4,\"if\",[[25,[\"zone\",\"isCalculationTypeWeight\"]]],null,{\"statements\":[[0,\"  \"],[7,\"table\"],[9],[0,\"\\n    \"],[7,\"tbody\"],[9],[0,\"\\n\"],[4,\"shipping/with-sorted-weighted-prices\",null,[[\"prices\"],[[25,[\"zone\",\"pricesContainer\",\"prices\"]]]],{\"statements\":[[4,\"each\",[[24,1,[]]],null,{\"statements\":[[0,\"          \"],[1,[29,\"shipping/by-weight-price\",null,[[\"price\"],[[24,2,[]]]]],false],[0,\"\\n\"]],\"parameters\":[2]},null]],\"parameters\":[1]},null],[0,\"    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[25,[\"zone\",\"isCalculationTypeQuantity\"]]],null,{\"statements\":[[0,\"  \"],[1,[29,\"shipping/by-quantity-price\",null,[[\"price\"],[[25,[\"zone\",\"pricesContainer\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "formaviva-web/_pods/components/shipping/zone-price-details/template.hbs"
    }
  });

  _exports.default = _default;
});