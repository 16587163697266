define("formaviva-web/_pods/components/sortable-select/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "/Wzd+UU5",
    "block": "{\"symbols\":[\"sortOption\"],\"statements\":[[7,\"div\"],[11,\"class\",\"sortable-select\"],[9],[0,\"\\n  \"],[7,\"label\"],[9],[0,\"Sort by:\"],[10],[0,\"\\n\"],[4,\"power-select\",null,[[\"options\",\"selected\",\"searchEnabled\",\"placeholder\",\"onchange\"],[[25,[\"sortOptions\"]],[25,[\"selectedSortOption\"]],false,\"Please select\",[29,\"action\",[[24,0,[]],\"setSelectedSortOption\"],null]]],{\"statements\":[[0,\"    \"],[1,[24,1,[\"label\"]],false],[0,\"\\n\"]],\"parameters\":[1]},null],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "formaviva-web/_pods/components/sortable-select/template.hbs"
    }
  });

  _exports.default = _default;
});