define("formaviva-web/_pods/components/table/cell/image/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Y0kzh4S3",
    "block": "{\"symbols\":[\"@column\",\"@rawValue\"],\"statements\":[[4,\"if\",[[24,2,[]]],null,{\"statements\":[[0,\"  \"],[7,\"img\"],[12,\"src\",[30,[[24,2,[]]]]],[12,\"class\",[30,[\"fv-table-thumbnail\",[29,\"if\",[[24,1,[\"isCircle\"]],\" cirlce-thumbnail\"],null]]]],[11,\"alt\",\"\"],[9],[10],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[7,\"span\"],[12,\"class\",[30,[\"fv-table-thumbnail\",[29,\"if\",[[24,1,[\"isCircle\"]],\" cirlce-thumbnail\"],null]]]],[9],[10],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "formaviva-web/_pods/components/table/cell/image/template.hbs"
    }
  });

  _exports.default = _default;
});