define("formaviva-web/_pods/dashboard/tracks/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "9II39YrG",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"mt-6 clearfix\"],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"pull-left pt-2\"],[9],[0,\"You currently have \"],[1,[29,\"pluralize\",[[29,\"or\",[[25,[\"model\",\"meta\",\"total\"]],0],null],\"track\"],null],false],[10],[0,\"\\n  \"],[4,\"link-to\",[\"upload.track\"],[[\"class\"],[\"btn btn-primary fv-btn--sm pull-right\"]],{\"statements\":[[0,\"Create Track\"]],\"parameters\":[]},null],[0,\"\\n\"],[10],[0,\"\\n\"],[1,[29,\"collection-pagination\",null,[[\"class\",\"collection\",\"path\",\"currentPage\"],[\"no-margins\",[25,[\"model\"]],\"dashboard.tracks\",[25,[\"page\"]]]]],false],[0,\"\\n\"],[1,[29,\"dashboard/dashboard-track-list\",null,[[\"dir\",\"sort\",\"tracks\",\"sortChange\"],[[25,[\"dir\"]],[25,[\"sort\"]],[25,[\"model\"]],[29,\"action\",[[24,0,[]],\"sortChange\"],null]]]],false],[0,\"\\n\"],[1,[29,\"collection-pagination\",null,[[\"class\",\"collection\",\"path\",\"currentPage\"],[\"no-margins\",[25,[\"model\"]],\"dashboard.tracks\",[25,[\"page\"]]]]],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "formaviva-web/_pods/dashboard/tracks/template.hbs"
    }
  });

  _exports.default = _default;
});