define("formaviva-web/helpers/feature-indicator", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.featureIndicator = featureIndicator;
  _exports.default = void 0;

  function featureIndicator(params
  /*, hash*/
  ) {
    var sign = params[0];
    var html = "";

    switch (sign) {
      case "yes":
        html = '<span class="feature-indicator feature-yes">&#10003;</span>';
        break;

      case "no":
        html = '<span class="feature-indicator feature-no">&times;</span>';
        break;
    }

    return new Ember.String.htmlSafe(html);
  }

  var _default = Ember.Helper.helper(featureIndicator);

  _exports.default = _default;
});