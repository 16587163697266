define("formaviva-web/_pods/components/layout/overlay-shortener/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ["overlay-shortener-area"],
    classNameBindings: ["collapsed:overlay-shortener-area--collapsed", "hasRendered:overlay-shortener-area--animatable"],
    overlayShown: Ember.computed.and("isLargerThanLimit", "collapsed"),
    onHoverIn: function onHoverIn() {
      this.set("collapsed", false);
    },
    onHoverOut: function onHoverOut() {
      this.set("collapsed", true);
    },
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      var height = this.$().height();

      if (height > this.maxSize) {
        this.set("isLargerThanLimit", true);
        this.set("collapsed", true);
      }

      this.$().hover(this.onHoverIn.bind(this), this.onHoverOut.bind(this));
    },
    didRender: function didRender() {
      var _this = this;

      this._super.apply(this, arguments);

      Ember.run.later(function () {
        _this.set("hasRendered", true);
      }, 100);
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);

      this.$().off("mouseenter mouseleave");
    }
  });

  _exports.default = _default;
});