define("formaviva-web/_pods/release/edit/route", ["exports", "formaviva-web/mixins/unsaved-record-notification", "ember-simple-auth/mixins/authenticated-route-mixin"], function (_exports, _unsavedRecordNotification, _authenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, _unsavedRecordNotification.default, {
    pageTitle: "Edit Release | Formaviva",
    model: function model() {
      var _this$paramsFor = this.paramsFor("release"),
          release_slug = _this$paramsFor.release_slug,
          profile_slug = _this$paramsFor.profile_slug;

      return this.store.queryRecord("release", {
        slug: release_slug,
        find_by_slug: true,
        profile_slug: profile_slug
      });
    }
  });

  _exports.default = _default;
});