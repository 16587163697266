define("formaviva-web/utils/sorting", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.buildSortObject = void 0;

  /**
   * SortObject represents sorting rule.
   * @typedef {Object} SortObject
   * @property {string} field Sort field
   * @property {'asc'|'desc'} direction Sorting direction
   */

  /**
   * Parse a string and return sort object with "field" and "direction" properties.
   * @param {string} str sort string. "field_name" for asc sorting. "-field_name" for desc sorting
   * @returns {SortObject}
   * @example
   * buildSortObject("created_at") // => { field: "created_at", direction: "asc" }
   * @example
   * buildSortObject("-created_at") // => { field: "created_at", direction: "desc" }
   */
  var buildSortObject = function buildSortObject(str) {
    return str[0] === "-" ? {
      direction: "desc",
      field: str.slice(1)
    } : {
      direction: "asc",
      field: str
    };
  };

  _exports.buildSortObject = buildSortObject;
});