define("formaviva-web/models/shipping-by-weight-price", ["exports", "formaviva-web/models/shipping-prices-container", "ember-data-model-fragments/attributes", "ember-cp-validations"], function (_exports, _shippingPricesContainer, _attributes, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Validations = (0, _emberCpValidations.buildValidations)({
    prices: (0, _emberCpValidations.validator)("has-many")
  });

  var _default = _shippingPricesContainer.default.extend(Validations, {
    prices: (0, _attributes.fragmentArray)("shipping-weighted-price", {
      defaultValue: [{
        minWeight: 0,
        cost: 0
      }]
    })
  });

  _exports.default = _default;
});