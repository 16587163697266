define("formaviva-web/_pods/components/shopping-cart/list-item/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNameBindings: ["isError"],
    classNames: ["shopping-cart-list-item", "row"],
    shoppingCart: Ember.inject.service("checkout/shoppingCart"),
    isError: Ember.computed("item.merch.id", "shoppingCart.merchWithShippingErrorIds", function () {
      var itemMerchId = this.get("item.merch.id");
      return this.get("shoppingCart.merchWithShippingErrorIds").includes(itemMerchId);
    }),
    actions: {
      onRemoveClick: function onRemoveClick(e) {
        this.set("shoppingCart.isOpen", true);
        this.get("removeItem")(this.get("item"));
        e.stopPropagation();
      },
      changeQuantity: function changeQuantity(quantity) {
        var q = parseInt(quantity);

        if (isNaN(q)) {
          q = 1;
        }

        this.set("item.quantity", Math.abs(q));
        this.get("refreshAmounts")();

        this.get("shoppingCart")._persist();
      }
    }
  });

  _exports.default = _default;
});