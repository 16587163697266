define("formaviva-web/_pods/artists/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    pageTitle: "Artists | Formaviva",
    queryParams: {
      page: {
        refreshModel: true
      },
      sort: {
        refreshModel: true
      }
    },
    model: function model(params) {
      var page = params.page || 1;
      var sort = params.sort || "-approved_at";
      return this.store.query("profile", {
        page: page,
        per_page: 24,
        public: true,
        sort: sort,
        filter: {
          profile_type: "artist"
        }
      });
    }
  });

  _exports.default = _default;
});