define("formaviva-web/services/authenticated-ajax", ["exports", "ember-ajax/services/ajax"], function (_exports, _ajax) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _ajax.default.extend({
    session: Ember.inject.service(),

    get headers() {
      var headers = {};

      if (this.session.isAuthenticated) {
        var accessToken = this.session.data.authenticated.access_token;
        var profileId = this.session.data.profileId;

        if (Ember.isPresent(accessToken)) {
          headers["Authorization"] = "Bearer ".concat(accessToken);
        }

        if (Ember.isPresent(profileId)) {
          headers["X-ProfileId"] = profileId;
        }
      }

      return headers;
    },

    trustedHosts: Object.freeze(["/(formaviva.com)/", "localhost", "api.formaviva.com", "staging-api.formaviva.com", "api.aws-staging.formaviva.com"])
  });

  _exports.default = _default;
});