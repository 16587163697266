define("formaviva-web/_pods/login/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    pageTitle: "Login | Formaviva",
    session: Ember.inject.service(),
    appState: Ember.inject.service(),
    currentSession: Ember.inject.service(),
    resetController: function resetController(controller) {
      controller.set("username", null);
      controller.set("password", null);
      controller.set("errorMessage", null);
    },
    beforeModel: function beforeModel() {
      this._super.apply(this, arguments);

      if (this.currentSession.isAuthenticated) this.transitionTo("dashboard.stats");
    },
    actions: {
      login: function login(username, password, e) {
        var _this = this;

        e.preventDefault();
        this.appState.setIsImpersonatedSession(false);
        this.controller.set("isLoggingIn", true);
        this.session.authenticate("authenticator:oauth2", username, password).then(function () {
          _this.currentSession.load().then(function () {
            var previousRouteName = _this.session.previousRouteName; // Redirect to corresponding index pages if the user
            // logins from login/signup route. Otherwise leave it to ember-simple-auth
            // to redirect to the previous route.

            if (!previousRouteName || previousRouteName === "login" || previousRouteName === "signup") {
              _this.get("currentSession.profile.isListener") ? _this.transitionTo("feed") : _this.transitionTo("index");
            }
          });
        }).catch(function () {
          _this.controller.set("errorMessage", "Invalid login.");

          _this.controller.set("isLoggingIn", false);
        });
      }
    }
  });

  _exports.default = _default;
});