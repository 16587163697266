define("formaviva-web/services/analytics/event-tracker", ["exports", "formaviva-web/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    authenticatedAjax: Ember.inject.service(),
    track: function track(params) {
      var requestUrl = _environment.default.apiBaseURL + "/analytics_events/";
      return this.get("authenticatedAjax").post(requestUrl, {
        data: {
          data: {
            attributes: params
          }
        } // JSON:API

      });
    }
  });

  _exports.default = _default;
});