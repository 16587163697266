define("formaviva-web/_pods/password-reset/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    pageTitle: "Password reset | Formaviva",
    resetController: function resetController(controller, isExiting) {
      if (isExiting) {
        controller.set("token", null);
      }
    }
  });

  _exports.default = _default;
});