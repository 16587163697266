define("formaviva-web/helpers/round-currency", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.roundNum = roundNum;
  _exports.default = void 0;

  function roundNum(str, namedArgs) {
    // At some place we need to include the digits after the decimal point even
    // if it is zero for better user experience. So we pass a flag to denote whether
    // the decimal points need to stripped or not, and return the value correspondingly.
    var stripDecimalIfWholeNumber = namedArgs.stripDecimalIfWholeNumber;
    var currencyValue = parseFloat(str);
    return currencyValue % 1 === 0 && stripDecimalIfWholeNumber ? currencyValue : currencyValue.toFixed(2);
  }

  var _default = Ember.Helper.helper(roundNum);

  _exports.default = _default;
});