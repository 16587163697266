define("formaviva-web/_pods/components/overlay-actions/component", ["exports", "formaviva-web/utils/scroll-lock"], function (_exports, _scrollLock) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: "",
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      _scrollLock.default.enable();
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);

      _scrollLock.default.disable();
    }
  });

  _exports.default = _default;
});