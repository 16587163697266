define("formaviva-web/_pods/components/layout/overlay-shortener/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "5y15NPsr",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[15,1],[0,\"\\n\\n\"],[4,\"if\",[[25,[\"overlayShown\"]]],null,{\"statements\":[[0,\"  \"],[7,\"div\"],[11,\"class\",\"shortener-overlay\"],[9],[0,\"\\n  \"],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "formaviva-web/_pods/components/layout/overlay-shortener/template.hbs"
    }
  });

  _exports.default = _default;
});