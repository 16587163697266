define("formaviva-web/_pods/components/billing/payment-method-form/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Z2pZr4e9",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[12,\"class\",[30,[\"payment-form \",[29,\"unless\",[[25,[\"braintreeIsReady\"]],\"payment-form--loading\"],null]]]],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"id\",\"braintree-dropin-container\"],[9],[10],[0,\"\\n\"],[10],[0,\"\\n\\n\"],[4,\"if\",[[25,[\"paymentMethodSelected\"]]],null,{\"statements\":[[4,\"if\",[[25,[\"braintree\",\"checkout\",\"isRunning\"]]],null,{\"statements\":[[4,\"overlay-spinner\",null,null,{\"statements\":[[0,\"      Processing payment \"],[1,[29,\"ellipsis-animation\",null,[[\"class\"],[\"inline-block\"]]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "formaviva-web/_pods/components/billing/payment-method-form/template.hbs"
    }
  });

  _exports.default = _default;
});