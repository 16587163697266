define("formaviva-web/_pods/components/shopping-cart/opener/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    shoppingCart: Ember.inject.service("checkout/shoppingCart"),
    actions: {
      closeShoppingCart: function closeShoppingCart() {
        this.set("shoppingCart.isOpened", false);
      }
    }
  });

  _exports.default = _default;
});