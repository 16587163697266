define("formaviva-web/initializers/app-instance-identificator", ["exports", "ember-uuid"], function (_exports, _emberUuid) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.initialize = initialize;
  _exports.default = void 0;

  function initialize() {
    // Assign a unique id to this app instance.
    // Used mainly for detecting if another app instance started playing audio.
    window.__fvAppInstance = (0, _emberUuid.v4)();
  }

  var _default = {
    name: "appinstanceidentificator",
    initialize: initialize
  };
  _exports.default = _default;
});