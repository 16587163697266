define("formaviva-web/_pods/components/track/tracks-table/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    fastboot: Ember.inject.service(),
    isFastBoot: Ember.computed.reads("fastboot.isFastBoot"),
    messageBus: Ember.inject.service(),
    isDownloading: false,
    trackArtistsAllSame: Ember.computed("tracks", function () {
      return this.tracks.mapBy("originalArtistNames").uniq().length === 1;
    }),
    slicedTracks: Ember.computed("tracks.[]", "visibleTracksCount", function () {
      var visibleTracksCount = this.visibleTracksCount;
      return Ember.isBlank(visibleTracksCount) ? this.tracks : this.tracks.slice(0, visibleTracksCount);
    }),
    init: function init() {
      this._super.apply(this, arguments);

      if (this.isFastBoot) return;
      this.subscribeToToggleEvent();
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);

      if (this.isFastBoot) return;
      this.messageBus.unsubscribe("toggle:load-more");
    },
    subscribeToToggleEvent: function subscribeToToggleEvent() {
      var _this = this;

      this.messageBus.subscribe("toggle:load-more", this, function (release) {
        if (_this.release == release) _this.send("toggleHiddenTracks");
      });
    },
    actions: {
      toggleHiddenTracks: function toggleHiddenTracks() {
        this.set("slicedTracks", this.tracks);
      }
    }
  });

  _exports.default = _default;
});