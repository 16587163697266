define("formaviva-web/_pods/analytics/play-sessions/route", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin"], function (_exports, _authenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    pageTitle: "Analytics | Formaviva",
    queryParams: {
      page: {
        refreshModel: true
      },
      track_id: {
        refreshModel: true
      }
    },
    model: function model(params) {
      var trackId = params.track_id;
      var page = params.page || 1;
      return this.store.query("play-session", {
        track_id: trackId,
        page: page
      });
    },
    setupController: function setupController(controller, model) {
      this._super(controller, model);

      if (Ember.isPresent(controller.get("allProfileTracks"))) return;
      var promise = this.store.findAll("track");
      promise.then(function (tracks) {
        controller.set("allProfileTracks", tracks);
      });
    }
  });

  _exports.default = _default;
});