define("formaviva-web/_pods/mixes/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    currentSession: Ember.inject.service(),
    queryParams: ["page", "sort", {
      filterByTag: "filter[tag]"
    }],
    page: 1,
    sort: null,
    filterByTag: null,
    availableSortOptions: null,
    selectedSortOption: Ember.computed("sort", function () {
      var sort = this.sort;
      var sortOption = {
        label: "Newest on Formaviva",
        field: "created_at",
        direction: "-"
      };

      if (sort) {
        if (sort.includes("name")) {
          sortOption.label = "Name (Z-A)";
          sortOption.field = "name";
          sortOption.direction = "-";

          if (!sort.includes("-")) {
            sortOption.label = "Name (A-Z)";
            sortOption.direction = "";
          }
        } else {
          if (!sort.includes("-")) {
            sortOption.label = "Oldest on Formaviva";
            sortOption.direction = "";
          }
        }
      }

      return sortOption;
    }),
    init: function init() {
      this._super.apply(this, arguments);

      var sortOptions = [{
        label: "Newest on Formaviva",
        field: "created_at",
        direction: "-"
      }, {
        label: "Oldest on Formaviva",
        field: "created_at",
        direction: ""
      }, {
        label: "Name (Z-A)",
        field: "name",
        direction: "-"
      }, {
        label: "Name (A-Z)",
        field: "name",
        direction: ""
      }];
      this.set("availableSortOptions", sortOptions);
    }
  });

  _exports.default = _default;
});