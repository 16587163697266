define("formaviva-web/models/order", ["exports", "ember-data", "ember-cp-validations"], function (_exports, _emberData, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Model = _emberData.default.Model;
  var attr = _emberData.default.attr;
  var Validations = (0, _emberCpValidations.buildValidations)({
    // name: [validator("presence", true), validator("length", { min: 4 })],
    // email: [validator("presence", true), validator("format", { type: "email" })],
    quantity: [(0, _emberCpValidations.validator)("number", {
      integer: true,
      gt: 0,
      allowString: true
    })],
    attendees: (0, _emberCpValidations.validator)("has-many")
  });

  var _default = Model.extend(Validations, {
    productType: attr("string"),
    productId: attr("number"),
    redirectUrl: attr("string"),
    attendees: attr(),
    totalPrice: attr("number"),
    quantity: attr("number"),
    paypalToken: attr("number"),
    checkoutUrl: attr("string"),
    returnUrl: attr("string"),
    name: attr("string"),
    email: attr("string")
  });

  _exports.default = _default;
});