define("formaviva-web/_pods/components/filter-select/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    router: Ember.inject.service(),
    classNames: ["filter-select"],
    init: function init() {
      this._super.apply(this, arguments);

      this.set("selectedFilterOption", this.selectedFilterOption || this.defaultFilterOption);
    },
    actions: {
      setSelectedFilterOption: function setSelectedFilterOption(option) {
        this.set("selectedFilterOption", option);
        var key = "filter[".concat(this.filterType.underscore(), "]");
        var queryParams = {
          page: 1
        };
        queryParams[key] = option.field;
        this.router.transitionTo({
          queryParams: queryParams
        });
      }
    }
  });

  _exports.default = _default;
});