define("formaviva-web/services/billing", ["exports", "formaviva-web/config/environment", "formaviva-web/utils/transform-object-keys"], function (_exports, _environment, _transformObjectKeys) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    authenticatedAjax: Ember.inject.service(),
    charge: function charge(chargeInfo, checkoutData, items) {
      var requestUrl = _environment.default.apiBaseURL + "/billing/charge"; // Prepare underscored params

      items = items.map(function (i) {
        return _transformObjectKeys.default.decamelizeKeys(i);
      });
      var transformedCheckoutData = {
        shipping_data: _transformObjectKeys.default.decamelizeKeys(checkoutData.shippingData),
        billing_data: _transformObjectKeys.default.decamelizeKeys(checkoutData.billingData),
        follow_seller_profiles: checkoutData.followSellerProfiles
      };
      return this.get("authenticatedAjax").post(requestUrl, {
        data: {
          charge_data: chargeInfo,
          checkout_data: transformedCheckoutData,
          items: items
        }
      });
    }
  });

  _exports.default = _default;
});