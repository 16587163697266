define("formaviva-web/_pods/feed/likes/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "7pA8GQvS",
    "block": "{\"symbols\":[\"track\"],\"statements\":[[7,\"div\"],[11,\"class\",\"feed-body feed-body--tracks\"],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"feed-content\"],[9],[0,\"\\n\"],[4,\"if\",[[29,\"gt\",[[25,[\"tracks\",\"length\"]],0],null]],null,{\"statements\":[[0,\"      \"],[7,\"div\"],[11,\"class\",\"feed-header\"],[9],[0,\"\\n        \"],[7,\"div\"],[11,\"class\",\"feed-header__sort\"],[9],[0,\"\\n          \"],[1,[29,\"sortable-select\",null,[[\"class\",\"sortOptions\",\"selectedSortOption\"],[\"sort-tracks\",[25,[\"availableSortOptions\"]],[25,[\"selectedSortOption\"]]]]],false],[0,\"\\n        \"],[10],[0,\"\\n      \"],[10],[0,\"\\n      \"],[7,\"div\"],[11,\"class\",\"feed-liked-tracks offset-top\"],[9],[0,\"\\n\"],[4,\"each\",[[25,[\"tracks\"]]],null,{\"statements\":[[0,\"          \"],[7,\"div\"],[11,\"style\",\"padding-left:0px;\"],[9],[0,\"\\n            \"],[1,[29,\"track/track-row\",null,[[\"track\",\"release\",\"purchaseItem\",\"currentTrack\",\"timestamp\",\"isDownloading\"],[[24,1,[]],[24,1,[\"release\"]],[29,\"if\",[[24,1,[\"purchaseItem\"]],[24,1,[\"purchaseItem\"]],[24,1,[\"release\",\"purchaseItem\"]]],null],[25,[\"currentTrack\"]],[24,1,[\"likedAt\"]],[25,[\"isDownloading\"]]]]],false],[0,\"\\n          \"],[10],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"        \"],[1,[29,\"collection-pagination\",null,[[\"collection\",\"path\",\"currentPage\"],[[25,[\"tracks\"]],\"feed.likes\",[25,[\"page\"]]]]],false],[0,\"\\n      \"],[10],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"      \"],[7,\"div\"],[11,\"class\",\"offset-top\"],[9],[0,\"\\n        \"],[7,\"span\"],[9],[0,\"Explore and enjoy music by listening to some interesting\\n          releases from \"],[4,\"link-to\",[\"releases\"],null,{\"statements\":[[0,\"here.\"]],\"parameters\":[]},null],[0,\"\\n        \"],[10],[0,\"\\n      \"],[10],[0,\"\\n\"]],\"parameters\":[]}],[0,\"  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "formaviva-web/_pods/feed/likes/template.hbs"
    }
  });

  _exports.default = _default;
});