define("formaviva-web/_pods/upload/followers/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "7SMdwZQY",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"row upload-section form-dark\"],[9],[0,\"\\n  \"],[7,\"p\"],[11,\"class\",\"col-md-8 col-md-offset-2 upload-followers-subtitle followers-text\"],[9],[0,\"\\n    You can use the upload follower functionality to boost your Formaviva profile and sales.\\n    You can upload a follower list from Bandcamp or your mailing list and inform everyone about your profile and music on Formaviva.\\n  \"],[10],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"col-md-8 col-md-offset-2\"],[9],[0,\"\\n    \"],[1,[23,\"upload-file\"],false],[0,\"\\n  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "formaviva-web/_pods/upload/followers/template.hbs"
    }
  });

  _exports.default = _default;
});