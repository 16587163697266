define("formaviva-web/services/unique-notifications", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * This service wrap ember-cli-notification service.
   * It removes messages with the same cssClasses property as the new one
   * from notificationMessages.content before each error, success info or warning
   * calls
   */
  var _default = Ember.Service.extend({
    notifications: Ember.inject.service("notification-messages"),
    messages: Ember.computed.readOnly("notifications.content"),
    error: function error(msg) {
      var opts = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

      this._removeSimilarMsg(opts);

      this.notifications.error(msg, opts);
    },
    success: function success(msg) {
      var opts = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

      this._removeSimilarMsg(opts);

      this.notifications.success(msg, opts);
    },
    info: function info(msg) {
      var opts = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

      this._removeSimilarMsg(opts);

      this.notifications.info(msg, opts);
    },
    warning: function warning(msg) {
      var opts = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

      this._removeSimilarMsg(opts);

      this.notifications.warning(msg, opts);
    },
    _removeSimilarMsg: function _removeSimilarMsg(opts) {
      var _this = this;

      if (Ember.isBlank(opts.cssClasses)) {
        return;
      }

      this.messages.filter(function (item) {
        return item.cssClasses === opts.cssClasses;
      }).forEach(function (msg) {
        return _this.messages.removeObject(msg);
      });
    }
  });

  _exports.default = _default;
});