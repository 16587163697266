define("formaviva-web/authenticators/oauth2-custom-inject", ["exports", "formaviva-web/authenticators/oauth2"], function (_exports, _oauth) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _oauth.default.extend({
    authenticate: function authenticate(tokenData) {
      var _this = this;

      return new Ember.RSVP.Promise(function (resolve) {
        var expiresAt = _this._absolutizeExpirationTime(tokenData["expires_in"]);

        _this._scheduleAccessTokenRefresh(tokenData["expires_in"], expiresAt, tokenData["refresh_token"]);

        if (!Ember.isEmpty(expiresAt)) {
          tokenData = Ember.assign(tokenData, {
            expires_at: expiresAt
          });
        }

        resolve(tokenData);
      });
    }
  });

  _exports.default = _default;
});