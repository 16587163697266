define("formaviva-web/_pods/components/profile/follow-profile-button/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Ou/pAy2s",
    "block": "{\"symbols\":[],\"statements\":[[7,\"button\"],[12,\"onclick\",[29,\"action\",[[24,0,[]],\"followProfile\",[25,[\"profile\"]]],null]],[12,\"disabled\",[23,\"isPending\"]],[12,\"class\",[30,[\"btn \",[29,\"if\",[[25,[\"prelaunchLocked\"]],\"btn-primary btn-primary-2\",\"btn-action--follow\"],null],\"\\n               \",[29,\"if\",[[29,\"and\",[[25,[\"profile\",\"followed\"]],[29,\"not\",[[25,[\"prelaunchLocked\"]]],null]],null],\"btn-action--followed\"],null]]]],[11,\"type\",\"button\"],[9],[0,\"\\n  \"],[7,\"span\"],[11,\"class\",\"btn-action__content\"],[9],[0,\"\\n    \"],[1,[23,\"buttonDisplay\"],false],[0,\"\\n  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "formaviva-web/_pods/components/profile/follow-profile-button/template.hbs"
    }
  });

  _exports.default = _default;
});