define("formaviva-web/_pods/components/tag/tag-indicator/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagLink: Ember.computed("record.mix", "tag.{id,slug}", function () {
      var recordType = this.get("record.mix") ? "mixes" : "releases";
      return "/".concat(recordType, "/").concat(this.get("tag.slug"));
    })
  });

  _exports.default = _default;
});