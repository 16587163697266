define("formaviva-web/_pods/release/show/controller", ["exports", "formaviva-web/mixins/can-edit"], function (_exports, _canEdit) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_canEdit.default, {
    queryParams: ["current_track_id"],
    current_track_id: null,
    currentSession: Ember.inject.service(),
    persistentPlayerState: Ember.inject.service(),
    store: Ember.inject.service(),
    shoppingCart: Ember.inject.service("checkout/shopping-cart"),
    release: Ember.computed.alias("model"),
    profile: Ember.computed.alias("release.profile"),
    allTracks: null,
    currentTrack: Ember.computed("current_track_id", "persistentPlayerState.currentTrack.id", "release.tracks.[]", function () {
      var _this = this;

      return this.release.tracks.find(function (t) {
        return t.id === _this.get("persistentPlayerState.currentTrack.id");
      }) || this.get("release.tracks.firstObject");
    }),
    tracks: Ember.computed("release", function () {
      var release = this.release;

      if (!release) {
        return [];
      } // lookup whether the data in relationship is loaded
      // and decide on whether the API is to be made to load
      // the data if not present


      if (release.hasMany("tracks").value()) {
        return release.tracks;
      } else {
        return this.store.query("track", {
          public: true,
          sort: "release_order",
          filter: {
            release_ids: release.id
          }
        });
      }
    }),
    isPrelaunchLocked: Ember.computed("release.isPrelaunch", "profile.{followed,id}", function () {
      return !this.get("profile.followed") && !(Ember.isPresent(this.get("currentSession").get("profile")) && this.get("profile.id") == this.get("currentSession").get("profile").get("id")) && this.get("release.isPrelaunch");
    }),
    actions: {
      filterTracks: function filterTracks(tag) {
        this.send("resetTracks");
        this.set("allTracks", this.tracks);
        var filteredTracks = this.tracks.filter(function (item) {
          return item.get("tags").includes(tag);
        });
        this.set("tracks", filteredTracks);
      },
      resetTracks: function resetTracks() {
        if (this.allTracks != null) {
          this.set("tracks", this.allTracks);
        }
      },
      addReleaseToCart: function addReleaseToCart(release) {
        this.get("shoppingCart").addItem({
          release: release
        });
      },
      addMerchToCart: function addMerchToCart(merch) {
        this.get("shoppingCart").addItem({
          merch: merch
        });
      }
    }
  });

  _exports.default = _default;
});