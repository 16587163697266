define("formaviva-web/_pods/components/profile/social-links/component", ["exports", "ember-awesome-macros"], function (_exports, _emberAwesomeMacros) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    hasAnySocialLink: (0, _emberAwesomeMacros.or)("profile.facebook", "profile.soundcloud", "profile.bandcamp", "profile.discogs", "profile.residentAdvisor", "profile.instagram", "profile.website")
  });

  _exports.default = _default;
});