define("formaviva-web/_pods/edit/user/route", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin"], function (_exports, _authenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    currentSession: Ember.inject.service(),
    pageTitle: "Edit Email & Password | Formaviva",
    model: function model() {
      var currentUser = this.currentSession.user;
      return this.store.peekRecord("user", currentUser.get("id"));
    },
    actions: {
      saveUser: function saveUser(user) {
        var _this = this;

        this.controller.set("isSaving", true);
        user.save().then(function () {
          _this.notifications.success("User details saved successfully.", {
            autoClear: true
          });
        }).catch(function (error) {
          if (window.Rollbar) Rollbar.error(error);

          _this.notifications.error("There was an unexpected error. Please try again or contact support.", {
            autoClear: true
          });
        }).finally(function () {
          return _this.controller.set("isSaving", false);
        });
      }
    }
  });

  _exports.default = _default;
});