define("formaviva-web/_pods/analytics/payouts/route", ["exports", "formaviva-web/utils/transform-object-keys"], function (_exports, _transformObjectKeys) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    pageTitle: "Payouts | Formaviva",
    fastboot: Ember.inject.service(),
    profileStats: Ember.inject.service(),
    isFastBoot: Ember.computed.reads("fastboot.isFastBoot"),
    queryParams: {
      sort: {
        refreshModel: true
      }
    },
    model: function model(_ref) {
      var sort = _ref.sort;
      var _this$fastboot = this.fastboot,
          isFastBoot = _this$fastboot.isFastBoot,
          shoebox = _this$fastboot.shoebox;
      var payoutStats;
      var data = shoebox.retrieve("analytics-payout");
      var model = this.store.query("payout", {
        sort: sort
      }); // we fetch data only when in pre-render or
      // when pre-render disabled and we don't have data from shoebox

      if (isFastBoot || !data) {
        payoutStats = this.fetchPayoutStats().then(function (payoutStats) {
          shoebox.set("analytics-payout", payoutStats);
          return payoutStats;
        });
      } else if (data) {
        payoutStats = data.payoutStats;
      }

      return Ember.RSVP.hash({
        model: model,
        payoutStats: payoutStats
      });
    },
    fetchPayoutStats: function fetchPayoutStats() {
      return this.profileStats.fetchPayoutStats().then(_transformObjectKeys.default.camelizeKeys).catch(function (error) {
        if (window.Rollbar) {
          Rollbar.error("Error occurred in fetching payout stats: " + error);
        }
      });
    },
    setupController: function setupController(controller, _ref2) {
      var model = _ref2.model,
          payoutStats = _ref2.payoutStats;

      this._super(controller, model);

      if (payoutStats) {
        controller.setProperties({
          payoutStats: payoutStats
        });
      }
    }
  });

  _exports.default = _default;
});