define("formaviva-web/_pods/feed/likes/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    persistentPlayerState: Ember.inject.service(),
    queryParams: ["page", "sort"],
    page: 1,
    currentSession: Ember.inject.service(),
    currentProfile: Ember.computed.alias("currentSession.profile"),
    tracks: Ember.computed.alias("model"),
    isDownloading: false,
    currentTrack: Ember.computed("persistentPlayerState.currentTrack.id", "tracks.firstObject", function () {
      var tracks = this.get("tracks");
      var currentTrackId = this.get("persistentPlayerState.currentTrack.id");
      return tracks.find(function (t) {
        return t.id === currentTrackId;
      }) || this.get("tracks.firstObject");
    }),
    // prettier-ignore
    selectedSortOption: Ember.computed("sort", {
      // eslint-disable-line ember/require-computed-property-dependencies
      get: function get() {
        if (this._selectedSortOption) return this._selectedSortOption;
        var sort = this.sort;
        var sortOption = {
          label: "Liked at",
          field: "liked_at",
          direction: "-",
          icon: "angle-up"
        };

        if (sort && !sort.includes("-")) {
          sortOption.direction = "";
          sortOption.icon = "angle-down";
        }

        return sortOption;
      },
      set: function set(key, value) {
        return this._selectedSortOption = value;
      }
    }),
    init: function init() {
      this._super.apply(this, arguments);

      var sortOptions = [{
        label: "Liked at",
        field: "liked_at"
      }];
      this.set("availableSortOptions", sortOptions);
    }
  });

  _exports.default = _default;
});