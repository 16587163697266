define("formaviva-web/_pods/components/overlay-spinner/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "7CDTYDuk",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[4,\"ember-wormhole\",null,[[\"to\"],[\"overlay_spinner\"]],{\"statements\":[[0,\"  \"],[7,\"div\"],[11,\"class\",\"overlay-container\"],[9],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"overlay-container-centered\"],[9],[0,\"\\n      \"],[7,\"div\"],[11,\"class\",\"progress-messages liquid-bind-inline\"],[9],[0,\"\\n        \"],[15,1],[0,\"\\n      \"],[10],[0,\"\\n    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "formaviva-web/_pods/components/overlay-spinner/template.hbs"
    }
  });

  _exports.default = _default;
});