define("formaviva-web/services/behaviour-tracking/tracker", ["exports", "formaviva-web/config/environment", "ember-uuid"], function (_exports, _environment, _emberUuid) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    authenticatedAjax: Ember.inject.service(),
    cookies: Ember.inject.service(),
    metrics: Ember.inject.service(),
    currentSession: Ember.inject.service(),
    appState: Ember.inject.service(),
    fastboot: Ember.inject.service(),
    cookieKey: "fv_user_behaviour_initial_distinct_id",
    track: function track() {
      var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      if (this.get("fastboot.isFastBoot")) return;
      if (this.appState.isImpersonatedSession) return;
      this.sendBrowserProperties();
      params["fv_user_behaviour_initial_distinct_id"] = this.getInitialDistinctId();

      if (window.navigator) {
        params["$browser"] = window.navigator.userAgent;
        params["$current_url"] = window.location.href;
      }

      var requestUrl = _environment.default.apiBaseURL + "/user_behaviour/track";
      return this.get("authenticatedAjax").post(requestUrl, {
        data: {
          event_params: params
        }
      });
    },
    sendBrowserProperties: function sendBrowserProperties() {
      if (this.appState.isImpersonatedSession) return;
      if (this.didSendBrowserProperties) return;
      var distinctId = this.get("currentSession.user.mixpanelDistinctId"); // Identify only when user has mixpanelDistinctId, which means user registered

      if (!distinctId) return;
      this.set("didSendBrowserProperties", true); // This will fail when using adblockers, but let's try anyway.

      this.get("metrics").identify({
        distinctId: distinctId
      });
      var user = this.get("currentSession.user");

      if (user && window.mixpanel && window.mixpanel.people) {
        window.mixpanel.people.set({});
        window.mixpanel.people.set({
          $email: user.get("email"),
          $name: user.get("name") || user.get("email")
        });
      }
    },
    assignInitialDistinctId: function assignInitialDistinctId() {
      if (this.appState.isImpersonatedSession) return;
      var existingInitialDistinctId = this.getInitialDistinctId();
      if (existingInitialDistinctId) return;
      this.cookies.write(this.cookieKey, (0, _emberUuid.v4)());
    },
    getInitialDistinctId: function getInitialDistinctId() {
      return this.cookies.read(this.cookieKey);
    }
  });

  _exports.default = _default;
});