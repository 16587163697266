define("formaviva-web/authenticators/oauth2", ["exports", "ember-simple-auth/authenticators/oauth2-password-grant", "formaviva-web/config/environment"], function (_exports, _oauth2PasswordGrant, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _oauth2PasswordGrant.default.extend({
    serverTokenEndpoint: _environment.default.serverURL + "/oauth/token",
    makeRequest: function makeRequest(url, data) {
      data.client_id = _environment.default.oauth.client_id;
      data.client_secret = _environment.default.oauth.client_secret;
      return this._super(url, data);
    }
  });

  _exports.default = _default;
});