define("formaviva-web/_pods/checkout/payment/route", ["exports", "formaviva-web/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    shoppingCart: Ember.inject.service("checkout/shoppingCart"),
    currentSession: Ember.inject.service(),
    authenticatedAjax: Ember.inject.service(),
    beforeModel: function beforeModel() {
      if (!this.get("currentSession.isAuthenticated")) {
        this.transitionTo("checkout.info");
      }
    },
    model: function model() {
      return Ember.RSVP.hash({
        paymentToken: this.fetchPaymentToken()
      });
    },
    fetchPaymentToken: function fetchPaymentToken() {
      var url = "".concat(_environment.default.apiBaseURL, "/billing/payment_token");
      return this.get("authenticatedAjax").request(url);
    },
    actions: {
      onPaymentSuccess: function onPaymentSuccess(data) {
        this.get("shoppingCart").reset();
        this.transitionTo("purchase", data.purchase_id, {
          queryParams: {
            justPurchased: true
          }
        });
      },
      didTransition: function didTransition() {
        var cartItems = this.get("shoppingCart.cartItems").mapBy("name");
        this.userBehaviourTracker.track({
          event: "payment_form",
          items: cartItems
        });
        return true; // Bubble the didTransition event
      },
      error: function error(_error, transition) {
        if (window.Rollbar) Rollbar.error(_error);
        transition.abort();
        this.notifications.error("There was an error in loading your payment form. Please try again or contact support.");
      }
    }
  });

  _exports.default = _default;
});