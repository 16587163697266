define("formaviva-web/_pods/components/password-reset/email-submit/component", ["exports", "ember-changeset-validations/validators"], function (_exports, _validators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var EMAIL_VALIDATOR = (0, _validators.validateFormat)({
    type: "email"
  });

  var _default = Ember.Component.extend({
    passwordReset: Ember.inject.service(),
    email: "",
    hasTyped: false,
    emailValidationError: Ember.computed("email", "hasTyped", function () {
      var validation = EMAIL_VALIDATOR("", this.email);
      return this.hasTyped && validation !== true ? validation : "";
    }),
    errorMessage: Ember.computed.or("backendError", "emailValidationError"),
    actions: {
      forgotPassword: function forgotPassword(email) {
        var _this = this;

        this.set("hasTyped", true);

        if (this.emailValidationError) {
          return;
        }

        this.set("isResetingPassword", true);
        this.get("passwordReset").postUserEmail(email).then(function () {
          return _this.set("emailSent", true);
        }).catch(function (e) {
          return _this.set("backendError", e.payload.error);
        }).finally(function () {
          return _this.set("isResetingPassword", false);
        });
      }
    }
  });

  _exports.default = _default;
});