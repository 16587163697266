define("formaviva-web/_pods/dashboard/stats/route", ["exports", "moment", "formaviva-web/utils/transform-object-keys"], function (_exports, _moment, _transformObjectKeys) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    profileStats: Ember.inject.service(),
    fastboot: Ember.inject.service(),
    fetchStats: function fetchStats(dateFrom, dateTo) {
      var isInitialFetch = false;

      if (dateFrom === undefined) {
        dateFrom = (0, _moment.default)().subtract(7, "d");
        dateTo = (0, _moment.default)();
        isInitialFetch = true;
      }

      var stats = this.profileStats.fetchStats({
        dateFrom: dateFrom,
        dateTo: dateTo
      }).then(_transformObjectKeys.default.camelizeKeys);

      if (isInitialFetch) {
        var _this$fastboot = this.fastboot,
            shoebox = _this$fastboot.shoebox,
            isFastBoot = _this$fastboot.isFastBoot;

        if (isFastBoot) {
          stats = stats.then(function (data) {
            shoebox.put("dashboard-stats", data);
            return data;
          });
        } else {
          var data = shoebox.retrieve("dashboard-stats");

          if (data) {
            stats = Ember.RSVP.resolve(data);
          }
        }
      }

      return stats;
    },
    model: function model() {
      return this.store.query("play-session", {
        page: 1,
        per_page: 10
      });
    },
    setupController: function setupController(controller, model) {
      this._super(controller, model);

      this.actions.fetchStats.call(this);
      controller.set("playSessions", model);
    },
    actions: {
      fetchStats: function fetchStats(dateFrom, dateTo) {
        var _this = this;

        this.fetchStats(dateFrom, dateTo).then(function (data) {
          _this.controller.set("profileStats", data);
        });
      }
    }
  });

  _exports.default = _default;
});