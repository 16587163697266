define("formaviva-web/_pods/releases/tagged/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    fastboot: Ember.inject.service(),
    isFastBoot: Ember.computed.reads("fastboot.isFastBoot"),
    pageTitle: "Releases | Formaviva",
    queryParams: {
      page: {
        refreshModel: true
      },
      sort: {
        refreshModel: true
      },
      filterByItemType: {
        refreshModel: true
      },
      filterByTag: {
        refreshModel: true
      }
    },
    setupController: function setupController(controller, model) {
      // Call _super for default behavior
      this._super(controller, model); // Implement your custom setup after


      controller.set("selectedTag", this.get("selectedTag"));
    },
    model: function model(params) {
      var _this = this;

      var currentTag = this.store.queryRecord("tag", {
        slug: params.tag_slug
      });
      this.set("selectedTag", currentTag);
      var page = params.page || 1;
      var sort = params.sort || "-release_date";
      var filterByItemType = params.filterByItemType || "all";
      var filterByTag = params.tag_slug || null;
      var key = ["release", filterByItemType, filterByTag, page, sort].join("-");
      var _this$fastboot = this.fastboot,
          shoebox = _this$fastboot.shoebox,
          isFastBoot = _this$fastboot.isFastBoot;

      if (!isFastBoot) {
        var json = shoebox.retrieve(key);

        if (json) {
          // ember-data-fastboot already shoeboxes all requests made from from ember-data by default
          // so records that come from store.query in pre-render also shoeboxed
          // but store.query does not lookup ember-data cache before making request
          // so we don't have chance to retrieve shoeboxed models from cache
          // instead what we do is shoebox ids instead in pre-render mode
          // and then retrieve records from ember-data cache by those ids
          var ids = json.ids,
              meta = json.meta;
          var objs = ids.map(function (id) {
            return _this.store.peekRecord("release", id);
          }).filter(function (val) {
            return val;
          });

          if (objs.length === ids.length) {
            objs.meta = meta;
            return objs;
          }
        }
      }

      return this.store.query("release", {
        page: page,
        sort: sort,
        per_page: 24,
        public: true,
        all: true,
        filter: {
          item_type: filterByItemType,
          tag: filterByTag
        }
      }).then(function (model) {
        if (isFastBoot) {
          shoebox.put(key, {
            ids: model.mapBy("id"),
            meta: model.meta
          });
        }

        return model;
      });
    }
  });

  _exports.default = _default;
});