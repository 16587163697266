define("formaviva-web/instance-initializers/ember-data-fastboot", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.initialize = _initialize;
  _exports.default = void 0;

  function _initialize(applicationInstance) {
    var shoebox = applicationInstance.lookup('service:fastboot').get('shoebox');

    if (!shoebox) {
      return;
    }

    var dump = shoebox.retrieve('ember-data-store');

    if (!dump) {
      return;
    }

    var store = applicationInstance.lookup('service:store');
    store.pushPayload(dump.records);
  }

  var _default = {
    name: 'ember-data-fastboot',
    initialize: function initialize() {
      if (typeof FastBoot === 'undefined') {
        _initialize.apply(void 0, arguments);
      }
    }
  };
  _exports.default = _default;
});