define("formaviva-web/_pods/components/shipping/shipping-zone/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ["shipping-zone", "row"],
    actions: {
      edit: function edit() {
        this.onEdit(this.zone);
      },
      remove: function remove() {
        this.onRemove(this.zone);
      }
    }
  });

  _exports.default = _default;
});