define("formaviva-web/_pods/components/release/release-title/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    currentSession: Ember.inject.service(),
    classNames: ["release-title"],
    showBuyButtons: Ember.computed.not("isPrelaunchLocked")
  });

  _exports.default = _default;
});