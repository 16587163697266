define("formaviva-web/_pods/components/release/release-track-selector/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "7p/+T5Ma",
    "block": "{\"symbols\":[\"track\",\"index\"],\"statements\":[[7,\"table\"],[11,\"class\",\"table fv-table fv-table-modal fv-table-scrollable track-list-table\"],[9],[0,\"\\n  \"],[7,\"thead\"],[9],[0,\"\\n    \"],[7,\"tr\"],[9],[0,\"\\n      \"],[7,\"th\"],[11,\"class\",\"track-order-column\"],[9],[10],[0,\"\\n      \"],[7,\"th\"],[11,\"class\",\"track-image-column\"],[9],[10],[0,\"\\n      \"],[7,\"th\"],[11,\"class\",\"track-title-column\"],[9],[0,\"Title\"],[10],[0,\"\\n      \"],[7,\"th\"],[11,\"class\",\"track-length-column\"],[9],[0,\"Length\"],[10],[0,\"\\n      \"],[7,\"th\"],[11,\"class\",\"track-uploaded-column\"],[9],[0,\"Uploaded\"],[10],[0,\"\\n      \"],[7,\"th\"],[11,\"class\",\"track-actions-column\"],[9],[10],[0,\"\\n    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n  \"],[7,\"tbody\"],[9],[0,\"\\n\"],[4,\"each\",[[25,[\"tracks\"]]],null,{\"statements\":[[0,\"      \"],[1,[29,\"release/release-track-selector-item\",null,[[\"tracksToAdd\",\"track\",\"index\",\"toggleTrack\",\"tagName\"],[[25,[\"tracksToAdd\"]],[24,1,[]],[24,2,[]],[29,\"action\",[[24,0,[]],[25,[\"toggleTrack\"]]],null],\"tr\"]]],false],[0,\"\\n\"]],\"parameters\":[1,2]},null],[4,\"unless\",[[25,[\"hideLoadMore\"]]],null,{\"statements\":[[0,\"      \"],[7,\"div\"],[11,\"class\",\"pt-4 text-center\"],[9],[0,\"\\n        \"],[7,\"button\"],[11,\"class\",\"btn btn-primary btn-primary-2\"],[9],[0,\"\\n          Load more tracks\\n        \"],[3,\"action\",[[24,0,[]],[25,[\"loadtracksForSelection\"]],[29,\"add\",[[25,[\"trackPage\"]],1],null]]],[10],[0,\"\\n      \"],[10],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "formaviva-web/_pods/components/release/release-track-selector/template.hbs"
    }
  });

  _exports.default = _default;
});