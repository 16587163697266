define("formaviva-web/_pods/components/player/player-slave/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "KraDXhQS",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"player-wrapper\"],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"player-control-section\"],[9],[0,\"\\n    \"],[7,\"button\"],[11,\"class\",\"main-control-button\"],[11,\"type\",\"button\"],[9],[0,\"\\n\"],[4,\"if\",[[25,[\"showLoadingIndicator\"]]],null,{\"statements\":[[0,\"        \"],[1,[23,\"ellipsis-animation\"],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[25,[\"isPlaying\"]]],null,{\"statements\":[[0,\"          \"],[1,[29,\"fa-icon\",[\"pause\"],[[\"class\"],[\"icon-pause\"]]],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"          \"],[1,[29,\"fa-icon\",[\"play\"],[[\"class\"],[\"icon-play\"]]],false],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[]}],[0,\"    \"],[3,\"action\",[[24,0,[]],\"togglePlay\"]],[10],[0,\"\\n  \"],[10],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"player-play-section\"],[9],[0,\"\\n    \"],[7,\"div\"],[12,\"style\",[29,\"html-safe\",[[29,\"concat\",[\"height:\",[29,\"or\",[[25,[\"playerHeight\"]],58],null],\"px\"],null]],null]],[12,\"id\",[23,\"containerId\"]],[9],[10],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"player-track-info-badge player-track-current-time\"],[9],[1,[29,\"format-duration\",[[25,[\"time\"]]],null],false],[10],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"player-track-info-badge player-track-length\"],[9],[0,\"\\n      \"],[1,[29,\"format-duration\",[[25,[\"track\",\"length\"]]],null],false],[0,\"\\n    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "formaviva-web/_pods/components/player/player-slave/template.hbs"
    }
  });

  _exports.default = _default;
});