define("formaviva-web/_pods/components/release/release-locked-overlay/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "9FXcfH+y",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"locked-overlay\"],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"center\"],[9],[0,\"\\n    To access the release\\n    \"],[1,[29,\"profile/follow-profile-button\",null,[[\"profile\",\"prelaunchLocked\"],[[25,[\"release\",\"profile\"]],true]]],false],[0,\"\\n  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "formaviva-web/_pods/components/release/release-locked-overlay/template.hbs"
    }
  });

  _exports.default = _default;
});