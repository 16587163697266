define("formaviva-web/_pods/components/password-reset/password-submit/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    passwordReset: Ember.inject.service(),
    actions: {
      resetPassword: function resetPassword(password, token) {
        var _this = this;

        this.set("isResetingPassword", true);
        this.get("passwordReset").postNewPassword(password, token).then(function () {
          return _this.set("passwordSent", true);
        }).catch(function (e) {
          return _this.set("errorMessage", e.payload.error);
        }).finally(function () {
          return _this.set("isResetingPassword", false);
        });
      }
    }
  });

  _exports.default = _default;
});