define("formaviva-web/_pods/components/stats/stats-box/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "GVxsycWU",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"stats__value\"],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"stats__number\"],[9],[0,\"\\n    \"],[1,[29,\"if\",[[25,[\"isCurrency\"]],\"€ \"],null],false],[0,\" \"],[1,[29,\"abbreviate-number\",[[25,[\"statsValue\"]]],null],false],[0,\"\\n  \"],[10],[0,\"\\n\"],[4,\"if\",[[25,[\"statsChange\"]]],null,{\"statements\":[[0,\"    \"],[7,\"div\"],[11,\"class\",\"stats__change\"],[9],[0,\"\\n      \"],[1,[29,\"stats/stats-change\",null,[[\"change\"],[[25,[\"statsChange\"]]]]],false],[0,\"\\n    \"],[10],[0,\"\\n\"]],\"parameters\":[]},null],[10],[0,\"\\n\"],[7,\"div\"],[11,\"class\",\"stats__label\"],[9],[0,\"\\n  \"],[1,[23,\"statsLabel\"],false],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "formaviva-web/_pods/components/stats/stats-box/template.hbs"
    }
  });

  _exports.default = _default;
});